import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCartDiscountStatusAndValues } from "../utils/cartUtils";
import { getOrderSummaryFromItemizedTransactionResponse } from "../utils/recordingUtils";
import { PredefinedDiscountOptions } from "./mixMasterCartsStore";
import { PendingSessionData } from "./shoppingCart";
import { getItemizedTransaction } from "./transactions";

export enum RecordingProviderType {
  ENGINEER = "engineer",
  STUDIO = "studio",
}

export interface RecordingFee {
  providerUnitPrice: number;
  providerTotalDuration: number;
  providerTotalPrice: number;
  providerType: RecordingProviderType;
  providerUsername: string;
  providerDiscountedUnitPrice?: number;
}

export interface RecordingFees {
  [recordingServiceId: number]: RecordingFee;
}

export interface RecordingOrderSummary {
  subtotal: number;
  fees: number;
  totalPrice: number;
  discountValue: number;
  discountPercentage: number;
  recordingFees: RecordingFees;
  outstandingBalance: number;
}

export interface SessionData {
  duration: number;
  studioRoomId?: number;
  engineerUserId?: number;
  dateTime: string;
}

export interface RecordingCart {
  title: string;
  artistName: string;
  applyLabelRate: boolean;
  overwritePrice: number | null;
  promocode: string | null;
  sessionData: SessionData[];
  substituteLocation?: google.maps.places.PlaceResult;
  providerAssumesFees: boolean;
  shareArtistContactInfo: boolean;
  predefinedDiscountOption: PredefinedDiscountOptions | null;
}

export const defaultRecordingCart: RecordingCart = {
  title: "",
  artistName: "",
  promocode: "",
  applyLabelRate: false,
  sessionData: [] as SessionData[],
  overwritePrice: null,
  providerAssumesFees: false,
  shareArtistContactInfo: false,
  predefinedDiscountOption: null,
};

interface RecordingCartState {
  orderSummary: RecordingOrderSummary | null;
  isLoading: boolean;
  cart: RecordingCart;
  // Used to track if overwrite price has been applied to the transaction for UI state.
  currentOverwritePrice: number | null;
}

const initialState: RecordingCartState = {
  orderSummary: null,
  isLoading: false,
  cart: defaultRecordingCart,
  currentOverwritePrice: null,
};

const recordingCartsSlice = createSlice({
  name: "recordingCarts",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getItemizedTransaction.fulfilled, (state, action) => {
      state.orderSummary = getOrderSummaryFromItemizedTransactionResponse(
        action.payload,
      );
      state.isLoading = false;

      // Following sets predefinedDiscountOption on reload.
      // Will prioritize predefinedDiscountOption over overwritePrice if the values are equal.
      const { predefinedDiscountOption, providerAssumesFees } =
        getCartDiscountStatusAndValues(action.payload);
      state.cart.predefinedDiscountOption = predefinedDiscountOption;
      state.cart.overwritePrice = action.payload.overwrite_price;
      state.cart.providerAssumesFees = providerAssumesFees;
      state.currentOverwritePrice = action.payload.overwrite_price;
    });
    builder.addCase(getItemizedTransaction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getItemizedTransaction.rejected, (state) => {
      state.isLoading = false;
      state.cart.overwritePrice = null;
      state.currentOverwritePrice = null;
    });
  },
  reducers: {
    resetRecordingCartsSlice: () => {
      return initialState;
    },
    setIsRecordingOrderSummaryLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload;
    },
    clearRecordingCart: (state) => {
      state.cart = defaultRecordingCart;
    },
    addRecordingCart: (
      state,
      action: PayloadAction<RecordingCart | undefined>,
    ) => {
      state.cart = action.payload ?? defaultRecordingCart;
    },
    addRecordingSessionsToCart: (
      state,
      action: PayloadAction<{
        pendingSessions: PendingSessionData[];
        studioRoomId?: number;
      }>,
    ) => {
      const { pendingSessions, studioRoomId } = action.payload;
      state.cart.sessionData = pendingSessions.map((session) => ({
        duration: session.duration,
        engineerUserId:
          session.trackingEngineer?.user_id ||
          session.autoSelectedTrackingEngineer?.user_id,
        studioRoomId: studioRoomId,
        dateTime: session.selectedISODate,
      })) as SessionData[];
    },
    updateRecordingOrderSummary: (
      state,
      action: PayloadAction<RecordingOrderSummary | null>,
    ) => {
      state.orderSummary = action.payload;
    },
    setRecordingOverwritePrice: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.cart.overwritePrice = action.payload;
      state.cart.predefinedDiscountOption = null;
    },
    addRecordingPromoCode: (state, action: PayloadAction<string>) => {
      state.cart.promocode = action.payload;
      state.cart.overwritePrice = null;
    },
    setSubstituteLocation: (
      state,
      action: PayloadAction<google.maps.places.PlaceResult>,
    ) => {
      state.cart.substituteLocation = action.payload;
    },
    toggleRecordingProviderAssumesFees: (state) => {
      state.cart.providerAssumesFees = !state.cart.providerAssumesFees;
    },
    toggleShareArtistContactInfo: (state) => {
      state.cart.shareArtistContactInfo = !state.cart.shareArtistContactInfo;
    },
    setRecordingPredefinedDiscountOption: (
      state,
      action: PayloadAction<PredefinedDiscountOptions | null>,
    ) => {
      state.cart.predefinedDiscountOption = action.payload;
      state.cart.overwritePrice = null;
    },
  },
});

export const {
  resetRecordingCartsSlice,
  clearRecordingCart,
  addRecordingCart,
  addRecordingSessionsToCart,
  setIsRecordingOrderSummaryLoading,
  setRecordingOverwritePrice,
  updateRecordingOrderSummary,
  addRecordingPromoCode,
  setSubstituteLocation,
  toggleRecordingProviderAssumesFees,
  toggleShareArtistContactInfo,
  setRecordingPredefinedDiscountOption,
} = recordingCartsSlice.actions;
export default recordingCartsSlice.reducer;
