import { useCallback, useEffect, useMemo, useState } from "react";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../../store/models/subscription";
import {
  useEngineerSubscriptionBenefits,
  useStudioSubscriptionBenefits,
  useSubscriptionName,
  useSubscriptionTrophy,
} from "../../../hooks/useSubscriptionTrophy";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import {
  getStripePortalSession,
  getSubscriptionStatus,
} from "../../../store/actions/subscriptions";
import { SCREENS } from "../../../routes";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  isEngineerOnboardedSelector,
  selectUserCanEnableStudioServices,
  selectUserEngineerField,
  selectUserStudioManagerField,
} from "../../../store/selectors/userInfoSelectors";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import {
  ManageSubscriptionCardBenefitList,
  ManageSubscriptionCardBenefitListItem,
  ManageSubscriptionCardContainer,
  ManageSubscriptionCardHeader,
} from "./ManageSubscriptionCard.styles";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";

export interface ManageSubscriptionCardProps {
  feesAvoided?: number;
  onClose?: () => void;
}

export const ManageSubscriptionCard = ({
  feesAvoided,
  onClose,
}: ManageSubscriptionCardProps) => {
  const selectedProfileStudio = useAppSelector(
    (state) => state.selectedProfileSlice.studio,
  );
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.subscriptionStore.loading);
  const subscription = useAppSelector((state) => state.subscriptionStore);
  const trophyAsset = useSubscriptionTrophy(
    subscription.subscription_plan_choice,
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const subscriptionName = useSubscriptionName(
    subscription.subscription_plan_choice,
  );
  const engineerBenefits = useEngineerSubscriptionBenefits(
    subscription.subscription_plan_choice,
  );
  const studioBenefits = useStudioSubscriptionBenefits(
    subscription.subscription_plan_choice,
  );
  const benefits = selectedProfileStudio ? studioBenefits : engineerBenefits;
  const [loadingPortal, setLoadingPortal] = useState(false);
  const subscriptionOnBackendName = useSubscriptionName(
    subscription.subscription_plan_choice,
  );
  const engineer = useAppSelector(selectUserEngineerField);
  const studioManager = useAppSelector(selectUserStudioManagerField);
  const isOnboardedEngineer = useAppSelector(isEngineerOnboardedSelector);
  const canEnableStudioServices = useAppSelector(
    selectUserCanEnableStudioServices,
  );
  const hasAnInactiveSubscription = useMemo(() => {
    return (
      subscription.subscription_plan_choice !== SUBSCRIPTION_PLAN.BASIC_PLAN &&
      subscription.stripe_subscription_status ===
        SUBSCRIPTION_STATUS.INACTIVE &&
      subscription.subscription_plan_choice !==
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN
    );
  }, [subscription]);

  useEffect(() => {
    if (!engineer && !studioManager) return;
    if (
      subscription.stripe_subscription_status ===
      SUBSCRIPTION_STATUS.NOT_DEFINED
    ) {
      void dispatch(getSubscriptionStatus({ refetch_subs_status: true }));
    }
  }, [engineer, studioManager, subscription]);

  const handleOnClick = useCallback(() => {
    window.analytics.track(
      getDebugEventPrefix + "clicked_manage_subscription_on_card",
      {
        user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
      },
    );
    if (
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN ||
      subscription.subscription_plan_choice === SUBSCRIPTION_PLAN.BASIC_PLAN
    ) {
      history.push(SCREENS.ADD_SUBSCRIPTION);
      if (onClose) onClose();
      return;
    }

    setLoadingPortal(true);
    void dispatch(getStripePortalSession())
      .unwrap()
      .then(({ portal_url }) => {
        window.location.href = portal_url;
      });
  }, [subscription, dispatch]);

  if (!engineer && !studioManager) return null;

  // We don't want to show the card if the user is not onboarded as an engineer and not as a studio manager
  if (
    !isOnboardedEngineer &&
    !canEnableStudioServices &&
    subscription.subscription_plan_choice ===
      SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN
  )
    return null;

  return (
    <ManageSubscriptionCardContainer>
      <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
        Membership Status
      </Text>
      <ManageSubscriptionCardHeader>
        <img
          alt="subscription trophy"
          width={30}
          height={30}
          src={trophyAsset}
          style={{ marginRight: "8px" }}
        />
        {loading ? (
          <Skeleton width={150} count={1} />
        ) : (
          <Text variant={TextStyleVariant.P2} weight={TEXT_WEIGHT.SEMI_BOLD}>
            {subscriptionName}
          </Text>
        )}
      </ManageSubscriptionCardHeader>
      {hasAnInactiveSubscription && (
        <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
          Your <strong>{subscriptionOnBackendName}</strong> is inactive. Click
          below to update your subscription
        </Text>
      )}
      <ManageSubscriptionCardBenefitList>
        {benefits?.map((benefit, index) => {
          if (loading) {
            return (
              <ManageSubscriptionCardBenefitListItem
                key={`${benefit}-${index}`}
              >
                <Skeleton count={1} />
              </ManageSubscriptionCardBenefitListItem>
            );
          }
          return (
            <ManageSubscriptionCardBenefitListItem key={`${benefit}-${index}`}>
              {benefit}
            </ManageSubscriptionCardBenefitListItem>
          );
        })}
      </ManageSubscriptionCardBenefitList>
      {subscription.subscription_plan_choice !== SUBSCRIPTION_PLAN.BASIC_PLAN &&
        subscription.subscription_plan_choice !==
          SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN && (
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
            Some benefits unlocked after free trial
          </Text>
        )}
      {feesAvoided && (
        <Text
          color={TextColor.SUCCESS}
        >{`So far you've saved ${PennyDollarFormatter().format(
          feesAvoided,
        )} in fees because of your membership!`}</Text>
      )}
      <Button
        variant={ButtonVariant.OUTLINED}
        disabled={loading}
        onClick={handleOnClick}
        loading={loadingPortal}
      >
        Manage Subscription
      </Button>
    </ManageSubscriptionCardContainer>
  );
};
