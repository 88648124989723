import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useMediaQuery,
  useMediaQueryBreakpoint,
} from "../../../hooks/useMediaQuery";
import { useSubscriptionName } from "../../../hooks/useSubscriptionTrophy";
import { SCREENS } from "../../../routes";
import { addBasicSubscriptionPlan } from "../../../store/actions/subscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { DollarFormatter } from "../../../store/utils/formatUtils";
import {
  getSubscriptionCardProps,
  getSubscriptionRecommendation,
} from "../../../store/utils/subscriptionUtils";
import { BaseModal } from "../../components/BaseModal/BaseModal";
import { SubscriptionCard } from "../../components/SubscriptionCard/SubscriptionCard";
import { SubscriptionComparisonTable } from "../../components/SubscriptionComparisonTable/SubscriptionComparisonTable";
import { Button } from "../../elements/Button/button";
import "./SelectSubscriptionScreen.css";

export interface SelectSubscriptionScreenProps {
  numberOfSongs: number;
  averageProjectPrice: number;
  onClickPreviousStep: () => void;
  onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => void;
}

export const SelectSubscriptionScreen = ({
  numberOfSongs,
  averageProjectPrice,
  onClickPreviousStep,
  onSubscriptionSelected,
}: SelectSubscriptionScreenProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaQueryBreakpoint();
  const isLargeViewport = useMediaQuery("(max-width: 1024px)");
  const [viewOptions, setViewOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectBasicPlan, setSelectBasicPlan] = useState(false);
  const projectStats = useAppSelector(
    (state) => state.statService.projectStats,
  );
  const numberOfActiveAndCompletedSongs =
    Number(projectStats?.num_active_projects) +
    Number(projectStats?.num_completed_projects);
  const [recommendation, amountSaved] = getSubscriptionRecommendation(
    numberOfSongs,
    averageProjectPrice,
    numberOfActiveAndCompletedSongs,
  );
  const user = useAppSelector((state) => state.accountInfo.user);

  const subscriptionCardProps = getSubscriptionCardProps({
    plan: recommendation,
    onSubscriptionSelected: () => {
      recommendation === SUBSCRIPTION_PLAN.BASIC_PLAN
        ? setSelectBasicPlan(true)
        : onSubscriptionSelected(recommendation);
    },
    recommended: true,
  });

  const packageName = useSubscriptionName(recommendation);

  const onConfirmBasicPlan = () => {
    setLoading(true);
    window.analytics.track(getDebugEventPrefix + "choose_basic_subscription", {
      user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
    });
    dispatch(addBasicSubscriptionPlan()).then(() => {
      history.push(SCREENS.DASHBOARD);
    });
  };

  return (
    <>
      <div className="container add-subscription-flow-container">
        <div className="row justify-content-md-center">
          <h3 className="subscription-step-header">
            A plan that suits your needs
          </h3>
          <p className="my-4" style={{ textAlign: "center" }}>
            Based on {numberOfSongs} monthly songs, we think the&nbsp;
            <strong>{packageName}</strong>
            &nbsp;is right for you. <br />
            <br />
            You&apos;ll save{" "}
            <strong>{DollarFormatter().format(amountSaved)}</strong>
            &nbsp;in service fees each month by selecting this plan.
          </p>
        </div>
        <div className="row justify-content-md-center">
          <div className="subscription-step-view">
            <div className="subscription-selection-section">
              {viewOptions ? (
                [
                  SUBSCRIPTION_PLAN.BASIC_PLAN,
                  SUBSCRIPTION_PLAN.GOLD_PLAN,
                  SUBSCRIPTION_PLAN.PLATINUM_PLAN,
                ].map((subscriptionPlan) => (
                  <SubscriptionCard
                    // Disable Platinum until the user has completed 10 songs.
                    buttonDisabled={
                      subscriptionPlan === SUBSCRIPTION_PLAN.PLATINUM_PLAN &&
                      numberOfActiveAndCompletedSongs < 10
                    }
                    buttonDisabledMessage="This plan is unlocked once you have booked 10 mixes or masters through EngineEars."
                    key={subscriptionPlan}
                    {...getSubscriptionCardProps({
                      plan: subscriptionPlan,
                      onSubscriptionSelected: () => {
                        if (subscriptionPlan === SUBSCRIPTION_PLAN.BASIC_PLAN) {
                          setSelectBasicPlan(true);
                        } else {
                          onSubscriptionSelected(subscriptionPlan);
                        }
                      },
                      recommended: subscriptionPlan === recommendation,
                    })}
                  />
                ))
              ) : (
                <SubscriptionCard {...subscriptionCardProps} />
              )}
            </div>
            {viewOptions && isDesktop && !isLargeViewport && (
              <SubscriptionComparisonTable />
            )}
            <div className="more-button-container">
              {!viewOptions ? (
                <button
                  className="more-options-button"
                  onClick={() => setViewOptions(!viewOptions)}
                >
                  Not the right plan?&nbsp;
                  <span style={{ textDecoration: "underline" }}>
                    View other options
                  </span>
                </button>
              ) : (
                <button
                  className="more-options-button"
                  onClick={() => setViewOptions(!viewOptions)}
                >
                  Close Comparison
                </button>
              )}
              <Button
                size="medium"
                customClassName="mb-5"
                label="Previous Step"
                onClick={onClickPreviousStep}
              />
            </div>
          </div>
        </div>
      </div>
      <BaseModal
        modalIsOpen={selectBasicPlan}
        closeModal={() => setSelectBasicPlan(false)}
        label="Confirm Basic Plan"
      >
        <div className="select-basic-modal">
          <h3 className="mx-2">Are you sure you want to continue?</h3>
          <p className="b2 mx-2 my-4">
            The basic membership includes a 12% service fee. We recommend our
            premium memberships to save on service fees.
          </p>
          <div className="select-basic-modal-footer">
            <Button
              primary={true}
              label="Choose Basic"
              loading={loading}
              onClick={onConfirmBasicPlan}
            />
            <Button label="Cancel" onClick={() => setSelectBasicPlan(false)} />
          </div>
        </div>
      </BaseModal>
    </>
  );
};
