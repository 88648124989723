import {
  SUBSCRIPTION_FEE_MAP,
  SUBSCRIPTION_PLAN,
} from "../models/subscription";

interface getSubscriptionCardPropsProps {
  plan: SUBSCRIPTION_PLAN;
  recommended?: boolean;
  onSubscriptionSelected?: () => void;
  showPrice?: boolean;
}

export const getSubscriptionCardProps = ({
  plan,
  onSubscriptionSelected = () => {},
  recommended = false,
  showPrice = true,
}: getSubscriptionCardPropsProps) => {
  return {
    plan,
    onSubscriptionSelected,
    recommended,
    showPrice,
  };
};

export const getSubscriptionRecommendation = (
  numberOfSongs: number,
  averageCost: number,
  numberOfActiveAndCompletedSongs: number,
) => {
  // First, calcuate the average package price for this engineer.
  // TODO: consider adding this back somehow.
  // if (services.length > 0) {
  //   const totalCost = services.reduce((acc, curr) => {
  //     return acc + curr.service_rate!.price;
  //   }, 0);
  //   averageCost = totalCost / services.length;
  // }

  // Next, calculate the total amount to be brought in.
  const totalMonthlyRevenue = averageCost * numberOfSongs;

  // Next, calculate the amount of fees saved with each plan.
  const goldSaved =
    totalMonthlyRevenue *
    (SUBSCRIPTION_FEE_MAP.get(SUBSCRIPTION_PLAN.BASIC_PLAN)! -
      SUBSCRIPTION_FEE_MAP.get(SUBSCRIPTION_PLAN.GOLD_PLAN)!);
  const platinumSaved =
    totalMonthlyRevenue *
    (SUBSCRIPTION_FEE_MAP.get(SUBSCRIPTION_PLAN.BASIC_PLAN)! -
      SUBSCRIPTION_FEE_MAP.get(SUBSCRIPTION_PLAN.PLATINUM_PLAN)!);

  // Only show platinum if the user has at least 10 active/completed songs.
  if (platinumSaved >= 70 && numberOfActiveAndCompletedSongs >= 10) {
    return [SUBSCRIPTION_PLAN.PLATINUM_PLAN, platinumSaved];
  } else if (goldSaved >= 15) {
    return [SUBSCRIPTION_PLAN.GOLD_PLAN, goldSaved];
  } else {
    return [SUBSCRIPTION_PLAN.BASIC_PLAN, 0];
  }
};

export const getSubscriptionAnalyticsName = (plan: SUBSCRIPTION_PLAN) => {
  switch (plan) {
    case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
      return "inactive";
    case SUBSCRIPTION_PLAN.BASIC_PLAN:
      return "basic";
    case SUBSCRIPTION_PLAN.GOLD_PLAN:
    case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
    case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
      return "gold";
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
    case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
      return "platinum";
  }
};
