import { Link } from "react-router-dom";
import styled from "styled-components";

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 50px 32px 50px;
  background: ${({ theme }) => theme.midgroundColor};
  box-sizing: border-box;

  @media (max-width: 480px) {
    max-height: 100%;
    height: 100%;
    padding: 24px 15px;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    max-height: 100%;
    height: 100%;
    padding: 16px 32px 32px 32px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background: ${({ theme }) => theme.dropdownBackgroundColor};
  box-sizing: border-box;
  border-radius: var(--border-radius-md);

  @media (max-width: 480px) {
    max-height: 100%;
    height: 100%;
    padding: 24px 16px;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    max-height: 100%;
    height: 100%;
  }
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;
`;

export const BreadCrumbIcon = styled.div`
  margin: 0 10px;
`;

export const BreadCrumbLink = styled(Link)<{ $isProjectTitle?: boolean }>`
  color: ${({ theme }) => theme.colorPalette.Gray300};
  text-decoration: none;
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightBold};
  white-space: nowrap;

  ${({ $isProjectTitle }) =>
    $isProjectTitle &&
    `
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const DetailAndStatusRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  flex: 1;
`;

export const DetailsAndBudgetRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
`;

export const BudgetRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const DetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  margin-right: auto;
`;

export const ProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  min-width: 205px;
`;

export const BudgetBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  min-width: 175px;
`;

export const StatusBlock = styled.div<{ $completed?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${({ theme, $completed = false }) =>
    $completed ? theme.colorPalette.Green50 : theme.colorPalette.BoxyYellow100};
  box-sizing: border-box;
  border-radius: var(--border-radius-md);
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    flex: 1;
  }
`;

export const VerticalSeparator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.separatorColor};
`;

export const ProjectIdContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;

export const ProjectOverviewText = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.textTertiaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const DetailedBlockTitleText = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeLg};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    font-size: ${({ theme }) => theme.textSizeMd};
  }
`;

export const DetailedBlockKeyText = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const SecondaryDetailedBlockKeyText = styled.p`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
`;

export const SecondaryDetailedBlockValueText = styled.p`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightNormal};
`;

export const DetailedBlockValueText = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightNormal};
`;

export const ProgressBlockKeyText = styled.p`
  color: ${({ theme }) => theme.textPrimaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightSemibold};
  margin-bottom: 1px;
  flex: 1;
  min-width: 150px;
`;

export const ProgressBlockValueText = styled.p`
  color: ${({ theme }) => theme.textSecondaryColor};
  font-size: ${({ theme }) => theme.textSizeSm};
  font-weight: ${({ theme }) => theme.textWeightNormal};
  text-align: left;
  flex: 1;
`;

export const OverviewHeaderDropDownSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
