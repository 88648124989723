import React, { useEffect, useMemo } from "react";
import {
  useStudioSubscriptionSubTypeBenefits,
  useSubscriptionSubTypeBenefits,
} from "../../../hooks/useSubscriptionTrophy";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import checkbox from "../../assets/checkbox_accepted.svg";
import { Button } from "../../elements/Button/button";
import "./PaymentPlanCheckbox.css";
import { getAnnualVersionOfSubscription } from "../../screens/SelectSubPaymentPlanScreen/SelectSubPaymentPlanScreen";
import { sub } from "date-fns";

export interface PaymentPlanCheckboxProps {
  subscriptionType: SUBSCRIPTION_PLAN; // Type of subscription plan being rendered.
  selected: boolean; // Indicates whether this particular plan is the selected on currently.
  setSelected?: (plan: SUBSCRIPTION_PLAN) => void;
  useStudioBenefits?: boolean;
}

export const PaymentPlanCheckbox = ({
  subscriptionType,
  selected,
  setSelected,
  useStudioBenefits = false,
}: PaymentPlanCheckboxProps) => {
  const subtypeBenefits = useSubscriptionSubTypeBenefits(subscriptionType);
  const studioSubtypeBenefits =
    useStudioSubscriptionSubTypeBenefits(subscriptionType);
  const isAnnualPlan =
    subscriptionType === SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN ||
    subscriptionType === SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN;

  // On first load, set the selected plan to be the annual version of the plan.
  useEffect(() => {
    if (!setSelected) return;
    setSelected(getAnnualVersionOfSubscription(subscriptionType));
  }, []);

  const onClick = () => {
    if (!setSelected) return;
    setSelected(subscriptionType);
  };

  let checkboxTitle = isAnnualPlan ? "Annual" : "Monthly";
  if (subscriptionType === SUBSCRIPTION_PLAN.DIAMOND_PLAN) {
    checkboxTitle = "$299.99 Quarterly";
  }

  return (
    <div
      className={"payment-plan-checkbox".concat(
        isAnnualPlan ? "" : " secondary-payment-plan",
      )}
    >
      <div className="m-2">
        <img
          src={checkbox}
          alt="checkbox"
          className={"mx-1 custom-checkmark".concat(selected ? " checked" : "")}
        />
        <input
          className="m-3"
          type="checkbox"
          id={checkboxTitle}
          checked={selected}
          onChange={onClick}
        />
        <label htmlFor={checkboxTitle}>{checkboxTitle}</label>
      </div>
      <div className="m-3">
        <ul className="benefit-unordered-list">
          {(useStudioBenefits ? studioSubtypeBenefits : subtypeBenefits).map(
            (benefit, index) => {
              return (
                <li
                  className={"benefit-list-item b2"}
                  key={`${benefit}-${index}`}
                >
                  {benefit}
                </li>
              );
            },
          )}
        </ul>
        {setSelected && (
          <Button
            label={isAnnualPlan ? "Save with this Plan" : "Choose Monthly"}
            primary={isAnnualPlan}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};
