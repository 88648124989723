import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { FrequentlyAskedItem } from "./FrequentlyAskedItem";
import { FrequentlyAskedQuestion } from "../../../../../store/models/frequentlyAskedQuestion";
import { FrequentlyAskedItemsBox } from "./FrequentlyAskedItem.styles";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { SortableItem } from "../../../../core-ui/components/ChipsList/SortableItem";
import {
  CreateFAQ,
  DeleteFAQ,
  useMutateFrequentlyAskedQuestion,
} from "../../../../../hooks/profileScreenHooks/useMutateFrequentlyAskedQuestion";
import { useGetUserProfile } from "../../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useParams } from "react-router-dom";
const MAX_FAQS = 5;

interface SortableComponentsProps {
  items: FrequentlyAskedQuestion[];
  editMode: boolean;
}

export const FrequentlyAskedItemsWrapper = ({
  items,
  editMode,
}: SortableComponentsProps) => {
  const [internalItems, setInternalItems] = useState(items);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Set the delay for the item to become draggable
      // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );

  const username = useParams<{ username: string }>().username;
  const { data: userData } = useGetUserProfile(username);

  const {
    mutateAsync,
    isPending: isUpdatingOrPostingFaq,
    isError,
  } = useMutateFrequentlyAskedQuestion({
    userId: userData?.id,
  });

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = internalItems.findIndex((i) => i.id === active.id);
      const newIndex = internalItems.findIndex((i) => i.id === over.id);
      const newItems = arrayMove(internalItems, oldIndex, newIndex);
      try {
        setInternalItems(newItems);
        await mutateAsync({
          faq_ids: newItems.map((faq) => faq.id),
        });
      } catch (e) {
        setInternalItems(items);
      }
    }
  };

  const renderElement = (item: FrequentlyAskedQuestion) => {
    return (
      <FrequentlyAskedItem
        item={item}
        editMode={editMode}
        onClick={mutateAsync}
        onDeleteClick={async () =>
          await mutateAsync({ faq_id: item.id, deleted: true } as DeleteFAQ)
        }
        loading={isUpdatingOrPostingFaq}
      />
    );
  };

  useEffect(() => {
    setInternalItems(items);
  }, [items]);

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext
        items={internalItems.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <FrequentlyAskedItemsBox>
          {internalItems.map((item) => (
            <SortableItem
              key={item.id}
              id={item.id}
              disableDragging={!editMode}
            >
              {renderElement(item)}
            </SortableItem>
          ))}
        </FrequentlyAskedItemsBox>

        {MAX_FAQS - items.length > 0 && editMode && (
          <Button
            onClick={async () =>
              await mutateAsync({
                question: "What can a client expect booking me on EngineEars?",
                answer: "Satisfaction guaranteed! I'll work with you to make sure you get exactly what you're looking for.",
              } as CreateFAQ)
            }
            loading={isUpdatingOrPostingFaq}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            variant={ButtonVariant.OUTLINED}
          >{`Add FAQ (${MAX_FAQS - items.length})`}</Button>
        )}
      </SortableContext>
    </DndContext>
  );
};
