import {
  subscriptionFeatureList,
  SUBSCRIPTION_PLAN,
} from "../../../store/models/subscription";
import { SubsPlanFeatureList } from "../../elements/SubsPlanFeatureList/SubsPlanFeatureList";
import "./SubscriptionComparisonTable.css";

export const SubscriptionComparisonTable = () => {
  return (
    <div className="feature-row">
      <div className="feature-list">
        <div className="title">
          <strong>What is included</strong>
        </div>
        {subscriptionFeatureList.map((feature: string) => {
          return (
            <div key={feature} className="subscription-feature">
              {feature}
            </div>
          );
        })}
      </div>
      <SubsPlanFeatureList plan={SUBSCRIPTION_PLAN.BASIC_PLAN} />
      <SubsPlanFeatureList plan={SUBSCRIPTION_PLAN.GOLD_PLAN} />
      <SubsPlanFeatureList plan={SUBSCRIPTION_PLAN.PLATINUM_PLAN} />
    </div>
  );
};
