import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SCREENS } from "../../../routes";
import {
  manageScheduledProject,
  ProjectManagementActions,
} from "../../../store/actions/booking";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  MixingProjectSteps,
  ProjectType,
  ProjectUserType,
} from "../../../store/models/project";
import {
  PaginatedScheduledProject,
  ScheduledProject,
} from "../../../store/models/scheduledproject";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  ProjectWorkflowPanel,
  ProjectWorkflowPanelVariant,
} from "../ProjectWorkflowPanel/ProjectWorkflowPanel";
import { RequestedPanelFooter } from "../RequestedPanelFooter/RequestedPanelFooter";
import { hasUserAcceptedProject } from "../ScheduledProjectTable/utils";
import { BookingDetails } from "./BookingDetails";
import { Divider } from "./BookingDetails.styles";

interface BookingRequestedPanelProps {
  isOpen: boolean;
  onClose: () => void;
  noRedirect?: boolean; // determines whether to redirect to project overview after accepting
  scheduledProject: PaginatedScheduledProject | null;
}

export const BookingRequestedPanel = ({
  isOpen,
  onClose,
  noRedirect = false,
  scheduledProject,
}: BookingRequestedPanelProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const collaborator = scheduledProject?.users.find(
    (collaborator) => collaborator.id !== loggedInUser?.id,
  );

  const approveScheduledProject = () => {
    if (!scheduledProject) return;
    setIsApproving(true);
    const { id } = scheduledProject;

    emitAnalyticsTrackingEvent(
      "on_click_accept_project",
      {
        scheduled_project_id: id.toString(),
      },
      loggedInUser?.id,
    );

    dispatch(
      manageScheduledProject({
        action: ProjectManagementActions.AcceptProject,
        scheduled_project_id: id,
      }),
    )
      .unwrap()
      .then(() => {
        if (!noRedirect) {
          history.push(
            SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN.replace(
              ":scheduled_project_id",
              id.toString(),
            ),
          );
        } else {
          toast.success("Request has been accepted");
          onClose();
        }
      })
      .catch(() =>
        toast.error(
          "There was an error accepting the project. Please try again!",
        ),
      )
      .finally(() => setIsApproving(false));
  };

  const rejectScheduledProject = () => {
    if (!scheduledProject) return;

    setIsRejecting(true);
    const { id } = scheduledProject;

    dispatch(
      manageScheduledProject({
        action: ProjectManagementActions.RejectProject,
        scheduled_project_id: id,
      }),
    )
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch(() =>
        toast.error(
          "There was an error rejecting the project. Please try again!",
        ),
      )
      .finally(() => setIsRejecting(false));
  };

  return (
    <ProjectWorkflowPanel
      albumTitle={scheduledProject?.title}
      collaborator={collaborator}
      isOpen={isOpen}
      projectStep={MixingProjectSteps.NO_STEP}
      projectType={ProjectType.MIXING}
      variant={ProjectWorkflowPanelVariant.BOOKING_REQUESTED}
      onClose={onClose}
      outstandingBalance={scheduledProject?.outstanding_balance}
      paywallOption={scheduledProject?.default_paywall_option}
      scheduledProject={scheduledProject as ScheduledProject | null}
      projectId={scheduledProject?.id}
    >
      {scheduledProject && (
        <>
          <BookingDetails
            scheduledProjectId={scheduledProject.id}
            scheduledProject={scheduledProject}
            userType={ProjectUserType.ENGINEER}
          />
          <Divider $light />
          {!scheduledProject.refunded &&
            !hasUserAcceptedProject(
              scheduledProject.project_acceptances,
              loggedInUser,
            ) && (
              <RequestedPanelFooter
                isApproving={isApproving}
                isRejecting={isRejecting}
                onApprove={approveScheduledProject}
                onReject={rejectScheduledProject}
              />
            )}
        </>
      )}
    </ProjectWorkflowPanel>
  );
};
