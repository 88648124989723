import { useState } from "react";
import User from "../../../../../../store/models/user";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import { FeedbackModal } from "../../../../FeedbackModal/FeedbackModal";
import {
  NAV_LIST_ITEM_VARIANT,
  NavDropDownListItem,
} from "../NavDropDownListItem/NavDropDownListItem";

interface NavDropDownHelpButtonProps {
  userId: User["id"] | undefined;
  onClose: () => void;
}

export const NavDropDownHelpButton: React.FC<NavDropDownHelpButtonProps> = ({
  onClose,
  userId,
}: NavDropDownHelpButtonProps) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  return (
    <>
      <NavDropDownListItem
        text="Help"
        variant={NAV_LIST_ITEM_VARIANT.BUTTON}
        onClick={() => {
          emitAnalyticsTrackingEvent("logged_in_open_feedback", {}, userId);
          setShowFeedbackModal(!showFeedbackModal);
          onClose();
        }}
      />
      {showFeedbackModal && (
        <FeedbackModal
          onClose={() => {
            setShowFeedbackModal(false);
          }}
        />
      )}
    </>
  );
};
