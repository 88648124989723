import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { SCREENS } from "../../../routes";
import {
  createStripeCustomer,
  getStripeCheckoutSession,
} from "../../../store/actions/subscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BannerColor } from "../../../store/models/profile";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { SignInModal } from "../../components/SignInModal/SignInModal";
import { BannerImage } from "../../elements/BannerImage/BannerImage";
import { SelectStudioSubscriptionScreen } from "../SelectStudioSubscriptionScreen/SelectStudioSubscriptionScreen";
import { SelectSubPaymentPlanScreen } from "../SelectSubPaymentPlanScreen/SelectSubPaymentPlanScreen";
import "./StudioSubscriptionScreen.css";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

const SELECT_SUBSCRIPTION_PACKAGE_STEP = 0;
const SELECT_PAYMENT_PLAN_STEP = 1;

export const StudioSubscriptionScreen = () => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);

  useSetPageTitle(undefined);

  const [currentStep, setCurrentStep] = useState(
    SELECT_SUBSCRIPTION_PACKAGE_STEP,
  );
  const [selectedSubscription, setSelectedSubscription] =
    useState<SUBSCRIPTION_PLAN>(SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const engineer = useAppSelector((state) => state.accountInfo.user?.engineer);
  const loading = useAppSelector((state) => state.engineerServices.isLoading);
  const meetsStripeRequirements = useAppSelector(
    (state) => state.accountInfo.meetsStripeRequirements,
  );

  useEffect(() => {
    if (!user) return;
    if (!engineer) return;
    void dispatch(
      createStripeCustomer({
        billing_email: user.email,
        name: `${user.first_name} ${user.last_name}`,
      }),
    );
  }, [dispatch, user, engineer]);

  const selectSubscriptionScreenProps = {
    onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => {
      // TODO: Figure out why this isn't working as expected. Check if it works in prod.
      // if (subscription.stripe_subscription_status == SUBSCRIPTION_STATUS.ACTIVE) {
      //   dispatch(getStripePortalSession())
      //   .unwrap()
      //   .then(({ portal_url }) => {
      //     window.location = portal_url;
      //   });
      //   return;
      // }
      setSelectedSubscription(selected);
      if (selected !== SUBSCRIPTION_PLAN.BASIC_PLAN)
        setCurrentStep(SELECT_PAYMENT_PLAN_STEP);
    },
  };

  const selectSubPaymentPlanScreenProps = {
    selectedSubscription,
    setSelectedSubscription,
    onClickPreviousStep: () => setCurrentStep(SELECT_SUBSCRIPTION_PACKAGE_STEP),
    onGoToCheckout: () => {
      window.analytics.track(
        getDebugEventPrefix + "start_stripe_subscription_checkout",
        {
          user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
        },
      );
      void dispatch(getStripeCheckoutSession({ plan: selectedSubscription }))
        .unwrap()
        .then((result) => {
          window.location.href = result.checkout_url;
        });
    },
    useStudioBenefits: true,
  };
  const bannerColor = useAppSelector(
    (state) => state.accountInfo.user?.profile?.banner_color,
  );

  if (loading) {
    return <LoadingScreen />;
  }
  if (!isAuthenticated) {
    return <SignInModal label={"Sign In"} />;
  }
  if (!meetsStripeRequirements) {
    return <Redirect to={SCREENS.SETUP_STRIPE} />;
  }

  return (
    <>
      <BannerImage
        editMode={false}
        color={bannerColor ?? BannerColor.BOXY_YELLOW}
      />
      <div className="container-fluid studio-subscription-container">
        <div className="container">
          <div className="row justify-content-md-center m-0">
            {
              [
                <SelectStudioSubscriptionScreen
                  {...selectSubscriptionScreenProps}
                  key={0}
                />,
                <SelectSubPaymentPlanScreen
                  {...selectSubPaymentPlanScreenProps}
                  key={1}
                />,
              ][currentStep]
            }
          </div>
        </div>
      </div>
    </>
  );
};
