/* eslint-disable import/no-unused-modules */
import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import { getCurrentDate } from "../utils/dateTimeUtils";
import {
  MockMasteringProject,
  MockMixingProject,
  MockMixingProject1,
} from "./mockDataForMixMaster/mockDataForMixMaster";
import {
  DetailedPurchaseOrder,
  MockPurchaseOrder1,
  MockRecordingProject,
  PaidRevision,
  Project,
  PurchaseOrder,
} from "./project";
import { PromoCode } from "./promoCode";
import {
  MockRecordingSessionWithStudio,
  MockRecordingSessionWithStudio1,
  MockRecordingSessionWithStudio2,
  RecordingSession,
} from "./recordingSession";
import User, { MockUser } from "./user";

export enum TransactionType {
  UNKNOWN_TRANSACTION_TYPE,
  PAYMENT,
  PAYOUT,
  REFUND,
  CASHOUT,
}

export enum InvoiceStatus {
  DRAFT = "draft",
  OPEN = "open",
  PAID = "paid",
  UNCOLLECTIBLE = "uncollectible",
}

export const getServiceProviderReadableTransactionType = (
  type: TransactionType,
) => {
  switch (type) {
    case TransactionType.PAYMENT:
      return "Client Payment";
    case TransactionType.PAYOUT:
      return "EngineEars Payout";
    case TransactionType.CASHOUT:
      return "Cashout to Bank";
    case TransactionType.REFUND:
      return "Refund to Client";
  }
  return "Unknown Type";
};

export const TransactionTypeOptions: OptionType[] = [
  {
    value: TransactionType.UNKNOWN_TRANSACTION_TYPE,
    label: "All",
  },
  {
    value: TransactionType.PAYMENT,
    label: "Client Payment",
  },
  {
    value: TransactionType.PAYOUT,
    label: "EngineEars Payout",
  },
  {
    value: TransactionType.CASHOUT,
    label: "Cashout to Bank",
  },
  {
    value: TransactionType.REFUND,
    label: "Refund to Client",
  },
];

export enum FinancialMethod {
  UNKNOWN_FINANCIAL_METHOD,
  STRIPE,
  PURCHASE_ORDER,
  KLARNA,
  PAYPAL,
  CASH_APP,
  APPLE_PAY,
  GOOGLE_PAY,
  AFFIRM,
}

export enum PaymentRedirectType {
  KLARNA = "klarna",
  CASH_APP = "cashapp",
  AFFIRM = "affirm",
}

export enum TransactionStatus {
  PENDING,
  PAID,
  PURCHASE_ORDER_REQUIRED,
  PURCHASE_ORDER_SUBMITTED,
  PARTIALLY_PAID,
}

export enum PurchaseOrderAction {
  SUBMIT_SINGLE_PO,
  BULK_SUBMIT_PO,
}

export interface TransactionItem {
  id: number;
  created: string;
  amount: number;
  fees_collected: number;
  paid_revision?: PaidRevision;
  project?: Project;
  promo_code?: PromoCode;
  purchase_order: PurchaseOrder | DetailedPurchaseOrder | null;
}

export interface TransactionItemWithTransaction extends TransactionItem {
  item_transaction: Transaction[];
}

export interface Transaction {
  created: string;
  total_price: string;
  code: string;
  id: number;
  user?: User;
  created_by_user?: User;
  transaction_type: TransactionType;
  items: TransactionItem[];
  financial_method: FinancialMethod;
  transaction_status: TransactionStatus;
  payment_id: string | null;
  client_token?: string | null;
  fees_collected?: string;
  stripe_session_id?: string;
  stripe_invoice_id: string | null;
  outstanding_balance: string | null;
  deposit_percentage_required: number | null;
  hosted_invoice_url?: string;
  scheduled_project_title?: string | null;
  recording_sessions?: RecordingSession[];
  estimated_delivery_date?: string | null;
  scheduled_project_id?: number;
}

export const getTotalFromTransaction = (transaction: Transaction) => {
  let feesCollected = 0;
  transaction.items.forEach((item) => {
    if (transaction.transaction_type === TransactionType.PAYOUT) {
      feesCollected = feesCollected + item.fees_collected;
    }
  });
  return Number(transaction.total_price) - feesCollected;
};

export const getEngineerFeesCollected = (transaction: Transaction) => {
  let feesCollected = 0;
  if (transaction.transaction_type !== TransactionType.PAYOUT) return 0;
  transaction.items.forEach((item) => {
    feesCollected = feesCollected + item.fees_collected;
  });
  return feesCollected;
};

export const MockTransactionItem: TransactionItem = {
  id: 1,
  created: getCurrentDate().toDateString(),
  amount: 100.5,
  fees_collected: 12.5,
  project: MockMixingProject,
  purchase_order: MockPurchaseOrder1,
};

export const MockTransactionItem1: TransactionItem = {
  id: 2,
  created: getCurrentDate().toDateString(),
  amount: 125,
  fees_collected: 12.5,
  project: MockMasteringProject,
  purchase_order: MockPurchaseOrder1,
};

export const MockTransactionItem2: TransactionItem = {
  id: 3,
  created: getCurrentDate().toDateString(),
  amount: 125,
  fees_collected: 12.5,
  project: MockMixingProject1,
  purchase_order: MockPurchaseOrder1,
};

export const MockTransaction: Transaction = {
  outstanding_balance: null,
  estimated_delivery_date: null,
  code: "9d93f140-b5a5-4736-933b-b339302efbee",
  created: getCurrentDate().toDateString(),
  id: 1,
  total_price: "200.00",
  user: MockUser,
  items: [MockTransactionItem, MockTransactionItem],
  transaction_type: TransactionType.PAYMENT,
  financial_method: FinancialMethod.STRIPE,
  transaction_status: TransactionStatus.PENDING,
  payment_id: "",
  fees_collected: "0.15",
  stripe_session_id: "01_secret_qqwrwffaresferfer&g3738823rd34",
  stripe_invoice_id: null,
  deposit_percentage_required: 0.5,
};

export const MockTransactionItemForRecording: TransactionItem = {
  id: 1,
  created: getCurrentDate().toDateString(),
  amount: 100.5,
  fees_collected: 12.5,
  project: MockRecordingProject,
  purchase_order: MockPurchaseOrder1,
};

export const MockTransaction1: Transaction = {
  outstanding_balance: null,
  estimated_delivery_date: null,
  code: "9d93f140-b5a5-4736-933b-b339302efbee",
  created: getCurrentDate().toDateString(),
  id: 1,
  total_price: "226.00",
  user: MockUser,
  items: [MockTransactionItem, MockTransactionItem1, MockTransactionItem2],
  transaction_type: TransactionType.PAYMENT,
  financial_method: FinancialMethod.STRIPE,
  transaction_status: TransactionStatus.PENDING,
  scheduled_project_title: "I have a title",
  payment_id: "",
  fees_collected: "0.15",
  stripe_session_id: "01_secret_qqwrwffaresferfer&g3738823rd34",
  stripe_invoice_id: "in_invoiceid",
  deposit_percentage_required: null,
};

export const MockTransactionForRecording: Transaction = {
  outstanding_balance: null,
  estimated_delivery_date: null,
  code: "9d93f140-b5a5-4736-933b-b339302efbee",
  created: getCurrentDate().toDateString(),
  id: 1,
  total_price: "226.00",
  user: MockUser,
  items: [
    MockTransactionItemForRecording,
    MockTransactionItemForRecording,
    MockTransactionItemForRecording,
  ],
  transaction_type: TransactionType.PAYMENT,
  financial_method: FinancialMethod.STRIPE,
  transaction_status: TransactionStatus.PENDING,
  scheduled_project_title: "I have a title",
  payment_id: "",
  fees_collected: "0.15",
  stripe_session_id: "01_secret_qqwrwffaresferfer&g3738823rd34",
  stripe_invoice_id: "in_invoiceid",
  recording_sessions: [
    MockRecordingSessionWithStudio,
    MockRecordingSessionWithStudio1,
    MockRecordingSessionWithStudio2,
  ],
  deposit_percentage_required: null,
};
