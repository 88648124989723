import { Alt } from "./alts";
import User, { MockUser, MockUser1, UserLite } from "./user";

export enum FILE_STATUS {
  FILE_STATUS_UNKNOWN,
  FILE_PENDING,
  FILE_UPLOADED,
}

export enum BUCKET_PATH {
  REFERENCE = "files_to_mix",
  DELIVERABLE = "mixed_files",
}

export type SortByTypeValue = "-most_recent_activity" | "created" | "-created";

export enum SortByTypeEnum {
  MOST_RECENT_ACTIVITY = "-most_recent_activity",
  OLDEST_FIRST = "created",
  NEWEST_FIRST = "-created",
}

export const COMMENTS_SORT = [
  { label: "Recent Activity", value: SortByTypeEnum.MOST_RECENT_ACTIVITY },
  { label: "Oldest First", value: SortByTypeEnum.OLDEST_FIRST },
  { label: "Newest First", value: SortByTypeEnum.NEWEST_FIRST },
];

export const UNKNOWN_FILE_TYPE = "unknown";
export const ZIP_UPLOAD_TYPE = "zip";
export const AUDIO_UPLOAD_TYPE = "audio";
export const MP4_UPLOAD_TYPE = "audio/mp4";
export const GENERATED_MP3_UPLOAD_TYPE = "generated-audio/mp3";
export const LINK_UPLOAD_TYPE = "link";

export enum UploadType {
  AUDIO = "audio",
  GENERATED_MP3 = "generated-audio/mp3",
  LINK = "link",
  MP4 = "audio/mp4",
  UNKNOWN = "unknown",
  ZIP = "zip",
}

export enum FileType {
  ZIP = ".zip",
  WAV = ".wav",
  MP4 = ".mp4",
  MP3 = ".mp3",
}

export enum ContentType {
  WAV = "audio/wav",
}

export interface FileVersion {
  id: number;
  file_name?: string;
  alt: Alt;
  reference: boolean;
  status: FILE_STATUS;
  object_key_path?: string;
  created_by_user?: User | null;
  version: number;
  created: string;
  upload_type?: string | null;
  file_link: string | null;
  archived: string | null;
}

export interface FileVersionComment {
  id: number;
  created: string;
  deleted: string | null;
  comment: string;
  start_timestamp_in_seconds: number | null;
  end_timestamp_in_seconds: number | null;
  unique_css_identifier?: string;
  author_name: string | null;
  author_user: User | null;
  file_version: FileVersion;
  file_version_id: number;
  reply_to?: number;
  resolved: string | null;
  resolver_name: string | null;
  resolver_user: UserLite | null;
  project_id: number;
  replies: FileVersionComment[];
}

export interface SonicMatchUpload {
  id: number;
  created: string;
  status: FILE_STATUS;
  file_name?: string;
  dolby_io_job_id?: string;
  engineer_users: User[];
}

export const MockFileVersion: FileVersion = {
  id: -1,
  status: FILE_STATUS.FILE_UPLOADED,
  file_name: "song.mp3",
  alt: Alt.CLEAN,
  reference: false,
  object_key_path: "keytos3bucket",
  created_by_user: MockUser,
  version: 2,
  created: new Date().toISOString(),
  file_link: null,
  archived: null,
};

export const MockSonicMatchUpload: SonicMatchUpload = {
  id: -1,
  created: new Date().toISOString(),
  status: FILE_STATUS.FILE_UPLOADED,
  file_name: "song.mp3",
  dolby_io_job_id: "jsdh2348quwef9wjfau3hr8afjea390",
  engineer_users: [MockUser, MockUser1],
};
