import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import queryString from "query-string";
import { Alt, AltItem } from "../models/alts";
import { EngineeringUniqueBookingLinkAPIResponse } from "../models/engineeringUniqueBookingLink";
import {
  DetailedPurchaseOrder,
  DetailedPurchaseOrderWithTransaction,
  Project,
  ProjectType,
} from "../models/project";
import { PromoCode } from "../models/promoCode";
import { MinimalScheduledProject } from "../models/scheduledproject";
import {
  FinancialMethod,
  MockTransaction,
  PurchaseOrderAction,
  Transaction,
  TransactionStatus,
} from "../models/transaction";
import { MajorLabelEnum, UMGSubLabelEnum } from "../models/trophy";
import {
  makeBackendGetCall,
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import {
  getApplyTransactionPromoCodeRoute,
  getCreateTransactionPaymentIntentRoute,
  getShareTransactionAPIRoute,
  getUpdateTransactionAPIRoute,
} from "../utils/routeGetters";
import {
  APPLY_TRANSACTION_PROMO_CODE,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_PAYMENT_INTENT,
  GET_ALL_TRANSACTIONS,
  GET_ITEMIZED_TRANSACTION,
  GET_PURCHASE_ORDER_BY_CODE,
  GET_TRANSACTION,
  GET_TRANSACTION_INVOICE_URL,
  HANDLE_BUDGET,
  PAID_TRANSACTION,
  PAYMENT_PLAN_ENDPOINT,
  PURCHASE_REVISIONS,
  RECORDING_SESSION_EXTENSION_CHECKOUT,
  RECORDING_SESSION_EXTENSION_PURCHASE,
  RESEND_BILLING_LINK,
  REVISION_CHECKOUT,
  SHARE_TRANSACTION,
  SUBMIT_PURCHASE_ORDER_BILLING_INFO_API,
  TRANSACTION_STATUS_API,
  UMG_BILLING_REPORT,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_PAYMENT,
} from "../utils/routes";
import { Error, receiveErrors } from "./errorStore";
import { renameProjectOrScheduledProject } from "./projects";
import { RecordingProviderType } from "./recordingCartsStore";

export interface TransactionData {
  service_type: ProjectType;
  service_provider_user_id?: number;
  code?: string;
  title?: string;
  artist_name?: string;
  prereq_id?: number;
  promocode?: string;
}

export interface ProjectTransactionData extends TransactionData {
  service_id: number;
  requested_date: string | null;
  alts?: Alt[] | AltItem[];
  add_master?: boolean;
  add_atmos?: boolean;
}

export interface RecordingTransactionData extends TransactionData {
  recording_service_id: number;
  first_choice_datetime: string;
  second_choice_datetime?: string;
  engineer_goes_to_artist?: boolean;
  session_duration_minutes: number;
  substitute_recording_location?: google.maps.places.PlaceResult;
  substitute_unit_number?: string;
  substitute_arrival_information?: string;
}

export interface bookingTransactionParams {
  financial_method?: FinancialMethod;
  action?: string;
  title?: string;
  artist_name?: string;
  project_data?: ProjectTransactionData[];
  recording_data?: RecordingTransactionData[];
  scheduled_project_id?: number;
  emailsList?: string[];
  adminEmail?: string;
  engineer_has_files?: boolean;
  promocode?: string;
  apply_label_rate?: boolean;
}

export interface PostPurchaseOrderArgs {
  scheduled_project_id?: number;
  project_id?: number;
  order_number?: string;
  action?: PurchaseOrderAction;
  transaction_id?: number;
  reject_budget?: boolean;
  // UMG specific parameters.
  cost_center?: string;
  work_breakdown_structure?: string;
  general_ledger?: string;
  major_label?: MajorLabelEnum;
  umg_sub_label?: UMGSubLabelEnum;
  purchase_order_id?: number;
}

export interface SubmitBillingInfoParams {
  purchase_order_id: number;
  code?: string;
  cost_center?: string;
  work_breakdown_structure?: string;
  general_ledger?: string;
  major_label?: MajorLabelEnum;
  umg_sub_label?: UMGSubLabelEnum;
  order_number?: string;
}

export const submitBillingInfo = createAsyncThunk(
  SUBMIT_PURCHASE_ORDER_BILLING_INFO_API,
  async (billingInfo: SubmitBillingInfoParams[], thunkAPI) => {
    const args = { billing_info: billingInfo };
    const result =
      await makeBackendPostCallWithJsonResponse<DetailedPurchaseOrderWithTransaction>(
        SUBMIT_PURCHASE_ORDER_BILLING_INFO_API,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const createTransaction = createAsyncThunk(
  CREATE_TRANSACTION,
  async (args: bookingTransactionParams | undefined, thunkApi) => {
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      CREATE_TRANSACTION,
      args ?? {},
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkApi.dispatch(receiveErrors(errors));
    return thunkApi.rejectWithValue(errors);
  },
);

export interface CheckoutRecordingSessionExtensionParams {
  project_id?: number;
  recording_session_id?: number;
  recording_data?: RecordingTransactionData[];
}

export interface MarkRecordingSessionExtensionPaidParams {
  transaction_id: number;
  append_session_modification?: boolean;
}

export const checkoutRecordingSessionExtension = createAsyncThunk(
  RECORDING_SESSION_EXTENSION_CHECKOUT,
  async (args: CheckoutRecordingSessionExtensionParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      RECORDING_SESSION_EXTENSION_CHECKOUT,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const markRecordingSessionExtensionPaid = createAsyncThunk(
  RECORDING_SESSION_EXTENSION_PURCHASE,
  async (args: MarkRecordingSessionExtensionPaidParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      RECORDING_SESSION_EXTENSION_PURCHASE,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

// For label projects. Budget managers approve spends, submit billing info, and unlock final payments to service providers.
export interface PendingBudgetManager {
  email: string;
  can_approve_budget: boolean;
  can_submit_billing_info: boolean;
  approval_required: boolean;
}

// FE model which is ultimately combined with the PendingBudgetManager list to form one cohesive list.
export interface PendingPurchaseOrderApprover {
  email: string;
  approval_required: boolean;
}

export interface markTransactionPaidParams {
  transaction_id: number;
  title?: string;
  artist_name?: string;
  booked_with_purchase_order: boolean;
  emailsList?: string[];
  action?: string;
  order_number?: string;
  cost_center?: string;
  work_breakdown_structure?: string;
  general_ledger?: string;
  financial_method?: FinancialMethod;
  major_label?: MajorLabelEnum;
  umg_sub_label?: UMGSubLabelEnum;
  budget_managers?: PendingBudgetManager[];
  payment_intent_client_secret?: string;
}

export const markTransactionPaid = createAsyncThunk(
  PAID_TRANSACTION,
  async (args: markTransactionPaidParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      PAID_TRANSACTION,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface MarkRevisionPaidArgs {
  transaction_id: number;
}

interface CheckoutRevisionArgs {
  project_id: number;
  pay_with_purchase_order?: boolean;
  number_of_revisions: number;
}

export const checkoutRevisions = createAsyncThunk(
  REVISION_CHECKOUT,
  async (args: CheckoutRevisionArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      REVISION_CHECKOUT,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const markRevisionTransactionPaid = createAsyncThunk(
  PURCHASE_REVISIONS,
  async (args: MarkRevisionPaidArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Project>(
      PURCHASE_REVISIONS,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getPurchaseOrder = createAsyncThunk(
  GET_PURCHASE_ORDER_BY_CODE,
  async (args: { code: string }, thunkAPI) => {
    const params = `?code=${args.code}`;
    const result =
      await makeBackendGetCallWithJsonResponse<DetailedPurchaseOrder>(
        GET_PURCHASE_ORDER_BY_CODE,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface TransactionUpdateProjectData {
  id?: number;
  promocode?: string;
  service_type?: ProjectType;
  service_id: number;
  code?: string; // used in EngineeringServiceUniqueLink
  add_master?: boolean;
  add_atmos?: boolean;
  requested_date?: string;
  title?: string;
  artist_name?: string;
  prereq_id?: number;
  alts?: Alt[];
}

export interface TransactionUpdateRecordingData {
  id?: number; // Project ID.
  recording_service_id: number;
  service_type: number;
  service_provider_user_id?: number;
  first_choice_datetime: string;
  session_duration_minutes: number;
  title?: string;
  substitute_recording_location?: google.maps.places.PlaceResult;
}

export interface UpdateTransactionArgs {
  transactionId: number;
  projects_data?: TransactionUpdateProjectData[];
  recordings_data?: TransactionUpdateRecordingData[];
  engineer_has_files?: boolean;
  is_predefined?: boolean;
  in_progress_project?: boolean;
  apply_label_rate?: boolean;
  title?: string;
  artist_name?: string;
  estimated_delivery_date?: string | null;
  abortController?: AbortController;
  overwrite_price?: number | null;
  provider_assumes_fees?: boolean;
  share_artist_contact_info?: boolean;
  predefined_discount_option?: number | null;
}

export interface UpdateTransactionResponse {
  transaction_data: Transaction;
  estimated_delivery_date_data: {
    date: string;
    is_computed: boolean;
  };
}

export const updateTransaction = createAsyncThunk(
  UPDATE_TRANSACTION,
  async (args: UpdateTransactionArgs, thunkAPI) => {
    const { transactionId, abortController, ...requestBody } = args;
    const updateTransactionRoute = getUpdateTransactionAPIRoute(transactionId);
    const result =
      await makeBackendPostCallWithJsonResponse<UpdateTransactionResponse>(
        updateTransactionRoute,
        requestBody,
        abortController ? { signal: abortController.signal } : undefined,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface GetTransactionArgs {
  transactionCode?: string;
  transactionId?: number;
  budgetManagerCode?: string;
}

interface GetTransactionResponse {
  transaction: Transaction;
  scheduled_project: MinimalScheduledProject | null;
  engineering_service_unique_link: EngineeringUniqueBookingLinkAPIResponse | null;
  overwrite_price: number | null;
}

export const getTransaction = createAsyncThunk(
  GET_TRANSACTION,
  async (args: GetTransactionArgs, thunkAPI) => {
    const { transactionId, transactionCode, budgetManagerCode } = args;
    const paramsObj: Record<string, string> = {};
    if (transactionId) {
      paramsObj.transaction_id = transactionId.toString();
    }
    if (transactionCode) {
      paramsObj.transaction_code = transactionCode;
    }
    if (budgetManagerCode) {
      paramsObj.budget_manager_code = budgetManagerCode;
    }
    const result =
      await makeBackendGetCallWithJsonResponse<GetTransactionResponse>(
        GET_TRANSACTION,
        `?${new URLSearchParams(paramsObj).toString()}`,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface UpdateTransactionPaymentArgs {
  transaction_id: number;
  payment_id?: string;
  financial_method: FinancialMethod;
}

export const updateTransactionPayment = createAsyncThunk(
  UPDATE_TRANSACTION_PAYMENT,
  async (args: UpdateTransactionPaymentArgs, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      UPDATE_TRANSACTION_PAYMENT,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface CreateTransactionPaymentIntentArgs {
  transactionCode: string;
}

export const createTransactionPaymentIntent = createAsyncThunk(
  CREATE_TRANSACTION_PAYMENT_INTENT,
  async (args: CreateTransactionPaymentIntentArgs, thunkAPI) => {
    const { transactionCode } = args;
    const route = getCreateTransactionPaymentIntentRoute(transactionCode);
    const result = await makeBackendPostCallWithJsonResponse<Transaction>(
      route,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface ApplyTransactionPromoCodeArgs {
  transactionCode: string;
  promocode: string;
  promocode_studio_id?: number;
  promocode_user_id?: number;
}

export const applyTransactionPromoCode = createAsyncThunk(
  APPLY_TRANSACTION_PROMO_CODE,
  async (args: ApplyTransactionPromoCodeArgs, thunkAPI) => {
    const { transactionCode, ...rest } = args;
    const route = getApplyTransactionPromoCodeRoute(transactionCode);
    const result = await makeBackendPostCallWithJsonResponse<PromoCode>(
      route,
      rest,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const handleBudget = createAsyncThunk(
  HANDLE_BUDGET,
  async (
    args: { code: string; approve_budget?: boolean; rejection_reason?: string },
    thunkAPI,
  ) => {
    const result =
      await makeBackendPostCallWithJsonResponse<DetailedPurchaseOrderWithTransaction>(
        HANDLE_BUDGET,
        args,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const resendBillingLink = createAsyncThunk(
  RESEND_BILLING_LINK,
  async (args: { purchase_order_id: number }, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<object>(
      RESEND_BILLING_LINK,
      args,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = {
      errors: result.resultJson,
      purchase_order_id: args.purchase_order_id,
    };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export interface getAllTransactionsParams {
  transactionStatus?: TransactionStatus;
  page?: number;
  orderBy?: string;
  searchQuery?: string;
  transactionType?: number;
  startDate?: string;
  endDate?: string;
  downloadCsv?: boolean;
  studioId?: number;
}

const downloadCsv = function (
  content: string,
  fileName: string,
  mimeType: string,
) {
  const a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

interface GetAllTransactionsResponse {
  data: Transaction[];
  num_pages: number;
  page: number;
  count: number;
}

export const getAllTransactions = createAsyncThunk(
  GET_ALL_TRANSACTIONS,
  async (args: getAllTransactionsParams, thunkAPI) => {
    let params = ``;
    if (args.page) {
      params += `?page=${args.page}`;
    } else {
      params += `?page=1`;
    }
    if (args.orderBy) {
      params += `&order_by=${args.orderBy}`;
    }
    if (args.searchQuery) {
      params += `&search_query=${args.searchQuery}`;
    }
    if (args.startDate) {
      params += `&start_date=${args.startDate}`;
    }
    if (args.endDate) {
      params += `&end_date=${args.endDate}`;
    }
    if (args.transactionType) {
      params += `&transaction_type=${args.transactionType}`;
    }
    if (args.transactionStatus) {
      params += `&transaction_status=${args.transactionStatus}`;
    }
    if (args.downloadCsv) {
      params += `&csv=${args.downloadCsv}`;
    }
    if (args.studioId) {
      params += `&studio_id=${args.studioId}`;
    }
    const result = await makeBackendGetCall(GET_ALL_TRANSACTIONS, params);
    if (args.downloadCsv) {
      const data = await result.text();
      downloadCsv(
        data,
        "EngineEarsTransactions.csv",
        "text/csv;encoding:utf-8",
      );
      return {};
    }
    const resultJson = (await result.json()) as
      | GetAllTransactionsResponse
      | Error;
    if (result.status === 200) {
      return resultJson as GetAllTransactionsResponse;
    }
    const errors = { errors: resultJson as Error };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface GetTransactionInvoiceUrlParams {
  transaction_id?: number;
  scheduled_project_id?: number;
  share_link_code?: string;
  project_id?: number;
}

interface GetTransactionInvoiceUrlResponse {
  hosted_invoice_url: string;
}

export const getTransactionInvoiceUrl = createAsyncThunk(
  GET_TRANSACTION_INVOICE_URL,
  async (args: GetTransactionInvoiceUrlParams, thunkAPI) => {
    const paramsObj: Record<string, string> = {};
    if (args.share_link_code) {
      paramsObj.share_link_code = args.share_link_code;
    }
    if (args.transaction_id) {
      paramsObj.transaction_id = args.transaction_id.toString();
    }
    if (args.scheduled_project_id) {
      paramsObj.scheduled_project_id = args.scheduled_project_id.toString();
    }
    if (args.project_id) {
      paramsObj.project_id = args.project_id.toString();
    }
    const response =
      await makeBackendGetCallWithJsonResponse<GetTransactionInvoiceUrlResponse>(
        GET_TRANSACTION_INVOICE_URL,
        `?${new URLSearchParams(paramsObj).toString()}`,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface shareTransactionParams {
  transactionId: number;
  emails: string[];
}

interface ShareTransactionResponse {
  success: boolean;
}

export const shareTransaction = createAsyncThunk(
  SHARE_TRANSACTION,
  async (args: shareTransactionParams, thunkAPI) => {
    const { transactionId, ...requestBody } = args;
    const shareTransactionRoute = getShareTransactionAPIRoute(transactionId);
    const result =
      await makeBackendPostCallWithJsonResponse<ShareTransactionResponse>(
        shareTransactionRoute,
        requestBody,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface GetItemizedTransactionParams {
  abortController?: AbortController;
  getPurchaseOrder?: boolean;
  filterByUser?: boolean;
  transactionCode: string;
  scheduledProjectId?: number;
}

interface ItemizedRecordingServiceOrderSummaryItem {
  duration: number;
  type: RecordingProviderType;
  unit_price: number;
  username: string;
  discounted_unit_price: number;
  total_price: number;
}

interface ItemizedEngineeringServiceOrderSummaryItem {
  quantity: number;
  service_type: ProjectType;
  amount: number;
  unit_price: number;
  discounted_unit_price?: number;
  alts?: {
    info: Alt[];
    unit_price: number;
  };
}

export interface ItemizedPurchaseOrder {
  id: number;
  cost_center: string;
  eventually_owed_to: string | null;
  general_ledger: string;
  order_number: string;
  total: number;
  work_breakdown_structure: string;
}

export interface GetItemizedTransactionResponse {
  id: number;
  code: string;
  discount_percentage: number;
  discount_value: number;
  engineering_service_items: ItemizedEngineeringServiceOrderSummaryItem[];
  fees: number;
  in_progress_project: boolean;
  outstanding_balance: number;
  overwrite_price: number;
  purchase_orders?: ItemizedPurchaseOrder[];
  recording_service_type_map: Record<
    number,
    ItemizedRecordingServiceOrderSummaryItem
  >;
  subtotal: number;
  total: number;
}

export const getItemizedTransaction = createAsyncThunk(
  GET_ITEMIZED_TRANSACTION,
  async (args: GetItemizedTransactionParams, thunkAPI) => {
    const { filterByUser, getPurchaseOrder, transactionCode, abortController } =
      args;
    const paramsObj = {
      filter_by_user: filterByUser,
      get_purchase_order: getPurchaseOrder,
      transaction_code: transactionCode,
      scheduled_project_id: args.scheduledProjectId?.toString(),
    };
    const queryParams = `?${queryString.stringify(paramsObj, { skipEmptyString: true, skipNull: true })}`;
    const response =
      await makeBackendGetCallWithJsonResponse<GetItemizedTransactionResponse>(
        GET_ITEMIZED_TRANSACTION,
        queryParams,
        abortController ? { signal: abortController.signal } : undefined,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const getUmgBillingReport = createAsyncThunk(
  UMG_BILLING_REPORT,
  async () => {
    const result = await makeBackendGetCall(UMG_BILLING_REPORT, "");
    const data = await result.text();
    downloadCsv(data, "UMGBillingReport.csv", "text/csv;encoding:utf-8");
  },
);

interface CreatePaymentPlanArgs {
  transaction_id: number;
  service_type: ProjectType;
}

export const createPaymentPlan = createAsyncThunk(
  PAYMENT_PLAN_ENDPOINT,
  async (args: CreatePaymentPlanArgs, thunkAPI) => {
    const response = await makeBackendPostCallWithJsonResponse<Transaction>(
      PAYMENT_PLAN_ENDPOINT,
      args,
    );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface FetchTransactionStatusParams {
  transactionId?: number;
  scheduledProjectId?: number;
  projectId?: number;
  transactionCode?: string | null;
}

interface TransactionStatusResponse {
  status: TransactionStatus;
}

export const fetchTransactionStatus = createAsyncThunk(
  TRANSACTION_STATUS_API,
  async (
    {
      transactionId,
      scheduledProjectId,
      projectId,
      transactionCode,
    }: FetchTransactionStatusParams,
    thunkAPI,
  ) => {
    const queryParams = `?${queryString.stringify(
      {
        transaction_id: transactionId,
        transaction_code: transactionCode,
        scheduled_project_id: scheduledProjectId,
        project_id: projectId,
      },
      { skipNull: true, skipEmptyString: true },
    )}`;
    const response =
      await makeBackendGetCallWithJsonResponse<TransactionStatusResponse>(
        TRANSACTION_STATUS_API,
        queryParams,
      );
    if (response.success) {
      return response.resultJson;
    }
    const errors = { errors: response.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface TransactionsState {
  isLoading: boolean;
  updating: boolean;
  transactionData?: Transaction;
  scheduledProjectData?: MinimalScheduledProject;
  transactions: { [page: number]: Transaction[] };
  currentPage: number;
  totalPages: number;
  count: number;
  getTransactionInvoiceUrlLoading: boolean;
}

const initialState: TransactionsState = {
  isLoading: false,
  updating: false,
  transactionData: undefined,
  scheduledProjectData: undefined,
  transactions: {},
  currentPage: 1,
  totalPages: 0,
  count: 0,
  getTransactionInvoiceUrlLoading: false,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    clearTransactionsState: (state) => {
      state.isLoading = false;
      state.transactionData = undefined;
      state.scheduledProjectData = undefined;
      state.transactions = {};
      state.getTransactionInvoiceUrlLoading = false;
    },
    addMockTransaction: (state) => {
      state.transactionData = MockTransaction;
    },
    setTransactionsPage: (
      state: TransactionsState,
      action: PayloadAction<number>,
    ) => {
      state.currentPage = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionStatus.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(fetchTransactionStatus.rejected, (state) => {
      state.updating = false;
    });
    builder.addCase(fetchTransactionStatus.fulfilled, (state, action) => {
      state.updating = false;
      const transactionId = action.meta.arg.transactionId;
      if (
        state.transactionData &&
        state.transactionData?.id === transactionId
      ) {
        state.transactionData.transaction_status = action.payload.status;
      }
    });
    builder.addCase(createTransaction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      renameProjectOrScheduledProject.fulfilled,
      (state, action) => {
        const title = action.meta.arg.title;
        const transaction = action.payload as Transaction;
        if (transaction !== undefined && Object.keys(transaction).length > 0) {
          state.transactionData = transaction;
        }

        if (state.scheduledProjectData) {
          if (
            state.scheduledProjectData.id ===
            action.meta.arg.scheduled_project_id
          ) {
            state.scheduledProjectData.title = title;
          }
        }
      },
    );
    builder.addCase(submitBillingInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTransactions.fulfilled, (state, action) => {
      if (!action.meta.arg.downloadCsv) {
        const payload = action.payload as GetAllTransactionsResponse;
        state.count = payload.count;
        state.currentPage = payload.page;
        state.totalPages = payload.num_pages;
        state.transactions[payload.page] = payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        updating: false,
        scheduledProjectData: action.payload.scheduled_project ?? undefined,
        transactionData: action.payload.transaction,
      };
    });
    builder.addCase(getTransactionInvoiceUrl.pending, (state) => {
      state.getTransactionInvoiceUrlLoading = true;
    });
    builder.addCase(getTransactionInvoiceUrl.fulfilled, (state) => {
      state.getTransactionInvoiceUrlLoading = false;
    });
    builder.addCase(getTransactionInvoiceUrl.rejected, (state) => {
      state.getTransactionInvoiceUrlLoading = false;
    });
    builder.addCase(createPaymentPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPaymentPlan.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(markTransactionPaid.fulfilled, (state, action) => {
      state.transactionData = action.payload;
    });
    builder.addCase(createPaymentPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionData = action.payload;
    });
    builder.addCase(updateTransaction.pending, (state) => {
      return {
        ...state,
        updating: true,
      };
    });
    builder.addCase(updateTransaction.rejected, (state) => {
      return {
        ...state,
        updating: false,
      };
    });
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        updating: false,
        transactionData: action.payload.transaction_data,
      };
    });
    builder.addMatcher(
      isAnyOf(
        createTransaction.rejected,
        getTransaction.rejected,
        checkoutRevisions.rejected,
        getAllTransactions.rejected,
        submitBillingInfo.rejected,
        submitBillingInfo.fulfilled,
      ),
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(createTransaction.pending, getTransaction.pending),
      (state) => {
        state.isLoading = true;
        state.transactionData = undefined;
      },
    );
    builder.addMatcher(
      isAnyOf(
        createTransaction.fulfilled,
        checkoutRevisions.fulfilled,
        checkoutRecordingSessionExtension.fulfilled,
        updateTransactionPayment.fulfilled,
        createTransactionPaymentIntent.fulfilled,
      ),
      (state, action: PayloadAction<Transaction>) => {
        return {
          ...state,
          isLoading: false,
          updating: false,
          transactionData: action.payload,
        };
      },
    );
  },
});

export const {
  clearTransactionsState,
  addMockTransaction,
  setTransactionsPage,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
