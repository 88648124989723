import {
  discountValueToLabelMap,
  PredefinedDiscountOptions,
} from "../actions/mixMasterCartsStore";
import { GetItemizedTransactionResponse } from "../actions/transactions";

interface DiscountsOptions {
  predefinedDiscountOption: PredefinedDiscountOptions | null;
  providerAssumesFees: boolean;
}

export const getCartDiscountStatusAndValues = (
  transaction: GetItemizedTransactionResponse,
) => {
  const {
    subtotal,
    total,
    fees,
    discount_value: discountValue,
    discount_percentage: discountPercentage,
  } = transaction;
  const discountOptions: DiscountsOptions = {
    predefinedDiscountOption: null,
    providerAssumesFees: false,
  };

  const providerAssumesFeesStatus = subtotal - discountValue + fees !== total;
  if (discountPercentage) {
    const actualDiscountPercentage = !providerAssumesFeesStatus
      ? discountPercentage
      : ((subtotal - total) / subtotal) * 100;
    const predefinedDiscountOption = discountValueToLabelMap.get(
      actualDiscountPercentage,
    );
    if (predefinedDiscountOption) {
      discountOptions.predefinedDiscountOption = predefinedDiscountOption;
      discountOptions.providerAssumesFees = providerAssumesFeesStatus;
    } else {
      discountOptions.predefinedDiscountOption = null;
      discountOptions.providerAssumesFees = providerAssumesFeesStatus;
    }
  }
  return discountOptions;
};
