import { faCheck, faCircleQuestion } from "@fortawesome/sharp-solid-svg-icons";
import { Box, Grid, useTheme } from "@mui/material";
import { ImgHTMLAttributes, useState } from "react";
import { defaultIconServiceMapping } from "../../../../constants/googleStorage";
import useModal from "../../../../hooks/useModal";
import { ProjectType } from "../../../../store/models/project";
import { BaseModal } from "../../../core-ui/components/BaseModal/BaseModal";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { FixedMinWidthButton } from "../../../core-ui/components/Button/FixedMinWidthButton";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../core-ui/components/Text/TextUtils";
import {
  DefaultServicePhotoContainer,
  ServiceCardBaseModalContainer,
  ServiceCardBenefitItem,
  ServiceCardBenefitsContainer,
  ServiceCardBenefitsItemCheck,
  ServiceCardColumnContainer,
  ServiceCardImg,
  ServiceCardInfoContainer,
  ServiceCardQuestionMarkIcon,
  ServiceCardRowContainer,
  ServiceCardV2Container,
} from "./ServiceCardV2.styles";

// eslint-disable-next-line import/no-unused-modules
export interface ServiceCardV2Props {
  discountedRate?: string;
  originalRate: string;
  onClick: () => void;
  serviceName: string;
  serviceDescription: string;
  includedInServiceText: string[];
  description?: string | null;
  userOnOwnProfile: boolean;
  disabled: boolean;
  editMode: boolean;
  imageProps: ImgHTMLAttributes<HTMLImageElement>;
  isLoading: boolean;
  serviceType: ProjectType;
}

export const ServiceCardV2 = ({
  discountedRate,
  originalRate,
  onClick,
  serviceName,
  serviceDescription,
  includedInServiceText,
  description,
  userOnOwnProfile,
  disabled,
  editMode,
  imageProps,
  isLoading,
  serviceType,
}: ServiceCardV2Props) => {
  const theme = useTheme();
  const {
    isOpen: isDescriptionModalOpen,
    openModal: openDescriptionModal,
    closeModal: closeDescriptionModal,
  } = useModal();
  const [expandedDescription, setExpandedDescription] = useState(false);
  const isDescriptionExpandable =
    description?.length && description.length > MAX_DESCRIPTION_LENGTH;
  const displayedDescription =
    !expandedDescription && isDescriptionExpandable
      ? description?.slice(0, MAX_DESCRIPTION_LENGTH)
      : description;

  const buttonText = () => {
    if (editMode) {
      return "Edit Service";
    }
    if (userOnOwnProfile) {
      return "Generate Booking";
    }
    return "Book now";
  };

  const renderRate = () => {
    if (!discountedRate) {
      return (
        <Text variant={TextStyleVariant.P2}>
          <strong style={{ color: "inherit" }}>{originalRate}</strong>{" "}
          {serviceType === ProjectType.RECORDING ? "/hour" : "/song"}
        </Text>
      );
    }

    return (
      <Box sx={{ display: "flex" }}>
        <Text
          style={{ textDecorationLine: "line-through" }}
          variant={TextStyleVariant.P2}
        >
          {originalRate}{" "}
        </Text>
        <Text variant={TextStyleVariant.P2}>
          <strong style={{ color: "inherit" }}>{discountedRate}</strong>{" "}
          {serviceType === ProjectType.RECORDING ? "/hour" : "/song"}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <ServiceCardV2Container className="engineering-service-card">
        <div>
          <Text bold variant={TextStyleVariant.S3}>
            {serviceName}
          </Text>
          <Text variant={TextStyleVariant.P3} color={TextColor.SECONDARY}>
            {serviceDescription}
          </Text>
        </div>
        {imageProps.src ? (
          <ServiceCardImg {...imageProps} />
        ) : (
          <DefaultServicePhotoContainer>
            <img
              src={defaultIconServiceMapping[serviceType]}
              style={{ height: "2rem", width: "100%" }}
            />
          </DefaultServicePhotoContainer>
        )}
        <ServiceCardColumnContainer>
          <Button
            disabled={disabled || isLoading}
            variant={ButtonVariant.BLACK}
            onClick={onClick}
            loading={isLoading}
          >
            {buttonText()}
          </Button>

          <ServiceCardRowContainer>
            {renderRate()}
            <Button
              style={{ alignItems: "flex-end", minHeight: "unset" }}
              variant={ButtonVariant.UNSTYLED}
              onClick={openDescriptionModal}
            >
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Text
                  variant={TextStyleVariant.P2}
                  weight={TEXT_WEIGHT.SEMI_BOLD}
                >
                  {" "}
                  What&apos;s Included{" "}
                </Text>
                <ServiceCardQuestionMarkIcon
                  style={{ marginLeft: "4px" }}
                  icon={faCircleQuestion}
                />
              </Box>
            </Button>
          </ServiceCardRowContainer>
        </ServiceCardColumnContainer>
      </ServiceCardV2Container>
      <BaseModal
        open={isDescriptionModalOpen}
        header={serviceName}
        setOpen={
          isDescriptionModalOpen ? closeDescriptionModal : openDescriptionModal
        }
        hasStickyFooter={false}
        modalBodyStyle={{ padding: "20px" }}
      >
        <ServiceCardBaseModalContainer>
          <Text variant={TextStyleVariant.P1}>{serviceDescription}</Text>
          <Grid container rowSpacing={6} columnSpacing={5}>
            <Grid item xs={12} sm={6}>
              <ServiceCardInfoContainer>
                <Text bold variant={TextStyleVariant.P1}>
                  What&apos;s included:
                </Text>
                <ServiceCardBenefitsContainer>
                  {includedInServiceText.map((text, index) => (
                    <ServiceCardBenefitItem key={index}>
                      <ServiceCardBenefitsItemCheck
                        icon={faCheck}
                        width={12}
                        height={16}
                      />
                      <Text variant={TextStyleVariant.P1}>{text}</Text>
                    </ServiceCardBenefitItem>
                  ))}
                </ServiceCardBenefitsContainer>
              </ServiceCardInfoContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "24px",
                }}
              >
                {displayedDescription && (
                  <ServiceCardInfoContainer>
                    <Text bold variant={TextStyleVariant.P1}>
                      Service description:
                    </Text>
                    <Text color={TextColor.SECONDARY}>
                      {displayedDescription}
                      {isDescriptionExpandable && "..."}
                      {isDescriptionExpandable && (
                        <Button
                          variant={ButtonVariant.TEXT}
                          style={{
                            display: "inline",
                            minHeight: "unset",
                            color: theme.palette.info.main,
                          }}
                          onClick={() => setExpandedDescription((ex) => !ex)}
                        >
                          read {expandedDescription ? "less" : "more"}
                        </Button>
                      )}
                    </Text>
                  </ServiceCardInfoContainer>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    weight={TEXT_WEIGHT.SEMI_BOLD}
                    variant={TextStyleVariant.P2}
                  >
                    Price:{" "}
                  </Text>
                  {renderRate()}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <FixedMinWidthButton
              disabled={disabled || isLoading}
              variant={ButtonVariant.PRIMARY}
              onClick={onClick}
              loading={isLoading}
            >
              {buttonText()}
            </FixedMinWidthButton>
          </Box>
        </ServiceCardBaseModalContainer>
      </BaseModal>
    </>
  );
};

const MAX_DESCRIPTION_LENGTH = 315;
