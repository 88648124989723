import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Popover from "@radix-ui/react-popover";
import { useRef, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { toast } from "react-toastify";
import { useIsEntirePendingSessionDataBookable } from "../../../hooks/bookingHooks/useIsPendingSessionDataBookable";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useModal from "../../../hooks/useModal";
import { setLinkShareStatus } from "../../../store/actions/generateBookingStore";
import { createScheduledProjectShareLink } from "../../../store/actions/scheduledprojects";
import { shareTransaction } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RecordingSession } from "../../../store/models/recordingSession";
import { getProjectOverviewRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { getHost } from "../../../utils/utils";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { PopoverFooter } from "../../core-ui/components/PopConfirm/PopConfirm.styles";
import {
  BOTTOM_TAB_BAR_OVERLAY_ID,
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import CopyLinkButton from "./CopyLinkButton";
import "./ShareBookingLink.css";
import { ShareEmailInputContainer } from "./ShareBookingLink.styles";
import { startInProgressProject } from "../../../store/actions/projects";
import { useHistory } from "react-router-dom";

export interface ShareBookingLinkProps {
  cartHasContent: boolean;
  disabledText?: string;
  transactionId?: number;
  inProgressProject?: boolean;
  recordingSessions: RecordingSession[] | undefined;
  scheduledProjectId?: number;
}

export const ShareBookingLink = ({
  cartHasContent,
  disabledText,
  transactionId,
  inProgressProject = false,
  recordingSessions,
  scheduledProjectId,
}: ShareBookingLinkProps) => {
  const shareBookingLinkRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useMediaQueryBreakpoint();
  const history = useHistory();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const { inProgressProjectCreated } = useAppSelector(
    (state) => state.mixMasterCartsStore,
  );
  const [isInProgressProjectLoading, setIsInProgressProjectLoading] =
    useState(false);
  const { linkShareStatus, paywallOption } = useAppSelector(
    (state) => state.generateBookingStore,
  );
  const { updating: isTransactionUpdating } = useAppSelector(
    (state) => state.transactionStore,
  );
  const hasRecordingSessions = Boolean(recordingSessions?.length);
  const pendingSessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData,
  );
  const pendingSessionsAreBookable = useIsEntirePendingSessionDataBookable(
    pendingSessions,
    recordingSessions?.[0]?.studio_room,
  );
  const disableBookingForRecordingSessions =
    hasRecordingSessions && !pendingSessionsAreBookable;

  const [emails, setEmails] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const host = getHost();
  const {
    isOpen: isEmailLinkPopoverOpen,
    closeModal: closeEmailLinkPopover,
    setIsOpen: setEmailLinkPopoverOpen,
  } = useModal();

  const shareLinkWithEmails = () => {
    if (!transactionId) return;
    if (shareBookingLinkRef.current && !isDesktop) {
      shareBookingLinkRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (emails.length === 0) {
      toast.error("Please enter at least one email address");
      return;
    }
    emitAnalyticsTrackingEvent(
      "on_click_email_link",
      { transaction_id: transactionId, emails: emails },
      loggedInUser?.id,
    );
    setIsLoading(true);
    if (inProgressProject) {
      if (!scheduledProjectId) {
        toast.error("Failed to share project link");
        setIsLoading(false);
        return;
      }
      dispatch(
        createScheduledProjectShareLink({
          scheduled_project_id: scheduledProjectId.toString(),
          emails,
        }),
      )
        .then(() => {
          toast.success("Project link shared successfully");
          dispatch(setLinkShareStatus("email"));
          closeEmailLinkPopover();
        })
        .catch(() => toast.error("Failed to share project link"))
        .finally(() => setIsLoading(false));
    } else {
      dispatch(
        shareTransaction({ transactionId: transactionId, emails: emails }),
      )
        .then(() => {
          toast.success("Booking link shared successfully");
          dispatch(setLinkShareStatus("email"));
          closeEmailLinkPopover();
        })
        .catch(() => toast.error("Failed to share booking link"))
        .finally(() => setIsLoading(false));
    }
  };

  const copyInProgressShareLink = async () => {
    return dispatch(
      createScheduledProjectShareLink({
        scheduled_project_id: scheduledProjectId!.toString(),
      }),
    )
      .unwrap()
      .then((data) => {
        return `${host}${getProjectOverviewRoute(scheduledProjectId, data[0].code)}`;
      });
  };

  const onClickStartInProgressProject = async () => {
    if (!scheduledProjectId || !paywallOption) {
      return;
    }

    setIsInProgressProjectLoading(true);
    dispatch(
      startInProgressProject({
        scheduled_project_id: scheduledProjectId,
        default_paywall_option: paywallOption,
      }),
    )
      .unwrap()
      .then(() => {
        history.replace(getProjectOverviewRoute(scheduledProjectId));
      })
      .catch(() => {
        setIsInProgressProjectLoading(false);
      });
  };

  const shareBookingLinkButtons = (
    <div className="share-booking-link-buttons">
      {!inProgressProject && (
        <>
          <BasePopover
            isOpen={isEmailLinkPopoverOpen}
            setIsPopoverOpen={(open) => {
              if (open && linkShareStatus) return;
              setEmailLinkPopoverOpen(open);
            }}
            title="Share your booking link"
            showXButton
            hideFooter
            description={
              <>
                <ShareEmailInputContainer>
                  <ReactMultiEmail
                    placeholder="Start by typing an email..."
                    emails={emails}
                    onChange={(emails: string[]) => {
                      setEmails(emails);
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          data-tag
                          className="share-booking-link-email"
                          key={index}
                        >
                          <div data-tag-item style={{ marginRight: "8px" }}>
                            {email}
                          </div>
                          <div
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            <FontAwesomeIcon
                              icon={faXmark}
                              color={"var(--medium-grey)"}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      );
                    }}
                    className="share-booking-link-multi-email mb-4"
                  />
                </ShareEmailInputContainer>
                <PopoverFooter>
                  <CopyLinkButton
                    cartHasContent={cartHasContent}
                    disabled={disableBookingForRecordingSessions}
                    disabledText={disabledText}
                    inProgressProject={inProgressProject}
                    inProgressOnClick={
                      scheduledProjectId ? copyInProgressShareLink : undefined
                    }
                    transactionId={transactionId}
                  />
                  <Button
                    fullWidth={!isDesktop}
                    disabled={
                      !transactionId ||
                      !cartHasContent ||
                      disableBookingForRecordingSessions ||
                      emails.length === 0 ||
                      isLoading
                    }
                    variant={ButtonVariant.PRIMARY}
                    loading={isLoading}
                    disableText={"Please add at least one email address"}
                    onClick={shareLinkWithEmails}
                  >
                    Email Link
                  </Button>
                </PopoverFooter>
              </>
            }
          >
            <Popover.Trigger asChild>
              <Button
                fullWidth={!isDesktop}
                disabled={
                  !transactionId ||
                  !cartHasContent ||
                  disableBookingForRecordingSessions
                }
                variant={ButtonVariant.PRIMARY}
                loading={isLoading}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                disableText={disabledText || "Please select a service"}
              >
                Share this Booking
              </Button>
            </Popover.Trigger>
          </BasePopover>
        </>
      )}
      {inProgressProject && (
        <Button
          disabled={
            !cartHasContent ||
            !transactionId ||
            !inProgressProjectCreated ||
            isTransactionUpdating ||
            !paywallOption
          }
          loading={isInProgressProjectLoading}
          onClick={onClickStartInProgressProject}
          fullWidth={!isDesktop}
          variant={ButtonVariant.PRIMARY}
        >
          Start Project
        </Button>
      )}
    </div>
  );

  const stickyShareBookingLinkButtons = useBottomTabBarOverlayView(
    !isDesktop,
    shareBookingLinkButtons,
    DEFAULT_TAB_OVERLAY_CLASS,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  return (
    <div className="share-booking-link-container" ref={shareBookingLinkRef}>
      {isDesktop ? shareBookingLinkButtons : stickyShareBookingLinkButtons}
    </div>
  );
};
