import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { HTMLAttributes, useMemo } from "react";
import { UserCard } from "../../components/UserCard/UserCard";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { GenerateBookingButton } from "../../components/GenerateBookingButton/GenerateBookingButton";
import { useAppSelector } from "../../../store/hooks";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import classNames from "classnames";
import { UniqueBreakpoints } from "../../../utils/breakpoints";

export type ModularDashboardHeaderProps = HTMLAttributes<HTMLDivElement>;

const ModularDashboardHeader = ({
  className,
  ...props
}: ModularDashboardHeaderProps) => {
  const isMobile = useMediaQuery(UniqueBreakpoints.DashboardMobile);
  const { services, isLoading: isLoadingEngineerServices } = useAppSelector(
    (state) => state.engineerServices,
  );
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const isStudio = useMemo(
    () => selectedProfile.studio !== undefined,
    [selectedProfile.studio],
  );
  const showGenerateBookingButton = useMemo(
    () => services.length !== 0 || isStudio,
    [isStudio, services],
  );

  if (!loggedInUser) {
    return null;
  }

  return (
    <div className={classNames(["dashboard-header", className])} {...props}>
      <UserCard loggedInUser={loggedInUser} selectedProfile={selectedProfile} />
      <Button
        className="dashboard-view-profile-button"
        href={
          selectedProfile.studio
            ? getStudioScreenRoute(selectedProfile.studio.username)
            : getProfileScreenRoute(selectedProfile.user?.username || "")
        }
        onClick={() => {
          emitAnalyticsTrackingEvent(
            "dashboard_on_click_view_profile",
            {},
            loggedInUser?.id,
          );
        }}
        variant={ButtonVariant.OUTLINED}
      >
        View Profile
      </Button>
      {showGenerateBookingButton && !isMobile && (
        <GenerateBookingButton
          className="dashboard-start-a-project-button"
          disabled={services.length === 0 && !isStudio}
          isLoadingEngineerServices={Boolean(isLoadingEngineerServices)}
          isMobile={isMobile}
          activeStudio={selectedProfile.studio}
        />
      )}
    </div>
  );
};

export default ModularDashboardHeader;
