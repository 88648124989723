import { Box, TextField } from "@mui/material";
import { ChangeEvent, useRef, useState } from "react";

interface VerificationCodeInputProps {
  codeLength: number;
  onComplete: (code: string) => void;
}

const VerificationCodeInput = ({
  codeLength,
  onComplete,
}: VerificationCodeInputProps) => {
  const [code, setCode] = useState(
    Array.from({ length: codeLength }).map(() => ""),
  );
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const processInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    slot: number,
  ) => {
    const num: number = parseInt(e.target.value);
    if (Number.isNaN(num)) return;
    const newCode = [...code];
    newCode[slot] = num.toString();
    setCode(newCode);
    if (slot !== codeLength - 1) {
      inputRefs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      // this should trigger VerifyEmailAPI
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, slot: number) => {
    if (e.key === "Backspace") {
      const newCode = [...code];
      newCode[slot] = "";
      setCode(newCode);
      if (slot === 0) return;
      // let's you edit current and doesn't just move to previous on delete
      if (code[slot] === "") {
        inputRefs.current[slot - 1].focus();
      }
    }
  };

  const onInputPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const content = e.clipboardData.getData("text/plain");
    if (content.length === codeLength) {
      const newCode = content.split("");
      setCode(newCode);
      newCode.forEach((num, index) => {
        inputRefs.current[index].value = num;
      });
      onComplete(newCode.join(""));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "center",
      }}
    >
      {code.map((_, index) => (
        <TextField
          sx={{ width: "48px" }}
          inputProps={{
            maxLength: 1,
            align: "center",
            sx: { textAlign: "center" },
          }}
          inputMode="numeric"
          value={code[index]}
          key={index}
          onChange={(e) => processInput(e, index)}
          onKeyUp={(e) => onKeyUp(e, index)}
          inputRef={(ref: HTMLInputElement | null): void => {
            if (ref) {
              inputRefs.current[index] = ref;
            }
          }}
          onPaste={onInputPaste}
        />
      ))}
    </Box>
  );
};

export default VerificationCodeInput;
