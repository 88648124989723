import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { useArtistReleases } from "../../../hooks/releaseAPIhooks";
import { ReleaseType, ReleaseTypeOptions } from "../../../store/models/release";
import { ReleaseList } from "../ReleaseList/ReleaseList";
import {
  ArtistReleaseButtonContainer,
  ArtistReleasesContainer,
  ArtistReleasesHeader,
  ReleaseFilterButton,
} from "./ArtistReleases.styles";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { useState } from "react";
import { ReleaseTabViewSectionProps } from "../ArtistReleaseTabView/ArtistReleaseTabView";

interface ArtistReleasesProps extends ReleaseTabViewSectionProps {
  onReleaseClick: (releaseId?: number) => void;
}

export const ArtistReleases = ({
  shouldShowDesktop,
  onReleaseClick,
}: ArtistReleasesProps) => {
  const [filter, setFilter] = useState(ReleaseType.NO_RELEASE_TYPE);
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);

  const { data, isPending } = useArtistReleases(userData?.id || 0, filter);

  const releasesData = data?.pages.map((page) => page.data).flat() ?? [];

  return (
    <ArtistReleasesContainer
      style={{
        marginLeft: shouldShowDesktop ? 0 : "16px",
        marginRight: shouldShowDesktop ? 0 : "16px",
      }}
    >
      <ArtistReleasesHeader>
        <Text bold variant={TextStyleVariant.S2}>
          Catalog
        </Text>
        <ArtistReleaseButtonContainer>
          {ReleaseTypeOptions.map((option) => (
            <ReleaseFilterButton
              key={option.value}
              $selected={filter === option.value}
              onClick={() => setFilter(option.value)}
            >
              <Text
                color={
                  filter === option.value
                    ? TextColor.PRIMARY_NO_DARK_THEME
                    : TextColor.SECONDARY
                }
                variant={TextStyleVariant.P2}
              >
                {option.label}
              </Text>
            </ReleaseFilterButton>
          ))}
        </ArtistReleaseButtonContainer>
      </ArtistReleasesHeader>
      <ReleaseList
        onReleaseClick={onReleaseClick}
        isPending={isPending}
        releases={releasesData}
      />
    </ArtistReleasesContainer>
  );
};
