import React, { FC, useMemo } from "react";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import "./SubscriptionCard.css";
import "./SubscriptionCard.css";
import {
  useEngineerSubscriptionBenefits,
  useStudioSubscriptionBenefits,
  useSubscriptionFeePercentage,
  useSubscriptionName,
  useSubscriptionPrice,
  useSubscriptionTrophy,
} from "../../../hooks/useSubscriptionTrophy";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { toast } from "react-toastify";

export interface SubscriptionCardProps {
  plan: SUBSCRIPTION_PLAN;
  onSubscriptionSelected: () => void;
  recommended: boolean;
  showPrice?: boolean;
  buttonDisabled?: boolean;
  buttonDisabledMessage?: string;
  useStudioBenefits?: boolean;
}

export interface SubscriptionDetails {
  name: string;
  percentage: number;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  plan,
  onSubscriptionSelected,
  recommended,
  showPrice = true,
  buttonDisabled = false,
  buttonDisabledMessage,
  useStudioBenefits = false,
}) => {
  const icon = useSubscriptionTrophy(plan);
  const benefits = useEngineerSubscriptionBenefits(plan);
  const studioBenefits = useStudioSubscriptionBenefits(plan);
  const subscriptionName = useSubscriptionName(plan);
  const subscriptionFeePercentage = useSubscriptionFeePercentage(plan);
  const price = useSubscriptionPrice(plan);

  if (benefits.length == 0) return null;

  return (
    <div
      className={"subscription-card-container "
        .concat(recommended ? "recommended" : "")
        .concat(!showPrice ? "hide-price" : "")}
    >
      <div className="subscription-card-header">
        <img alt={"trophy"} height={27.51} width={27.51} src={icon} />
        <h4 className="subscription-name-header mt-2">{subscriptionName}</h4>
        <p>{`${subscriptionFeePercentage}% per ${
          useStudioBenefits ? "session" : "project"
        } service fee`}</p>
      </div>
      <div className="what-included-subscription-container">
        <p className="h7-semi-bold">What's included</p>
        <ul className="benefit-unordered-list">
          {(useStudioBenefits ? studioBenefits : benefits).map(
            (benefit, index) => {
              return (
                <li
                  className={"benefit-list-item b2"}
                  key={`${benefit}-${index}`}
                >
                  {benefit}
                </li>
              );
            },
          )}
        </ul>
      </div>
      {showPrice && (
        <>
          <div className="subscription-price-container">
            <span className={"sub-price-wrapper"}>
              <p className={"subscription-price-section h3"}>{price}</p>
            </span>
          </div>
          <div
            className={`subscribe-button ${
              buttonDisabled ? "disabled-subscribe-button" : ""
            }`}
            onClick={
              buttonDisabled
                ? () => {
                    toast.error(buttonDisabledMessage);
                    console.log("Test");
                  }
                : onSubscriptionSelected
            }
          >
            <p>Get Started</p>
          </div>
        </>
      )}
    </div>
  );
};
