import {
  useFetchRelease,
  useFetchReleaseTracks,
} from "../../../hooks/releaseAPIhooks";
import { ReleaseTabViewSectionProps } from "./ArtistReleaseTabView";
import { FeaturedArtistRelease } from "../FeaturedArtistRelease/FeaturedArtistRelease";
import { ArtistReleaseTrackTable } from "../ArtistReleaseTrackTable/ArtistReleaseTrackTable";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSetPlayList } from "../../../hooks/audioPlayerHooks/useGetFooterPlayerRef";
import { useAppSelector } from "../../../store/hooks";
import { FooterFileTrackType } from "../../../store/actions/abPlayerStore";
import { convertArtistReleaseToPlaylistTrack } from "../../../store/models/playListTrack";
import { useIsMounted } from "usehooks-ts";

interface ReleaseDetailViewProps extends ReleaseTabViewSectionProps {
  releaseId: number;
  navigateBack: () => void;
}

export const ReleaseDetailView = ({
  releaseId,
  shouldShowDesktop,
  navigateBack,
  size,
}: ReleaseDetailViewProps) => {
  const [autoSetPlayList, setAutoSetPlayList] = useState(false);
  const isMounted = useIsMounted();
  const { data: release, isPending } = useFetchRelease(releaseId);
  const { data: releaseTracks } = useFetchReleaseTracks(releaseId);
  const { playlistId } = useAppSelector((state) => state.abPlayerStore);
  const setPlayList = useSetPlayList();

  useEffect(() => {
    if (!isMounted) return;
    if (autoSetPlayList) return;
    if (!releaseId) {
      return;
    }
    if (playlistId == releaseId) {
      return;
    }
    setAutoSetPlayList(true);
    setPlayList({
      tracks: convertArtistReleaseToPlaylistTrack(releaseTracks),
      index: 0,
      playOnLoad: false,
      playlistId: releaseId,
      footerFileTrackType: FooterFileTrackType.RELEASE,
    });
  }, [
    releaseTracks,
    releaseId,
    playlistId,
    setPlayList,
    isMounted,
    autoSetPlayList,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "54px" }}>
      <FeaturedArtistRelease
        release={release}
        ctaText={"Add to playlist"}
        onReleaseClick={() => {
          toast.error("Coming Soon");
        }}
        isReleaseLoading={isPending}
        shouldShowDesktop={shouldShowDesktop}
        size={size}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text bold variant={TextStyleVariant.S2}>
            Project Songs
          </Text>
          <Button onClick={navigateBack} variant={ButtonVariant.UNSTYLED}>
            <FontAwesomeIcon
              style={{
                marginRight: "4px",
              }}
              icon={faAngleLeft}
            />
            <Text>Back</Text>
          </Button>
        </Box>
        <ArtistReleaseTrackTable
          artistUserId={release?.artist_user_id}
          releaseTracks={releaseTracks}
        />
      </Box>
    </Box>
  );
};
