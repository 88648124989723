import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
} from "../utils/fetch";
import { receiveErrors } from "./errorStore";
import {
  FAVORITED_ACCOUNT_API,
  FETCH_CHART_DATA,
  FETCH_PENDING_PROJECT_COUNTS,
  FETCH_ALL_USER_FAVORITES,
} from "../utils/routes";
import {
  convertFavoritedAccountEntity,
  FavoritedAccount,
  FavoritedAccountEntity,
} from "../models/favoritedAccount";
import { fetchFavorites } from "./favorites";
import {
  ChartDataFilter,
  DateRanges,
} from "../../stories/components/PerformanceChart/PerformanceChart";
import { ProjectType } from "../models/project";
import { Studio } from "../models/studio";

interface PendingProjectCounts {
  pendingScheduledProjectCount: number;
  pendingRecordingSessionBookingCount: number;
}
interface UserFavorites {
  liked_users: number[];
  liked_studios: number[];
  liked_studio_rooms: number[];
}

interface UserDashboard {
  favoritedAccounts: FavoritedAccount[];
  pendingProjects: PendingProjectCounts;
  userFavorites: UserFavorites | null;
}

const initialState: UserDashboard = {
  favoritedAccounts: [],
  pendingProjects: {
    pendingScheduledProjectCount: 0,
    pendingRecordingSessionBookingCount: 0,
  },
  userFavorites: null,
};

export interface fetchChartDataParams {
  studio_ids?: number[];
  data_filter: ChartDataFilter;
  start_date: string;
  end_date: string;
  date_range_filter: DateRanges;
  isUserEngineer: boolean;
  filter_by?: number[];
  userStudios?: Studio[];
}

export interface DataPoint {
  x: string;
  y: number;
}
export interface ChartData {
  id: string;
  grand_total: number;
  color?: string;
  data: DataPoint[];
}
export interface FetchChartDataResponse {
  chartData: ChartData[];
  currentDate: string;
  dateFormat: string;
  grandTotal: number;
  previousDateRangeData: number;
  previousStartDate: string;
  previousEndDate: string;
  grossVolumePercentage: number;
  sessionsBookedPercentage: number;
  projectsBookedPercentage: number;
  profileVisitsPercentage: number;
}

export const fetchChartData = createAsyncThunk(
  FETCH_CHART_DATA,
  async (args: fetchChartDataParams, thunkAPI) => {
    let params: string = "?"
      .concat(
        args.studio_ids ? `studio_ids=${args.studio_ids.toString()}&` : "",
      )
      .concat(args.data_filter ? `data_filter=${args.data_filter}&` : "")
      .concat(args.start_date ? `start_date=${args.start_date}&` : "")
      .concat(args.end_date ? `end_date=${args.end_date}&` : "")
      .concat(
        args.date_range_filter
          ? `date_range_filter=${args.date_range_filter}&`
          : "",
      )
      .concat(
        args.isUserEngineer ? `is_user_engineer=${args.isUserEngineer}&` : "",
      )
      .concat(
        args.filter_by?.some((filter) => filter === 0) &&
          args.data_filter !== 0 &&
          args.data_filter !== undefined
          ? `${[
              ProjectType.MIXING,
              ProjectType.MASTERING,
              ProjectType.TWO_TRACK_MIXING,
              ProjectType.ATMOS_MIXING,
              ProjectType.RECORDING,
            ].join(",")}`
          : `filter_by=${args.filter_by?.join(",")}&`,
      );
    params = params.endsWith("&") ? params.slice(0, -1) : params; // Remove trailing '&'
    const result =
      await makeBackendGetCallWithJsonResponse<FetchChartDataResponse>(
        FETCH_CHART_DATA,
        params,
      );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

interface createFavoritedAccountParams {
  liked_user?: number;
  liked_studio?: number;
  liked_studio_room?: number;
}

export const createFavoritedAccount = createAsyncThunk(
  FAVORITED_ACCOUNT_API + "-post",
  async (favoritedAccount: createFavoritedAccountParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<FavoritedAccount>(
      FAVORITED_ACCOUNT_API,
      favoritedAccount,
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const deleteFavoritedAccount = createAsyncThunk(
  FAVORITED_ACCOUNT_API + "-delete",
  async (favoritedAccountParams: createFavoritedAccountParams, thunkAPI) => {
    const result = await makeBackendPostCallWithJsonResponse<FavoritedAccount>(
      FAVORITED_ACCOUNT_API,
      { ...favoritedAccountParams, delete: true },
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const fetchAllUserFavorites = createAsyncThunk(
  FETCH_ALL_USER_FAVORITES,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<UserFavorites>(
      FETCH_ALL_USER_FAVORITES,
      "",
    );
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const fetchPendingProjectCounts = createAsyncThunk(
  FETCH_PENDING_PROJECT_COUNTS,
  async (_, thunkAPI) => {
    const result = await makeBackendGetCallWithJsonResponse<{
      pending_scheduled_projects_count: number;
      pending_recording_session_bookings_count: number;
    }>(FETCH_PENDING_PROJECT_COUNTS, "");
    if (result.success) {
      return result.resultJson;
    }
    const errors = { errors: result.resultJson };
    thunkAPI.dispatch(receiveErrors(errors));
    return thunkAPI.rejectWithValue(errors);
  },
);

export const userDashboardSlice = createSlice({
  name: "userDashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPendingProjectCounts.fulfilled, (state, action) => {
      state.pendingProjects = {
        pendingScheduledProjectCount:
          action.payload.pending_scheduled_projects_count,
        pendingRecordingSessionBookingCount:
          action.payload.pending_recording_session_bookings_count,
      };
    });
    builder.addCase(createFavoritedAccount.fulfilled, (state, action) => {
      state.favoritedAccounts.push(action.payload);
      if (state.userFavorites) {
        const { liked_user, liked_studio, liked_studio_room } = action.payload;
        if (liked_user) {
          state.userFavorites.liked_users.push(liked_user);
        }
        if (liked_studio) {
          state.userFavorites.liked_studios.push(liked_studio);
        }
        if (liked_studio_room) {
          state.userFavorites.liked_studio_rooms.push(liked_studio_room);
        }
      }
    });
    builder.addCase(fetchFavorites.fulfilled, (state, action) => {
      const data: FavoritedAccountEntity[] = action.payload.data;
      data.forEach((account) => {
        const favoritedAccount: FavoritedAccount =
          convertFavoritedAccountEntity(account);
        if (
          !state.favoritedAccounts.some(
            (stateFavoritedAccount) =>
              stateFavoritedAccount.id === favoritedAccount.id,
          )
        ) {
          state.favoritedAccounts.push(favoritedAccount);
        }
      });
    });
    builder.addCase(deleteFavoritedAccount.fulfilled, (state, action) => {
      const index = state.favoritedAccounts.findIndex(
        (favoritedAccount) => favoritedAccount.id === action.payload.id,
      );
      state.favoritedAccounts.splice(index, 1);

      if (state.userFavorites) {
        const { liked_user, liked_studio, liked_studio_room } = action.payload;

        if (liked_user) {
          state.userFavorites.liked_users =
            state.userFavorites.liked_users.filter(
              (user) => user !== liked_user,
            );
        }
        if (liked_studio) {
          state.userFavorites.liked_studios =
            state.userFavorites.liked_studios.filter(
              (studio) => studio !== liked_studio,
            );
        }
        if (liked_studio_room) {
          state.userFavorites.liked_studio_rooms =
            state.userFavorites.liked_studio_rooms.filter(
              (studio_room) => studio_room !== liked_studio_room,
            );
        }
      }
    });
    builder.addCase(fetchAllUserFavorites.fulfilled, (state, action) => {
      state.userFavorites = action.payload;
    });
  },
});

export default userDashboardSlice.reducer;
