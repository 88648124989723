import { Elements } from "@stripe/react-stripe-js";
import { useAtomValue } from "jotai";
import {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Drawer from "react-bottom-drawer";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { topNavHeightAtom } from "../../../atoms/navAtoms";
import { darkModeAtom } from "../../../atoms/user/darkModeAtom";
import { CHANNEL_ID_PREFIX } from "../../../constants/chat";
import { getDocUrl } from "../../../constants/googleStorage";
import { TRACKING_EVENTS_NAME } from "../../../constants/trackingEvents";
import { useAccountStatus } from "../../../hooks/accountHooks";
import {
  SessionDetail,
  emptySessionBookingText,
  useIsEntirePendingSessionDataBookable,
  useMissingBookingSessionDetails,
} from "../../../hooks/bookingHooks/useIsPendingSessionDataBookable";
import { isInProgressProjectTransaction } from "../../../hooks/partialPaymentHooks";
import {
  convertUSDToCents,
  usePartialPaymentDateAvailable,
  useTransactionTotalAsPennies,
} from "../../../hooks/transactionHook";
import { useIsAandR } from "../../../hooks/useIsAandR";
import useModal from "../../../hooks/useModal";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { SCREENS } from "../../../routes";
import {
  createPaymentPlan,
  createTransactionPaymentIntent,
  fetchTransactionStatus,
  markTransactionPaid,
  updateTransactionPayment,
} from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import {
  FinancialMethod,
  TransactionStatus,
} from "../../../store/models/transaction";
import { selectInvoiceTransactionFields } from "../../../store/selectors/transcationSelectors";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { stripePromise } from "../../../store/utils/stripe";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { DefaultSoundWaveLoader } from "../../elements/DefaultSoundWaveLoader/DefaultSoundWaveLoader";
import { PayPalButton } from "../../elements/PayPalButton/PayPalButton";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { ChatButton } from "../ChatButton/ChatButton";
import {
  ContactInfoModal,
  MISSING_EMAIL_VERIFICATION_MESSAGE,
  MISSING_PHONE_NUMBER_MESSAGE,
} from "../ContactInfoModal/ContactInfoModal";
import {
  BOTTOM_TAB_BAR_FOCUSED_OVERLAY_ID,
  BOTTOM_TAB_BAR_OVERLAY_ID,
  DEFAULT_TAB_OVERLAY_CLASS,
  NO_PADDING_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import { PurchaseOrderCheckoutForm } from "../PurchaseOrderCheckoutForm/PurchaseOrderCheckoutForm";
import {
  StripePaymentForm,
  StripePaymentFormHandles,
  StripePaymentFormProps,
} from "../StripePaymentForm/StripePaymentForm";
import { UnauthenticatedModal } from "../UnauthenticatedModal/UnauthenticatedModal";
import {
  PaymentGatewayModuleContainer,
  PaymentGatewayModuleDrawer,
  PaymentGatewayModuleFooter,
  PaymentGatewayModuleFooterActionsRow,
  PaymentGatewayModuleH6,
  PaymentGatewayModuleP,
  PaymentGatewayModuleRefundPolicy,
  PaymentPlanInProgressContainer,
} from "./PaymentGatewayModule.styles";

export interface PaymentGatewayModuleProps extends StripePaymentFormProps {
  isMobile: boolean;
  redirectPath?: string;
  forceEnablePayment?: boolean;
  // children success view with button to navigate to the next page
  children: ReactNode;
  // secondary CTA
  secondaryButton?: ReactNode;
  // following props are specifically for partial payment options
  minimumDepositPercentage?: number | null;
  serviceType?: ProjectType;
  // Toast message to display when the user tries to checkout
  disabledText?: string;
}

interface StripeError {
  message: string;
  type: string;
}

export const PaymentGatewayModule = ({
  transaction,
  isMobile,
  artistName,
  projectTitle,
  scheduledProjectIdForHandoff,
  redirectPath,
  forceEnablePayment = false,
  children: successView,
  secondaryButton = null,
  minimumDepositPercentage = 1,
  serviceType,
  disabledText,
}: PaymentGatewayModuleProps) => {
  const { user, isAuthenticated } = useAppSelector(
    (state) => state.accountInfo,
  );
  const { updating: isTransactionUpdating } = useAppSelector(
    (state) => state.transactionStore,
  );
  const { missingPhoneNumber, emailVerified } = useAccountStatus();
  const history = useHistory();
  const isDarkMode = useAtomValue(darkModeAtom);

  const navHeight = useAtomValue(topNavHeightAtom);
  const isAandR = useIsAandR(user);
  const [markedPaid, setMarkedPaid] = useState(false);
  const [markedPartiallyPaid, setMarkedPartiallyPaid] = useState(false);
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const [showStripeMobileMenu, setShowStripeMobileMenu] = useState(false);
  const [creatingPaymentIntent, setCreatingPaymentIntent] = useState(false);
  const [showPurchaseOrderMobileMenu, setShowPurchaseOrderMobileMenu] =
    useState(false);
  const [paymentPlanLoading, setPaymentPlanLoading] = useState(false);
  const [showPartialPaymentIndicator, setShowPartialPaymentIndicator] =
    useState(false);
  const [showStripePaymentForm, setShowStripePaymentForm] = useState(false);
  const stripePaymentFormRef = useRef<StripePaymentFormHandles>(null);
  const orderSummary = useAppSelector(
    (state) =>
      state.mixMasterCartsStore.orderSummary ||
      state.recordingCartsStore.orderSummary,
  );
  const totalPriceCents = convertUSDToCents(orderSummary?.totalPrice || 0);
  const amount = useTransactionTotalAsPennies(transaction) || totalPriceCents;
  const amountInDollars = amount / 100;
  const darkMode = useAtomValue(darkModeAtom);
  const dispatch = useAppDispatch();
  const hasRecordingSessions = Boolean(transaction.recording_sessions?.length);
  const pendingSessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData,
  );
  const pendingSessionsAreBookable = useIsEntirePendingSessionDataBookable(
    pendingSessions,
    hasRecordingSessions
      ? transaction.recording_sessions![0].studio_room
      : undefined,
  );
  const hasStripeTransactionPaymentIntent = Boolean(
    transaction.stripe_session_id,
  );
  const hasTransactionPaymentID = Boolean(transaction.payment_id);
  const {
    transaction_status: status,
    hosted_invoice_url: invoicePaymentPlanUrl,
    id: transactionId,
  } = useAppSelector(selectInvoiceTransactionFields);
  const transactionCodeQuery = useQueryParam("transaction_code");
  const transactionCode = transactionCodeQuery.get();
  const inProgressProjectTransaction =
    isInProgressProjectTransaction(transaction);

  const partialPaymentIntervalRef = useRef<ReturnType<
    typeof setInterval
  > | null>(null);

  const disableBookingForRecordingSessions =
    hasRecordingSessions && !pendingSessionsAreBookable && !forceEnablePayment;
  const missingSessionDetail = useMissingBookingSessionDetails();
  const showMissingSessionDetail = missingSessionDetail !== SessionDetail.NONE;

  const isCheckoutButtonDisabled =
    isTransactionUpdating ||
    markedPaid ||
    disableBookingForRecordingSessions ||
    paymentPlanLoading;

  const hasIncompleteContactInfo = !emailVerified || missingPhoneNumber;

  const {
    isOpen: showUnauthenticatedModal,
    openModal: openUnauthenticatedModal,
    closeModal: closeUnauthenticatedModal,
  } = useModal();

  const {
    isOpen: showMissingContactInfoModal,
    openModal: openMissingContactInfoModal,
    closeModal: closeMissingContactInfoModal,
  } = useModal();

  const partialPaymentDateAvailable = usePartialPaymentDateAvailable(
    pendingSessions,
    3,
  );

  const showPartialPaymentOption = useMemo(() => {
    if (!isAuthenticated) return false;
    return (
      partialPaymentDateAvailable &&
      minimumDepositPercentage &&
      minimumDepositPercentage < 1 &&
      !isAandR &&
      !inProgressProjectTransaction
    );
  }, [
    partialPaymentDateAvailable,
    minimumDepositPercentage,
    isAandR,
    isAuthenticated,
    inProgressProjectTransaction,
  ]);

  const partialPaymentButtonText = useMemo(() => {
    if (!amountInDollars || !minimumDepositPercentage) return "";
    const deposit = amountInDollars * minimumDepositPercentage;
    const remainingAmount = amountInDollars * (1 - minimumDepositPercentage);

    if (isMobile) {
      return `Pay ${PennyDollarFormatter().format(deposit)} now`;
    }
    return `Pay ${PennyDollarFormatter().format(deposit)} now, ${PennyDollarFormatter().format(remainingAmount)} later`;
  }, [minimumDepositPercentage, amountInDollars, isMobile]);

  // Generates a stripe payment intent for the given transaction.
  // Must be called after the user is authenticated.
  const generateStripePaymentIntent = async (isPaypal = false) => {
    if (isPaypal) return false;
    // Payment plan already creates a payment intent, return true if already exists.
    if (hasStripeTransactionPaymentIntent) return true;

    try {
      setCreatingPaymentIntent(true);
      await dispatch(
        createTransactionPaymentIntent({
          transactionCode: transaction.code,
        }),
      ).unwrap();
    } finally {
      setCreatingPaymentIntent(false);
    }

    return true;
  };

  const handleSubmittingPayment = async (isPaypal = false) => {
    if (markedPaid) return;

    if (!isAuthenticated) {
      openUnauthenticatedModal();
      return;
    }

    if (hasIncompleteContactInfo) {
      openMissingContactInfoModal();
      return;
    }

    // If the transaction does not have a payment intent,
    // create one with an API call. This will allow the user
    // to see the associated payment options.
    if (!showStripePaymentForm) {
      const didGenerateStripePaymentIntent =
        await generateStripePaymentIntent(isPaypal);
      if (didGenerateStripePaymentIntent) {
        setShowStripePaymentForm(true);
        return;
      }
    }

    try {
      setSubmittingPayment(true);
      if (isPaypal) {
        emitAnalyticsTrackingEvent(
          "mark_paid_scheduled_project",
          {
            transaction_id: `${transaction.id}`,
            value: transaction.total_price ?? 0,
          },
          user?.id,
        );

        await dispatch(
          markTransactionPaid({
            transaction_id: transaction.id,
            title: projectTitle,
            artist_name: artistName,
            booked_with_purchase_order: false,
            financial_method: FinancialMethod.PAYPAL,
          }),
        ).unwrap();
      } else {
        await stripePaymentFormRef.current?.handleSubmit();
      }
      setMarkedPaid(true);
    } catch (e) {
      const error = e as StripeError;
      if (error.type === "invalid_request_error") {
        toast.error(
          "An error occurred, please try submitting your billing info again.",
        );
        await updateTransactionPaymentToStripe();
      } else {
        toast.error(error.message);
      }
    } finally {
      setSubmittingPayment(false);
    }
  };

  const handleCreatePaymentPlan = async () => {
    if (!transactionId || !serviceType) return;

    if (!isAuthenticated) {
      openUnauthenticatedModal();
      return;
    }

    if (hasIncompleteContactInfo) {
      openMissingContactInfoModal();
      return;
    }

    setPaymentPlanLoading(true);
    await dispatch(
      createPaymentPlan({
        transaction_id: transactionId,
        service_type: serviceType,
      }),
    )
      .unwrap()
      .then(() => {
        setShowPartialPaymentIndicator(true);
        setPaymentPlanLoading(false);
        if (isMobile) {
          setShowStripeMobileMenu(true);
        }
      })
      .catch(() => {
        setPaymentPlanLoading(false);
      });
  };

  const handleCancelPaymentPlan = () => {
    setShowPartialPaymentIndicator(false);
    void updateTransactionPaymentToStripe();
  };

  useEffect(() => {
    if (!transactionId) return;
    if (!minimumDepositPercentage || minimumDepositPercentage >= 1) return;
    if (!invoicePaymentPlanUrl) return;
    // Set an interval to continue fetching transaction status every 5 seconds
    partialPaymentIntervalRef.current = setInterval(async () => {
      const response = await dispatch(
        fetchTransactionStatus({ transactionId, transactionCode }),
      ).unwrap();
      if (response.status === TransactionStatus.PARTIALLY_PAID) {
        setMarkedPartiallyPaid(true);
      }
    }, 5000);
    return () => {
      if (partialPaymentIntervalRef.current) {
        clearInterval(partialPaymentIntervalRef.current);
        partialPaymentIntervalRef.current = null;
      }
    };
  }, [
    dispatch,
    transactionId,
    invoicePaymentPlanUrl,
    minimumDepositPercentage,
  ]);

  useEffect(() => {
    if (partialPaymentIntervalRef.current && !showPartialPaymentIndicator) {
      clearInterval(partialPaymentIntervalRef.current);
      partialPaymentIntervalRef.current = null;
    }
  }, [showPartialPaymentIndicator]);

  useEffect(() => {
    if (invoicePaymentPlanUrl) {
      window.open(invoicePaymentPlanUrl, "_blank");
    }
  }, [invoicePaymentPlanUrl]);

  useEffect(() => {
    if (!markedPaid || !redirectPath) return;

    setTimeout(() => {
      history.replace(redirectPath);
    }, 3000);
  }, [markedPaid]);

  useEffect(() => {
    if (!markedPartiallyPaid || !redirectPath) return;

    history.replace(redirectPath);
  }, [markedPartiallyPaid]);

  const updateTransactionPaymentToStripe = async () => {
    // On a paypal payment error, stripe payment error or cancelling partial payment,
    // change the payment type back to stripe. This will create a stripe payment intent in the backend,
    // which will allow all of the accepted payment options to be re-displayed.
    await dispatch(
      updateTransactionPayment({
        transaction_id: transaction.id,
        financial_method: FinancialMethod.STRIPE,
      }),
    );
  };

  const message =
    "You can access the payment plan checkout page below. Once you've paid \
your deposit we'll redirect you to your pending project";

  const partialPaymentIndicator = (
    <PaymentPlanInProgressContainer>
      <p className={"mb-3 b1-semi-bold"}>{message}</p>
      <p
        style={{
          textAlign: "center",
        }}
        className={"mb-3 b1"}
      >
        waiting for initial deposit
      </p>
      <DefaultSoundWaveLoader />
      <Button
        fullWidth={true}
        href={invoicePaymentPlanUrl || undefined}
        variant={ButtonVariant.OUTLINED}
        disabled={false}
      >
        View Payment Plan Checkout Page
      </Button>
      <Button fullWidth={true} onClick={handleCancelPaymentPlan}>
        Cancel Payment Plan
      </Button>
    </PaymentPlanInProgressContainer>
  );

  const renderDirectMessageButton = (customButtonStyle?: CSSProperties) => {
    if (
      !transaction.created_by_user ||
      transaction.created_by_user.id === user?.id ||
      transaction.transaction_status !== TransactionStatus.PENDING
    ) {
      return null;
    }
    return (
      <ChatButton
        buttonText={`Message ${getDisplayableNameForUser(transaction.created_by_user)}`}
        customButtonStyle={customButtonStyle}
        onButtonClick={() => {
          // We can come back to this later on for more detailed things we want to log
          emitAnalyticsTrackingEvent(
            TRACKING_EVENTS_NAME.USER_OPEN_DIRECT_MESSAGE,
            {
              otherUserIds: [transaction.created_by_user!.id],
              path: SCREENS.TRANSACTION_OVERVIEW,
            },
            user?.id,
          );
        }}
        prefix={CHANNEL_ID_PREFIX.DIRECT_MESSAGE}
        relatedId={transaction.created_by_user.id}
      />
    );
  };

  const paymentForm = (
    <div>
      <Elements
        stripe={stripePromise}
        options={{
          mode: "payment",
          currency: "usd",
          amount,
          appearance: {
            theme: darkMode ? "night" : "stripe",
          },
        }}
      >
        {isAuthenticated &&
          hasTransactionPaymentID &&
          showStripePaymentForm &&
          !hasIncompleteContactInfo && (
            <>
              <PayPalButton
                transaction={transaction}
                totalPrice={amountInDollars}
                onPaymentSuccess={() => {
                  void handleSubmittingPayment(true);
                }}
                handleErr={updateTransactionPaymentToStripe}
                isDisabled={submittingPayment || isCheckoutButtonDisabled}
                showPaypalButton={true}
              />
              <div className="my-2" />
            </>
          )}
        {isAuthenticated &&
          !hasIncompleteContactInfo &&
          showStripePaymentForm && (
            <StripePaymentForm
              artistName={artistName}
              projectTitle={projectTitle}
              scheduledProjectIdForHandoff={scheduledProjectIdForHandoff}
              ref={stripePaymentFormRef}
              transaction={transaction}
              checkPhoneNumber={false}
            />
          )}
        {submittingPayment &&
          transaction.financial_method == FinancialMethod.PAYPAL && (
            <SoundWaveLoader width={100} height={100} />
          )}
        <UnauthenticatedModal
          showModal={showUnauthenticatedModal}
          closeModal={() => {
            closeUnauthenticatedModal();
          }}
          message={"You need to be logged in to book your project."}
        />
        {showMissingContactInfoModal && (
          <ContactInfoModal
            disableDismiss={false}
            overwriteShowModal={true}
            onClose={closeMissingContactInfoModal}
            customHeading={
              emailVerified
                ? MISSING_PHONE_NUMBER_MESSAGE
                : MISSING_EMAIL_VERIFICATION_MESSAGE
            }
          />
        )}
        <PaymentGatewayModuleFooter
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <PaymentGatewayModuleFooterActionsRow>
            {secondaryButton}
            {!isMobile &&
              !showStripePaymentForm &&
              renderDirectMessageButton({ width: "100%" })}
            {(isMobile || showStripePaymentForm) &&
              showPartialPaymentOption && (
                <Button
                  fullWidth
                  onClick={
                    !isAuthenticated
                      ? openUnauthenticatedModal
                      : handleCreatePaymentPlan
                  }
                  variant={ButtonVariant.OUTLINED}
                  loading={paymentPlanLoading}
                  disabled={isCheckoutButtonDisabled}
                  disableText={disabledText}
                >
                  {partialPaymentButtonText}
                </Button>
              )}
            <Button
              disabled={isCheckoutButtonDisabled}
              disableText={disabledText}
              fullWidth
              loading={submittingPayment || creatingPaymentIntent}
              variant={ButtonVariant.PRIMARY}
              onClick={() => {
                void handleSubmittingPayment(false);
              }}
            >
              {`Checkout (${PennyDollarFormatter().format(amountInDollars)})`}
            </Button>
          </PaymentGatewayModuleFooterActionsRow>
        </PaymentGatewayModuleFooter>
      </Elements>
    </div>
  );

  const paymentGatewayHeader = (projectMarkedPaid: boolean) => {
    const headerText = hasStripeTransactionPaymentIntent
      ? "Payment Options"
      : "Checkout";
    return (
      <>
        <PaymentGatewayModuleH6 variant={TextStyleVariant.H6}>
          {headerText}
        </PaymentGatewayModuleH6>
        {!hasStripeTransactionPaymentIntent && (
          <PaymentGatewayModuleP>
            Click below to view payment options
          </PaymentGatewayModuleP>
        )}
        {showMissingSessionDetail && !(markedPaid || markedPartiallyPaid) && (
          <Text
            bold
            color={TextColor.ERROR}
            style={{ textAlign: "right", width: "100%" }}
          >
            {emptySessionBookingText[missingSessionDetail]}
          </Text>
        )}
        {hasStripeTransactionPaymentIntent && (
          <>
            <PaymentGatewayModuleRefundPolicy>
              {"By booking, you are agreeing to the "}
              <a
                style={{
                  color: isDarkMode ? "white" : "black",
                  textDecoration: "underline",
                }}
                href={getDocUrl("EngineEarsRefundPolicy.pdf")}
                target="_blank"
                rel="noopener noreferrer"
              >
                Refund Policy
              </a>
              .
            </PaymentGatewayModuleRefundPolicy>
            <PaymentGatewayModuleP>
              {projectMarkedPaid
                ? "Payment has been processed successfully"
                : "Please choose a payment method below"}
            </PaymentGatewayModuleP>
          </>
        )}
      </>
    );
  };

  const paymentGateway = (projectMarkedPaid: boolean) => (
    <PaymentGatewayModuleContainer className="gateway">
      {paymentGatewayHeader(projectMarkedPaid)}
      {projectMarkedPaid
        ? successView
        : !showPartialPaymentIndicator
          ? paymentForm
          : partialPaymentIndicator}
    </PaymentGatewayModuleContainer>
  );

  const mobileDrawer = useBottomTabBarOverlayView(
    isMobile,
    <>
      <PaymentGatewayModuleDrawer $navHeight={navHeight}>
        <Drawer
          className="drawer"
          isVisible={showPurchaseOrderMobileMenu}
          onClose={() => {
            if (submittingPayment) return;
            setShowPurchaseOrderMobileMenu(false);
          }}
        >
          <PurchaseOrderCheckoutForm
            artistName={artistName}
            isMobile={true}
            markedPaid={markedPaid}
            projectTitle={projectTitle}
            setMarkedPaid={setMarkedPaid}
            transaction={transaction}
            disableBooking={disableBookingForRecordingSessions}
          >
            {successView}
          </PurchaseOrderCheckoutForm>
        </Drawer>
      </PaymentGatewayModuleDrawer>
      <PaymentGatewayModuleDrawer $navHeight={navHeight}>
        <Drawer
          className="drawer"
          isVisible={showStripeMobileMenu}
          onClose={() => {
            if (submittingPayment) return;
            setShowStripeMobileMenu(false);
          }}
        >
          {paymentGatewayHeader(markedPaid)}
          {markedPaid ? successView : paymentForm}
        </Drawer>
      </PaymentGatewayModuleDrawer>
    </>,
    NO_PADDING_TAB_OVERLAY_CLASS,
    BOTTOM_TAB_BAR_FOCUSED_OVERLAY_ID,
  );

  const mobileFooter = (
    <>
      <PaymentGatewayModuleFooter className="footer-actions">
        {showMissingSessionDetail && (
          <Text
            bold
            color={TextColor.ERROR}
            style={{ textAlign: "center", width: "100%" }}
          >
            {emptySessionBookingText[missingSessionDetail]}
          </Text>
        )}
        {!isAandR && renderDirectMessageButton({ width: "100%" })}
        <PaymentGatewayModuleFooterActionsRow>
          {!showPartialPaymentOption && secondaryButton}
          {showStripeMobileMenu && showPartialPaymentOption && (
            <Button
              disabled={isCheckoutButtonDisabled}
              disableText={disabledText}
              fullWidth={true}
              onClick={
                !isAuthenticated
                  ? openUnauthenticatedModal
                  : handleCreatePaymentPlan
              }
              variant={ButtonVariant.OUTLINED}
              loading={paymentPlanLoading}
            >
              {partialPaymentButtonText}
            </Button>
          )}
          <Button
            disabled={isCheckoutButtonDisabled}
            disableText={disabledText}
            fullWidth={true}
            loading={submittingPayment || creatingPaymentIntent}
            variant={isAandR ? ButtonVariant.OUTLINED : ButtonVariant.PRIMARY}
            onClick={async () => {
              if (!isAuthenticated) {
                openUnauthenticatedModal();
                return;
              }
              await generateStripePaymentIntent();
              setShowStripeMobileMenu(true);
              if (!showPartialPaymentOption) {
                setShowStripePaymentForm(true);
              }
            }}
          >
            {isAandR
              ? "Pay with Card"
              : `Checkout (${PennyDollarFormatter().format(amountInDollars)})`}
          </Button>
        </PaymentGatewayModuleFooterActionsRow>
        {isAandR && (
          <Button
            fullWidth={true}
            disabled={isCheckoutButtonDisabled}
            disableText={disabledText}
            loading={submittingPayment || creatingPaymentIntent}
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              setShowPurchaseOrderMobileMenu(true);
            }}
          >
            Start Project
          </Button>
        )}
      </PaymentGatewayModuleFooter>
      {mobileDrawer}
      <UnauthenticatedModal
        showModal={showUnauthenticatedModal}
        closeModal={() => {
          closeUnauthenticatedModal();
        }}
        message={"You need to be logged in to book your project."}
      />
    </>
  );

  const portal = useBottomTabBarOverlayView(
    isMobile,
    !showPartialPaymentIndicator ? mobileFooter : partialPaymentIndicator,
    DEFAULT_TAB_OVERLAY_CLASS,
    BOTTOM_TAB_BAR_OVERLAY_ID,
  );

  if (amountInDollars <= 0) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        {portal}
        {markedPaid && successView}
      </>
    );
  }

  if (
    (status === TransactionStatus.PAID ||
      status === TransactionStatus.PARTIALLY_PAID) &&
    !markedPaid
  ) {
    return (
      <Text>Payment has already been submitted for this transaction.</Text>
    );
  }

  // only show A&R checkout form if the transaction has no recording sessions
  if (isAandR && !transaction.recording_sessions?.length) {
    return (
      <PurchaseOrderCheckoutForm
        artistName={artistName}
        isMobile={isMobile}
        markedPaid={markedPaid}
        projectTitle={projectTitle}
        setMarkedPaid={setMarkedPaid}
        transaction={transaction}
        disableBooking={disableBookingForRecordingSessions}
      >
        {markedPaid ? successView : paymentGateway(markedPaid)}
      </PurchaseOrderCheckoutForm>
    );
  }

  return paymentGateway(markedPaid);
};
