import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { getOptedInUnverifiedEngineers } from "../../../store/actions/homepage";
import { setUpAndComingEngFilter } from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import User from "../../../store/models/user";
import {
  getProfileScreenRoute,
  getSearchScreenWithPromocodeAttached,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { getUserProfileCard } from "../ProfileCard/ProfileCard";
import { cachedEngineersAtom } from "./atoms";
import {
  StyledEngineerPromoListButton,
  StyledEngineerPromoListCardContainer,
  StyledEngineerPromoListContainer,
  StyledEngineerPromoListFooter,
  StyledEngineerPromoListSubtitle,
} from "./EngineerPromoListModal.styles";

export const UNVERIFIED_ENG_PROMO_CODE = "NEWEE50";
const MOBILE_LENGTH = 2;
export interface EngineerPromoListModalProps {
  showEngineerPromoListModal: boolean;
  showOldEngineerPromoListModal?: boolean;
  openEngineerPromoListModal: () => void;
  closeEngineerPromoListModal: () => void;
}

export const EngineerPromoListModal = ({
  showEngineerPromoListModal,
  showOldEngineerPromoListModal = false,
  openEngineerPromoListModal,
  closeEngineerPromoListModal,
}: EngineerPromoListModalProps) => {
  const [engineerUsers, setEngineerUsers] = useState<User[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  const [cachedEngineers, setCachedEngineers] = useAtom(cachedEngineersAtom);

  const { isMobile } = useMediaQueryBreakpoint();

  useEffect(() => {
    if (showOldEngineerPromoListModal) {
      setEngineerUsers(cachedEngineers);
      return;
    }
    if (engineerUsers != null) {
      return;
    }

    setIsLoading(true);
    void dispatch(getOptedInUnverifiedEngineers())
      .unwrap()
      .then((result) => {
        if (isMobile) {
          result = result.slice(0, MOBILE_LENGTH);
        }
        setEngineerUsers(result);
      })
      .catch(() => {
        setEngineerUsers([]);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, engineerUsers, isMobile, showOldEngineerPromoListModal]);

  if (engineerUsers && engineerUsers.length === 0) {
    closeEngineerPromoListModal();
    return null;
  }

  const handleProfileCardClick = (prevEngineers?: User[]) => {
    if (prevEngineers) {
      setCachedEngineers(prevEngineers);
    }
    closeEngineerPromoListModal();
  };

  const handleClose = () => {
    setCachedEngineers(null);
    closeEngineerPromoListModal();
  };

  const handleSeeMore = () => {
    setCachedEngineers(null);
    emitAnalyticsTrackingEvent(
      "clicked_see_opted_in_unverified_engineers",
      {},
      loggedInUser?.id,
    );
    dispatch(setUpAndComingEngFilter(true));
    history.push(
      getSearchScreenWithPromocodeAttached(UNVERIFIED_ENG_PROMO_CODE),
    );
  };

  const showModal = showOldEngineerPromoListModal || showEngineerPromoListModal;

  return (
    <BaseModal
      header="Welcome to EngineEars"
      open={showModal}
      setOpen={showModal ? handleClose : openEngineerPromoListModal}
      centerModalHeader
      style={{
        maxWidth: "100%",
        width: "100%",
        overflowX: "auto",
      }}
    >
      <StyledEngineerPromoListContainer>
        <StyledEngineerPromoListSubtitle>
          Get 50% off your first booking with any of these engineers!
        </StyledEngineerPromoListSubtitle>
        <StyledEngineerPromoListCardContainer>
          {isLoading ? (
            <SoundWaveLoader width={100} height={100} />
          ) : (
            engineerUsers?.map((engineerUser, key) => {
              const path = getProfileScreenRoute(engineerUser.username).concat(
                "?promocode=",
                UNVERIFIED_ENG_PROMO_CODE,
              );
              return getUserProfileCard(engineerUser, key, path, () =>
                handleProfileCardClick(engineerUsers),
              );
            })
          )}
        </StyledEngineerPromoListCardContainer>
        <StyledEngineerPromoListFooter>
          <StyledEngineerPromoListButton onClick={handleSeeMore}>
            See More
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ marginLeft: "8px" }}
            />
          </StyledEngineerPromoListButton>
        </StyledEngineerPromoListFooter>
      </StyledEngineerPromoListContainer>
    </BaseModal>
  );
};
