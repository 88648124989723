import { useQuery } from "@tanstack/react-query";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { RecordingSession } from "../../../store/models/recordingSession";
import { ScheduledProject } from "../../../store/models/scheduledproject";
import queryChannelForProjectOrSession from "./queryChannelForProjectOrSession";
interface Params {
  client?: StreamChat<DefaultGenerics>;
  project_id: string;
  recordingSession?: Pick<
    RecordingSession,
    "recording_session_request_id" | "users"
  >;
  scheduledProject?: ScheduledProject;
}
const useChannelForProjectOrSession = ({
  client,
  project_id,
  recordingSession,
  scheduledProject,
}: Params) => {
  return useQuery({
    queryKey: ["channelForProject", project_id],
    queryFn: async () => {
      return queryChannelForProjectOrSession(
        client!,
        recordingSession,
        scheduledProject,
      );
    },
    enabled: Boolean(client),
  });
};

export default useChannelForProjectOrSession;
