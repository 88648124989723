import { faEdit, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isSameDay from "date-fns/isSameDay";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useIsAffiliatedEngineerWithStudio } from "../../../hooks/studioHooks";
import { useAvailabilityForMostAvailableDay } from "../../../hooks/useGeneralWorkingHoursStringForDay";
import { useIsAandR } from "../../../hooks/useIsAandR";
import { useStudioRoomReviewStats } from "../../../hooks/useUserReviews";
import { fetchAvailableRecordingEngineers } from "../../../store/actions/engineerRecommendation";
import { getRecordingServiceAvailability } from "../../../store/actions/recording";
import { SetUpCartPayload } from "../../../store/actions/shoppingCart";
import { createTransaction } from "../../../store/actions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { PromoCode } from "../../../store/models/promoCode";
import { DiscountRate } from "../../../store/models/recording";
import { Studio, StudioRoom } from "../../../store/models/studio";
import { useManagerCanEditStudio } from "../../../store/selectors/teamSelectors";
import { selectUserCanEnableStudioServices } from "../../../store/selectors/userInfoSelectors";
import { getCurrentDate } from "../../../store/utils/dateTimeUtils";
import {
  DollarFormatter,
  PennyDollarFormatter,
} from "../../../store/utils/formatUtils";
import { getValidDiscountRate } from "../../../store/utils/recordingUtils";
import { getTransactionBookingScreenRoute } from "../../../store/utils/routeGetters";
import {
  convertIndexFromHourOptionsToMinutes,
  generatePreferredHoursOptions,
  getNumberedOptionsWithLabel,
} from "../../../store/utils/serviceUtils";
import { generateSessionList } from "../../../store/utils/shoppingCartUtils";
import { BookingParameters } from "../../../store/utils/transactions";
import { convertAvailabilityForDateToMap } from "../../../store/utils/utils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { BookingCalendar } from "../BookingCalendar/BookingCalendar";
import "./StudioCalendarWidget.css";

export interface StudioCalendarWidgetProps {
  studioRoom: StudioRoom;
  studio: Studio;
  canManageStudio: boolean;
  showRecordingServiceModal: () => void;
  openBookingFlow?: boolean;
  promoCode?: PromoCode;
}

const DISCOUNT_RATE_LIMIT = 4;

export const StudioCalendarWidget = ({
  studioRoom,
  studio,
  canManageStudio,
  showRecordingServiceModal,
  openBookingFlow = false,
  promoCode,
}: StudioCalendarWidgetProps) => {
  const stats = useStudioRoomReviewStats(studioRoom.id);
  const { average_rating, ratings_count } = useMemo(() => {
    return stats ?? { average_rating: 0, ratings_count: 0 };
  }, [stats]);
  const studioRoomRecordingAvailabilities = useAppSelector(
    (state) => state.availability.studio_room[studioRoom?.id || -1],
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const isAnRAccount = useIsAandR(user);
  const availabilities = convertAvailabilityForDateToMap(
    studioRoomRecordingAvailabilities,
  );
  const affiliatedEngineerBookingLinksEnabled = Boolean(
    studio?.affiliated_engineer_booking_links_enabled,
  );

  const isAffiliatedEngineer = useIsAffiliatedEngineerWithStudio(studio);

  const userStudioServicesEnabled = useAppSelector(
    selectUserCanEnableStudioServices,
  );
  const userIsMe = useManagerCanEditStudio(studio?.id);
  const canEditStudioService = userStudioServicesEnabled && userIsMe;
  const history = useHistory();
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [discountSelected, setDiscountSelected] = useState<
    DiscountRate | undefined
  >(undefined);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [lockSelectedDays, setLockSelectedDays] = useState<boolean>(false);
  const [discountRates, setDiscountRates] = useState<
    DiscountRate[] | undefined
  >(undefined);
  const { recording_service } = studioRoom;
  const { minimum_session_time_minutes, maximum_session_time_minutes } =
    useMemo(() => {
      return recording_service
        ? recording_service
        : {
            minimum_session_time_minutes: 30,
            maximum_session_time_minutes: 120,
          };
    }, [recording_service]);
  const daysOptions = getNumberedOptionsWithLabel(1, 15, "Days");
  const [daysSelected, setDaysSelected] = useState<OptionType>(daysOptions[0]);
  const preferredHourOptions = useMemo(() => {
    if (!recording_service) return [];
    return generatePreferredHoursOptions(
      minimum_session_time_minutes,
      maximum_session_time_minutes,
      isAnRAccount && !userIsMe,
      recording_service.service_rate,
      recording_service.recording_service_discount_rate,
    );
  }, [
    recording_service,
    isAnRAccount,
    userIsMe,
    minimum_session_time_minutes,
    maximum_session_time_minutes,
  ]);

  useEffect(() => {
    if (!recording_service) {
      setDiscountRates(undefined);
    }
    if (
      !recording_service?.recording_service_discount_rate ||
      recording_service?.recording_service_discount_rate?.length === 0
    ) {
      setDiscountRates(undefined);
    }
    const activeDiscountRates =
      recording_service?.recording_service_discount_rate?.filter(
        (discountRate) => discountRate.deleted === null,
      );
    const subSetActiveDiscount = activeDiscountRates?.slice(
      0,
      DISCOUNT_RATE_LIMIT,
    );
    setDiscountRates(subSetActiveDiscount);
  }, [recording_service?.recording_service_discount_rate]);
  const [selectedHour, setSelectedHour] = useState<OptionType | null>(
    preferredHourOptions.find(
      (option) => option.value === minimum_session_time_minutes / 60,
    ) ?? null,
  );

  const defaultRate = useMemo(() => {
    if (!recording_service) return 0;
    return isAnRAccount && !userIsMe
      ? recording_service.service_rate.label_price ?? 0
      : recording_service.service_rate.price ?? 0;
  }, [recording_service, isAnRAccount, userIsMe, selectedHour]);

  const appliedDiscountRate = useMemo(() => {
    if (!recording_service) {
      return undefined;
    }
    const durationMinutes = selectedHour ? selectedHour.value * 60 : undefined;
    return getValidDiscountRate(
      recording_service.recording_service_discount_rate,
      durationMinutes,
    );
  }, [recording_service, selectedHour]);

  const hourRate = useMemo(() => {
    if (!recording_service) return 0;
    return isAnRAccount && !userIsMe
      ? recording_service.service_rate.label_price ??
          recording_service.service_rate.price
      : appliedDiscountRate?.service_rate.price ??
          recording_service.service_rate.price;
  }, [
    recording_service,
    isAnRAccount,
    userIsMe,
    selectedHour,
    appliedDiscountRate,
  ]);

  useEffect(() => {
    if (studioRoom?.id) {
      return;
    }
    fetchAvailableRecordingEngineers({
      studio_room_id: studioRoom.id,
    });
  }, [studioRoom.id]);

  useAvailabilityForMostAvailableDay(
    selectedHour ? selectedHour.value * 60 : minimum_session_time_minutes,
    studioRoom.id,
    undefined,
  );

  const dispatch = useAppDispatch();

  const minimumSessionHours = useMemo(() => {
    return minimum_session_time_minutes / 60;
  }, [minimum_session_time_minutes]);

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const [duration, setDuration] = useState<number>(
    minimum_session_time_minutes,
  );

  const workingHoursState = useAppSelector((state) => state.workingHours);
  const studioRoomWorkingHours =
    workingHoursState.studioRoomWorkingHours[studioRoom?.id || -1];

  const [availabilitiesLoading, setAvailabilitiesLoading] =
    useState<boolean>(false);
  const [booking, setBooking] = useState<boolean>(true);

  const onClickDate = (date: Date) => {
    const isDateSelected = selectedDates.some(
      (d) => d.getTime() === date.getTime(),
    );
    if (isDateSelected) {
      const datesWithoutSelected = selectedDates.filter(
        (d) => d.getTime() !== date.getTime(),
      );
      setSelectedDates(datesWithoutSelected);
      if (
        (selectedDay && isSameDay(selectedDay, date)) ||
        datesWithoutSelected.length === 0
      ) {
        setSelectedDay(null);
      }
    } else if (
      selectedDates.length + 1 > daysSelected.value &&
      lockSelectedDays
    ) {
      setSelectedDay(date);
      setSelectedDates((prev) => [...prev.slice(1), date]);
    } else {
      const dayOption = daysOptions.find(
        (option) => option.value === selectedDates.length + 1,
      );
      if (!dayOption) {
        return toast.error("You can't select more days than the maximum");
      }
      if (dayOption.value > daysSelected.value) {
        setDaysSelected(dayOption);
      }
      setSelectedDay(date);

      setSelectedDates((prev) => [...prev, date]);
    }
  };

  useEffect(() => {
    if (!discountSelected) return;
    const selectedDiscountMinTimeHours =
      discountSelected?.minimum_time_to_enable_rate / 60;
    setSelectedHour(
      preferredHourOptions.find(
        (option) => option.value === selectedDiscountMinTimeHours,
      ) ?? null,
    );
  }, [discountSelected, preferredHourOptions]);

  const handleSetUpPredefinedSession = useCallback(async () => {
    if (!recording_service) return;
    if (!studioRoom) return;
    if (!selectedHour) return;
    const sortedSelectedDates = selectedDates.sort((a, b) =>
      a.getTime() > b.getTime() ? 1 : -1,
    );
    let pendingSessions = generateSessionList(
      daysSelected.value,
      sortedSelectedDates,
      convertIndexFromHourOptionsToMinutes(selectedHour.value),
    );
    if (
      isAffiliatedEngineer &&
      affiliatedEngineerBookingLinksEnabled &&
      user?.engineer
    ) {
      pendingSessions = pendingSessions.map((session) => ({
        ...session,
        trackingEngineer: user.engineer?.has_active_recording_service
          ? user.engineer
          : undefined,
      }));
    }
    const shoppingCart: SetUpCartPayload = {
      pendingSessionData: pendingSessions,
    };

    try {
      setIsBookingLoading(true);
      const transaction = await dispatch(createTransaction()).unwrap();
      const bookingParameters: BookingParameters = {
        transactionId: +transaction.id,
        activeStudioId: studio.id,
        activeStudioRoomId: studioRoom.id,
        activeServiceType: ProjectType.RECORDING,
        activeServiceTypeProjectIds: [],
      };

      emitAnalyticsTrackingEvent(
        "clicked_book_studio_calendar_widget",
        {
          transaction_id: bookingParameters.transactionId,
          active_studio_id: bookingParameters.activeStudioId,
          active_studio_room_id: bookingParameters.activeStudioRoomId,
          service_type: bookingParameters.activeServiceType,
        },
        user?.id,
      );

      history.push(
        getTransactionBookingScreenRoute(
          transaction.code,
          dispatch,
          bookingParameters,
          shoppingCart,
        ),
      );
    } catch {
      setIsBookingLoading(false);
    }
  }, [
    user,
    affiliatedEngineerBookingLinksEnabled,
    isAffiliatedEngineer,
    recording_service,
    studioRoom,
    studio,
    history,
    selectedHour,
    daysSelected,
    selectedDates,
    dispatch,
  ]);

  useEffect(() => {
    if (!recording_service) return;
    if (
      studioRoomWorkingHours === undefined ||
      studioRoomWorkingHours.length === 0
    )
      return;
    setAvailabilitiesLoading(true);
    void dispatch(
      getRecordingServiceAvailability({
        timezoneShiftMinutes: new Date().getTimezoneOffset(),
        studioRoomId: studioRoom.id,
        startDate: getCurrentDate(),
      }),
    )
      .unwrap()
      .finally(() => {
        setAvailabilitiesLoading(false);
      });
  }, [dispatch, recording_service, studioRoom.id, studioRoomWorkingHours]);

  useEffect(() => {
    if (openBookingFlow) {
      void handleSetUpPredefinedSession();
    }
  }, [openBookingFlow, handleSetUpPredefinedSession]);

  useEffect(() => {
    if (!recording_service) {
      return;
    }
    const acceptingBookings = Boolean(
      recording_service.not_accepting_bookings === null,
    );
    setBooking(acceptingBookings);
  }, [recording_service]);

  const currentTotal = useMemo(() => {
    const totalHours = daysSelected.value * (selectedHour?.value ?? 1);
    return totalHours * hourRate;
  }, [daysSelected.value, selectedHour?.value, hourRate]);

  return (
    <div className="studio-calendar-widget-container">
      <div className="studio-calendar-header-container">
        <div className="studio-calendar-header-container-row">
          {userIsMe && !canEditStudioService && (
            <p className="p b1-semi-bold mb-3">
              Bookings for this page will be unlocked once you&apos;ve been
              officially onboarded by the EngineEars team.
              <br />
              <br />
              We&apos;ll be in touch with an onboarding invitation soon!
            </p>
          )}
          <div className="price-container">
            <p className="h6">
              {(!userIsMe || canEditStudioService) && (
                <span className="h6-semi-bold">
                  {DollarFormatter().format(hourRate)} per hour
                </span>
              )}{" "}
            </p>
            {canEditStudioService && (
              <FontAwesomeIcon
                onClick={() => {
                  showRecordingServiceModal();
                }}
                color="var(--black)"
                icon={faEdit}
                className={"edit-service-button"}
                size={"sm"}
              />
            )}
          </div>

          {(!userIsMe || canEditStudioService) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "baseline",
                flexDirection: "row",
              }}
            >
              <FontAwesomeIcon
                icon={faStar}
                color="var(--black)"
                className="mx-2"
              />{" "}
              <p className="b1-semi-bold">{average_rating}</p>
            </div>
          )}
        </div>
        <div className="studio-calendar-header-container-row">
          <p className="b1">
            {`${
              appliedDiscountRate
                ? appliedDiscountRate.minimum_time_to_enable_rate / 60
                : minimumSessionHours
            } hour minimum `}{" "}
            {!studioRoom.studio?.tracking_engineer_price_included && (
              <>
                <span>&#x2022;</span>
                {` ${DollarFormatter().format(
                  Number(hourRate) + Number(studioRoom.low_eng_rate) ?? 0,
                )} 
            ${
              studioRoom.low_eng_rate === studioRoom.high_eng_rate
                ? ""
                : " - " +
                  DollarFormatter().format(
                    Number(hourRate) + Number(studioRoom.high_eng_rate) ?? 0,
                  )
            } with engineer`}
              </>
            )}
          </p>
          <p className="b1">{`${ratings_count} Reviews`}</p>
        </div>
      </div>
      {discountRates && discountRates.length > 0 && (
        <div className="studio-calendar-discount-rates-container">
          {discountRates.map((discountRate, index) => {
            const hours = discountRate.minimum_time_to_enable_rate / 60;
            const price = discountRate.service_rate.price * hours;

            const onClick = (discountRate: DiscountRate) => {
              if (discountSelected === discountRate) {
                setDiscountSelected(undefined);
                return;
              }
              setDiscountSelected(discountRate);
            };
            return (
              <div
                onClick={() => onClick(discountRate)}
                key={index}
                className={"studio-calendar-discount-rate-container ".concat(
                  discountSelected === discountRate
                    ? "studio-calendar-discount-rate-selected "
                    : "",
                )}
              >
                <p
                  style={{ textAlign: "center", whiteSpace: "pre" }}
                  className="b2-semi-bold"
                >{`${PennyDollarFormatter().format(
                  price,
                )} for ${hours} hours`}</p>
              </div>
            );
          })}
        </div>
      )}
      <div className="recording-calendar-room-options-container">
        <div className="recording-calendar-room-option-container">
          <p className="b3-semi-bold">I&apos;m looking to book</p>
          <DropdownSelector
            value={daysSelected}
            onChange={(option) => {
              if (!lockSelectedDays) {
                setLockSelectedDays(true);
              }
              if (option?.value < selectedDates.length) {
                setSelectedDay(null);
                setSelectedDates((prev) => prev.slice(0, option.value));
              }
              setDaysSelected(option);
            }}
            options={daysOptions}
          />
        </div>
        <div className="recording-calendar-room-option-container">
          <p className="b3-semi-bold">for</p>
          <DropdownSelector
            placeholder="Select hours per day"
            value={selectedHour}
            onChange={(option) => {
              setDiscountSelected(undefined);
              setSelectedHour(option);
              const hours = option?.value ?? 0;
              const minutes = hours * 60;
              setDuration(minutes ?? 0);
            }}
            options={preferredHourOptions}
          />
        </div>
      </div>
      <div className="studio-calendar-container">
        <BookingCalendar
          studioId={studio.id}
          clickedDay={selectedDay}
          selectedDays={selectedDates}
          availabilities={availabilities}
          onClickDate={onClickDate}
          durationMinutes={duration}
        />
      </div>
      <Button
        className="studio-calendar-widget-button"
        fullWidth
        variant={ButtonVariant.GRADIENT}
        onClick={() => {
          if (!recording_service && canManageStudio) {
            showRecordingServiceModal();
          }
          if (!booking && canManageStudio) {
            return showRecordingServiceModal();
          }
          if (!booking && (!recording_service || !canManageStudio)) {
            toast.info(
              "This studio is not accepting bookings at the moment. Please try again later.",
            );
            return;
          }
          void handleSetUpPredefinedSession();
        }}
        disabled={
          ((availabilitiesLoading || !availabilities.size) &&
            !canManageStudio &&
            !recording_service) ||
          (userIsMe && !canEditStudioService)
        }
        loading={availabilitiesLoading || isBookingLoading}
      >
        {!recording_service
          ? canManageStudio
            ? "Set Up Recording Service"
            : "Not booking"
          : booking
            ? canManageStudio ||
              (isAffiliatedEngineer && affiliatedEngineerBookingLinksEnabled)
              ? "Generate Booking"
              : "Complete Your Reservation"
            : "Not Booking"}
      </Button>
      <div className="calendar-text-view">
        <p className="b1">You won&apos;t be charged yet</p>
      </div>
      <div className="studio-rooom-calendar-breakdown-view">
        <p className="b1">{`Estimate: ${daysSelected.value} day${
          daysSelected.value > 1 ? "s" : ""
        } x ${PennyDollarFormatter().format(defaultRate)} * ${
          selectedHour?.value ?? "hours"
        }`}</p>
        <p className="b1">{`${PennyDollarFormatter().format(
          defaultRate * (daysSelected.value * (selectedHour?.value ?? 1)),
        )}`}</p>
      </div>
      {appliedDiscountRate && (
        <div className="studio-rooom-calendar-breakdown-view">
          <p className="b1">{`Discounted Rate: ${daysSelected.value} day${
            daysSelected.value > 1 ? "s" : ""
          } x ${PennyDollarFormatter().format(hourRate)} * ${
            selectedHour?.value ?? "hours"
          }`}</p>
          <p className="b1">{`${PennyDollarFormatter().format(
            hourRate * (daysSelected.value * (selectedHour?.value ?? 1)),
          )}`}</p>
        </div>
      )}
      {promoCode && (
        <div className="studio-rooom-calendar-breakdown-view">
          <p className="b1">Promocode Discount *(applied at checkout)</p>
          <p className="b1">{`${Math.round(
            promoCode.discount_percentage * 100,
          )}%`}</p>
        </div>
      )}
      {(promoCode || appliedDiscountRate) && (
        <div className="studio-rooom-calendar-breakdown-view">
          <p className="b1">Discount:</p>
          <p className="b1">{`-${PennyDollarFormatter().format(
            currentTotal * (promoCode ? promoCode.discount_percentage : 0) +
              (defaultRate - hourRate) *
                daysSelected.value *
                (selectedHour?.value ?? 1),
          )}`}</p>
        </div>
      )}
      <div className="studio-rooom-calendar-breakdown-view">
        <p className="b1">Total</p>
        <p className="b1">{`${PennyDollarFormatter().format(
          currentTotal -
            currentTotal * (promoCode ? promoCode.discount_percentage : 0),
        )}`}</p>
      </div>
    </div>
  );
};
