import { Box, styled } from "@mui/material";

export const ArtistReleaseTableWrapper = styled("table")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const ArtistReleaseTableHeader = styled("th")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const ReleaseTableHeaderView = styled(Box)(() => ({
  minWidth: "16px",
  width: "fit-content",
}));

export const ArtistReleaseTableRow = styled("tr")(({ theme }) => ({
  minWidth: "16px",
  height: "48px",
  padding: "16px",
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
