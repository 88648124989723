import React, { useState } from "react";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { getSubscriptionCardProps } from "../../../store/utils/subscriptionUtils";
import { PaymentPlanCheckbox } from "../../components/PaymentPlanCheckbox/PaymentPlanCheckbox";
import { SubscriptionCard } from "../../components/SubscriptionCard/SubscriptionCard";
import { Button } from "../../elements/Button/button";
import "./SelectSubPaymentPlanScreen.css";

export interface SelectSubPaymentPlanScreenProps {
  selectedSubscription: SUBSCRIPTION_PLAN;
  setSelectedSubscription: (plan: SUBSCRIPTION_PLAN) => void;
  onClickPreviousStep: () => void;
  onGoToCheckout: () => void;
  useStudioBenefits?: boolean;
}

export function getAnnualVersionOfSubscription(plan: SUBSCRIPTION_PLAN) {
  switch (plan) {
    case SUBSCRIPTION_PLAN.GOLD_PLAN:
    case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
    case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      return SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN;
    case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
    case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
      return SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN;
    case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
      return SUBSCRIPTION_PLAN.DIAMOND_PLAN;
  }
  return SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN;
}

export const SelectSubPaymentPlanScreen = ({
  selectedSubscription,
  setSelectedSubscription,
  onClickPreviousStep,
  onGoToCheckout,
  useStudioBenefits = false,
}: SelectSubPaymentPlanScreenProps) => {
  const [checkingOut, setCheckingOut] = useState(false);

  function getDefaultVersionOfSubscription(plan: SUBSCRIPTION_PLAN) {
    switch (plan) {
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
        return SUBSCRIPTION_PLAN.GOLD_PLAN;
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return SUBSCRIPTION_PLAN.PLATINUM_PLAN;
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return SUBSCRIPTION_PLAN.DIAMOND_PLAN;
    }
    return SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN;
  }

  return (
    <div className="container add-subscription-flow-container">
      <div className="row justify-content-md-center">
        <h3 className="subscription-step-header">
          Last step before you get to work
        </h3>
        {!useStudioBenefits && (
          <p className="my-4" style={{ textAlign: "center" }}>
            Choose your payment plan. Either way, get an{" "}
            <strong>unlimited free trial</strong> until you've completed your
            first project.
          </p>
        )}
        {useStudioBenefits && (
          <p className="my-4" style={{ textAlign: "center" }}>
            Choose your payment plan. Either way, get an{" "}
            <strong>unlimited free trial</strong> while we ensure your studio is
            completely set up.
          </p>
        )}
      </div>
      <div className="row justify-content-md-center">
        <div className="subscription-step-view">
          <div className="payment-plans-view">
            <SubscriptionCard
              {...getSubscriptionCardProps({
                plan: getDefaultVersionOfSubscription(selectedSubscription),
                showPrice: false,
              })}
              useStudioBenefits={useStudioBenefits}
            />
            <div className="payment-plans-container">
              {selectedSubscription !== SUBSCRIPTION_PLAN.DIAMOND_PLAN && (
                <>
                  <PaymentPlanCheckbox
                    subscriptionType={getAnnualVersionOfSubscription(
                      selectedSubscription,
                    )}
                    selected={
                      selectedSubscription ===
                      getAnnualVersionOfSubscription(selectedSubscription)
                    }
                    setSelected={setSelectedSubscription}
                    useStudioBenefits={useStudioBenefits}
                  />
                  <PaymentPlanCheckbox
                    subscriptionType={getDefaultVersionOfSubscription(
                      selectedSubscription,
                    )}
                    selected={
                      selectedSubscription ===
                      getDefaultVersionOfSubscription(selectedSubscription)
                    }
                    setSelected={setSelectedSubscription}
                    useStudioBenefits={useStudioBenefits}
                  />
                </>
              )}
              {selectedSubscription === SUBSCRIPTION_PLAN.DIAMOND_PLAN && (
                <PaymentPlanCheckbox
                  subscriptionType={getDefaultVersionOfSubscription(
                    selectedSubscription,
                  )}
                  selected={true}
                  useStudioBenefits={useStudioBenefits}
                />
              )}
              <p className="b2">
                Members get priority customer service support and early access
                to new features
              </p>
            </div>
          </div>
          <div className="payment-plans-footer mb-5">
            <Button
              customClassName="mt-3"
              label="PREVIOUS STEP"
              onClick={onClickPreviousStep}
            />
            <Button
              customClassName="mt-3"
              label="Go to Checkout"
              primary={true}
              loading={checkingOut}
              onClick={() => {
                setCheckingOut(true);
                onGoToCheckout();
              }}
              disabled={selectedSubscription === undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
