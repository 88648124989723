import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { fetchProfile } from "../../store/actions/users";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentUser } from "../../store/selectors/userInfoSelectors";

export const useGetUserProfile = (username?: string) => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  return useQuery({
    staleTime: user && user.username === username ? Infinity : undefined,
    queryKey: [QUERY_KEYS.GET_USER_PROFILE, username],
    queryFn: async () => {
      return dispatch(fetchProfile({ username })).unwrap();
    },
  });
};
