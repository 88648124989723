import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import {
  makeBackendGetCallWithJsonResponse,
  makeBackendPostCallWithJsonResponse,
  makeBackendPutCallWithJsonResponse,
} from "../store/utils/fetch";
import { Release, ReleaseTrack, ReleaseType } from "../store/models/release";
import {
  BASE_RELEASE_API,
  CREATE_ARTIST_RELEASE_FROM_SCHEDULED_PROJECT,
  GET_ARTIST_RELEASES,
  GET_SCHEDULED_PROJECT_RELEASE,
} from "../store/utils/routes";
import { useAppDispatch, useCollaborators } from "../store/hooks";
import { ErrorsAction, receiveErrors } from "../store/actions/errorStore";
import queryString from "query-string";
import { UserLite } from "../store/models/user";
import {
  PreviousPlaylistState,
  updatePlayListOrder,
} from "../store/actions/abPlayerStore";

export const useReleaseBulkCreate = (
  scheduledProjectId: number,
  releaseType: ReleaseType,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [
      QUERY_KEYS.CREATE_RELEASE_FROM_SCHEDULED_PROJECT,
      {
        scheduledProjectId,
        releaseType,
      },
    ],
    mutationFn: async () => {
      const args = {
        scheduled_project_id: scheduledProjectId,
        release_type: releaseType,
      };
      const response = await makeBackendPostCallWithJsonResponse<Release>(
        CREATE_ARTIST_RELEASE_FROM_SCHEDULED_PROJECT,
        args,
      );
      if (response.success) {
        return response.resultJson;
      }
      return Promise.reject(response.resultJson);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE,
          scheduledProjectId,
        ],
      });
    },
  });
};

export const useFetchScheduledProjectRelease = (scheduledProjectId: number) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_SCHEDULED_PROJECT_RELEASE, scheduledProjectId],
    queryFn: async () => {
      const response = await makeBackendGetCallWithJsonResponse<Release>(
        `${GET_SCHEDULED_PROJECT_RELEASE}${scheduledProjectId}/`,
        "",
      );
      if (response.statusCode === 204) {
        return null;
      }
      if (response.success) {
        return response.resultJson;
      }
      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    retry: 0,
    enabled: Boolean(scheduledProjectId),
  });
};

interface ArtistReleaseResponse {
  data: Release[];
  count: number;
  current_page: number;
  total_pages: number;
}

export const useArtistReleases = (
  artistUserId: number,
  releaseType: ReleaseType,
) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const fetchArtistReleases = async ({ pageParam }: { pageParam: number }) => {
    const params = queryString.stringify({
      page: pageParam,
    });
    const response =
      await makeBackendGetCallWithJsonResponse<ArtistReleaseResponse>(
        `${GET_ARTIST_RELEASES}${artistUserId}/${releaseType}/`,
        `?${params}`,
      );
    if (response.success) {
      const releasesResponse = response.resultJson;
      releasesResponse.data.forEach((release) => {
        queryClient.setQueryData(
          [QUERY_KEYS.FETCH_ARTIST_RELEASE, release.id],
          release,
        );
      });
      return releasesResponse;
    }
    const errors = { errors: response.resultJson };
    dispatch(receiveErrors(errors));
    return Promise.reject(response.resultJson);
  };

  return useInfiniteQuery({
    initialPageParam: 1,
    enabled: Boolean(artistUserId),
    retry: 0,
    queryKey: [
      QUERY_KEYS.FETCH_ARTIST_RELEASES,
      {
        artistUserId,
        releaseType,
      },
    ],
    queryFn: ({ pageParam }) =>
      fetchArtistReleases({
        pageParam,
      }),
    getNextPageParam: (lastPage: ArtistReleaseResponse) => {
      if (lastPage.current_page < lastPage.total_pages) {
        return lastPage.current_page + 1;
      }
      return undefined;
    },
  });
};

export const useFetchReleaseTracks = (releaseId: number) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS, releaseId],
    queryFn: async () => {
      const response = await makeBackendGetCallWithJsonResponse<ReleaseTrack[]>(
        `${BASE_RELEASE_API}${releaseId}/tracks/`,
        "",
      );
      if (response.success) {
        return response.resultJson;
      }
      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    retry: 0,
    initialData: [],
    enabled: Boolean(releaseId),
  });
};

interface ArtistTrendingReleaseTracksResponse {
  data: ReleaseTrack[];
  count: number;
  current_page: number;
  total_pages: number;
}

export const useFetchTrendingArtistReleaseTracks = (artistUserId = 0) => {
  const dispatch = useAppDispatch();
  return useInfiniteQuery({
    initialPageParam: 1,
    enabled: Boolean(artistUserId),
    queryKey: [QUERY_KEYS.FETCH_TRENDING_ARTIST_RELEASE_TRACKS, artistUserId],
    queryFn: async ({ pageParam }) => {
      const params = queryString.stringify({
        page: pageParam,
      });
      const response =
        await makeBackendGetCallWithJsonResponse<ArtistTrendingReleaseTracksResponse>(
          `${BASE_RELEASE_API}artist/user/${artistUserId}/tracks/trending/`,
          `?${params}`,
        );
      if (response.success) {
        return response.resultJson;
      }
      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
    getNextPageParam: (lastPage: ArtistTrendingReleaseTracksResponse) => {
      if (lastPage.current_page < lastPage.total_pages) {
        return lastPage.current_page + 1;
      }
      return undefined;
    },
  });
};

export const useFetchFeaturedRelease = (artistUserId = 0) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useQuery({
    retry: 0,
    enabled: Boolean(artistUserId),
    queryKey: [QUERY_KEYS.FETCH_FEATURED_ARTIST_RELEASE, artistUserId],
    queryFn: async () => {
      const response = await makeBackendGetCallWithJsonResponse<Release>(
        `${BASE_RELEASE_API}featured/artist/user/${artistUserId}/`,
        "",
      );
      if (response.statusCode === 204) {
        return null;
      }
      if (response.success) {
        const featuredRelease = response.resultJson;
        queryClient.setQueryData(
          [QUERY_KEYS.FETCH_ARTIST_RELEASE, featuredRelease.id],
          featuredRelease,
        );
        return featuredRelease;
      }
      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
  });
};

interface ReleaseDetails {
  recording_engineer_users: UserLite[];
  mixing_engineer_users: UserLite[];
  dolby_atmos_mixing_engineer_users: UserLite[];
  mastering_engineer_users: UserLite[];
  total_play: number;
}

export const useFetchReleaseDetails = (releaseId = 0) => {
  const dispatch = useAppDispatch();
  return useQuery({
    enabled: Boolean(releaseId),
    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE_DETAILS, releaseId],
    queryFn: async () => {
      const response = await makeBackendGetCallWithJsonResponse<ReleaseDetails>(
        `${BASE_RELEASE_API}${releaseId}/details/`,
        "",
      );
      if (response.success) {
        return response.resultJson;
      }
      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
  });
};

export const useReleaseDetails = (releaseId = 0) => {
  const { data: releaseDetails, isPending } = useFetchReleaseDetails(releaseId);

  const recordingEngineerCollaborators = useCollaborators(
    "Recording engineers:",
    releaseDetails?.recording_engineer_users || [],
    isPending,
  );
  const mixingEngineerCollaborators = useCollaborators(
    "Mixing engineers:",
    releaseDetails?.mixing_engineer_users ?? [],
    isPending,
  );

  const masteringEngineerCollaborators = useCollaborators(
    "Mastering engineers:",
    releaseDetails?.mastering_engineer_users ?? [],
    isPending,
  );

  const dolbyAtmosMixingEngineerCollaborators = useCollaborators(
    "Dolby atmos mixing engineers:",
    releaseDetails?.dolby_atmos_mixing_engineer_users ?? [],
    isPending,
  );

  return {
    recordingEngineerCollaborators,
    mixingEngineerCollaborators,
    masteringEngineerCollaborators,
    dolbyAtmosMixingEngineerCollaborators,
    isReleaseDetailsInitialLoading: isPending,
    playCount: releaseDetails?.total_play ?? 0,
  };
};

export const useFetchRelease = (releaseId = 0) => {
  const dispatch = useAppDispatch();
  return useQuery({
    enabled: Boolean(releaseId),
    queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE, releaseId],
    queryFn: async () => {
      const response = await makeBackendGetCallWithJsonResponse<Release>(
        `${BASE_RELEASE_API}${releaseId}/`,
        "",
      );
      if (response.success) {
        return response.resultJson;
      }
      const errors = { errors: response.resultJson };
      dispatch(receiveErrors(errors));
      return Promise.reject(response.resultJson);
    },
  });
};

interface UpdateTrackOrderArgs {
  releaseId: number;
  releaseTrackIds: number[];
  previousState: PreviousPlaylistState;
}

export const useTrackOrderUpdateMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: async ({ releaseTrackIds }: UpdateTrackOrderArgs) => {
      const response = await makeBackendPutCallWithJsonResponse(
        `${BASE_RELEASE_API}track/index/`,
        {
          release_tracks_to_swap: releaseTrackIds,
        },
      );
      if (response.success) {
        return Promise.resolve();
      }
      const errors = { errors: response.resultJson };
      return Promise.reject(errors);
    },
    mutationKey: [QUERY_KEYS.UPDATE_RELEASE_TRACK_ORDER],
    onError: (
      error: ErrorsAction,
      { releaseId, previousState }: UpdateTrackOrderArgs,
    ) => {
      dispatch(receiveErrors(error));
      dispatch(
        updatePlayListOrder({
          playlist: previousState.playlist,
          currentTrackIndex: previousState.currentTrackIndex,
        }),
      );
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ARTIST_RELEASE_TRACKS, releaseId],
      });
    },
  });
};
