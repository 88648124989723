import React from "react";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { getSubscriptionCardProps } from "../../../store/utils/subscriptionUtils";
import { SubscriptionCard } from "../../components/SubscriptionCard/SubscriptionCard";
import "./SelectStudioSubscriptionScreen.css";

export interface SelectStudioSubscriptionScreenProps {
  onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => void;
}

export const SelectStudioSubscriptionScreen = ({
  onSubscriptionSelected,
}: SelectStudioSubscriptionScreenProps) => {
  return (
    <>
      <div className="container add-subscription-flow-container">
        <div className="row justify-content-md-center">
          <h3 className="subscription-step-header">
            Choose a membership to unlock functionality and reduced service fees
          </h3>
          <p className="my-4" style={{ textAlign: "center" }}>
            All memberships come with a risk-free{" "}
            <strong>unlimited free trial</strong>. During your trial period, we
            will work closely with you to ensure your Studio's profile is
            completely set up, verified and ready to manage all of your
            sessions. Your trial will be extended indefinitely until your first
            session on the platform occurs.
          </p>
        </div>
        <div className="row justify-content-md-center">
          <div className="subscription-step-view">
            <div className="subscription-selection-section">
              {[
                SUBSCRIPTION_PLAN.GOLD_PLAN,
                SUBSCRIPTION_PLAN.PLATINUM_PLAN,
                SUBSCRIPTION_PLAN.DIAMOND_PLAN,
              ].map((subscriptionPlan) => (
                <SubscriptionCard
                  key={subscriptionPlan}
                  {...getSubscriptionCardProps({
                    plan: subscriptionPlan,
                    onSubscriptionSelected: () => {
                      onSubscriptionSelected(subscriptionPlan);
                    },
                  })}
                  useStudioBenefits={true}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
