import React from "react";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API } from "../../../store/utils";
import "./LocationInput.css";
export interface LocationInputProps {
  options?: google.maps.places.AutocompleteOptions;
  onPlaceSelected: (location: google.maps.places.PlaceResult) => void;
  defaultValue?: string;
  placeholder?: string;
  showBorder?: boolean;
  customClass?: string;
  inputStyle?: React.CSSProperties;
}

export const LocationInput = ({
  onPlaceSelected,
  options = {
    types: ["address"],
  },
  defaultValue,
  placeholder = "Enter a location",
  customClass = "location-input",
  showBorder = false,
  inputStyle,
}: LocationInputProps) => {
  const [active, setActive] = React.useState(false);
  const customStyle = showBorder ? {} : { border: "none" };

  return (
    <Autocomplete
      style={customStyle}
      inputStyle={{
        backgroundColor: "#ec0202",
        color: "#000",
        ...inputStyle,
      }}
      onBlur={() => {
        setActive(false);
      }}
      onFocus={() => {
        setActive(true);
      }}
      defaultValue={defaultValue}
      className={customClass}
      options={options}
      apiKey={GOOGLE_API}
      onPlaceSelected={onPlaceSelected}
      placeholder={active ? "Start typing an address" : placeholder}
    />
  );
};
