import React from "react";
import { Button } from "../../elements/Button/button";
import { FixedRangeNumberField } from "../../elements/FixedRangeNumberField/FixedRangeNumberField";
import "./SubscriptionSurveyScreen.css";

export interface SubscriptionSurveyScreenProps {
  numberOfSongs: number;
  averageProjectPrice: number;
  setAverageProjectPrice: (price: number) => void;
  setNumberOfSongs: (songs: number) => void;
  onClickNextStep: () => void;
}

export const SubscriptionSurveyScreen = ({
  numberOfSongs,
  averageProjectPrice,
  setAverageProjectPrice,
  setNumberOfSongs,
  onClickNextStep,
}: SubscriptionSurveyScreenProps) => {
  return (
    <div className="container add-subscription-flow-container">
      <div className="row justify-content-md-center">
        <h3 className="subscription-step-header">
          Thanks for hosting your services on EngineEars!
        </h3>
        <p className="mt-2 h7 subscription-step-header">
          Fill out the survey below to help us understand the right membership
          for you.
        </p>
      </div>
      <div className="row justify-content-md-center">
        <div className="subscription-step-view">
          <form className="py-4 subscription-form">
            <p>How many songs do you typically work on per month?</p>
            <FixedRangeNumberField
              placeholder="#"
              initialValue={numberOfSongs}
              min={1}
              max={100}
              onChange={setNumberOfSongs}
            />
          </form>
          <form className="subscription-form">
            <p>
              How much do you typically charge per song for mixing/mastering
              services?
            </p>
            <div className="dollarsign">$</div>
            <FixedRangeNumberField
              placeholder={averageProjectPrice.toString()}
              initialValue={averageProjectPrice}
              min={50}
              max={10000}
              onChange={setAverageProjectPrice}
            />
          </form>
          <Button
            customClassName="mb-5 centered-continue-button"
            label="Continue"
            primary={true}
            onClick={onClickNextStep}
          />
        </div>
      </div>
    </div>
  );
};
