import { OptionType } from "../../stories/elements/DropDownSelector/DropdownSelector";
import EntityPhoto from "./entityPhoto";
import { EP_SONG_COUNT } from "./service";
import { UserLite } from "./user";

export enum ReleaseType {
  NO_RELEASE_TYPE,
  SINGLE,
  EP,
  ALBUM,
}

export const ReleaseTypeOptions: OptionType[] = [
  {
    value: ReleaseType.NO_RELEASE_TYPE,
    label: "All",
  },
  {
    value: ReleaseType.SINGLE,
    label: "Single",
  },
  {
    value: ReleaseType.EP,
    label: "EP",
  },
  {
    value: ReleaseType.ALBUM,
    label: "Album",
  },
];

export interface Release {
  id: number;
  title: string;
  artist_user_id: number;
  artist_user: null | UserLite;
  description: null | string;
  featured: null | string;
  release_type: ReleaseType;
  release_cover: null | EntityPhoto;
  created_at: string;
}

export const getReleaseType = (numOfSongs: number) => {
  if (numOfSongs === 0) {
    return ReleaseType.NO_RELEASE_TYPE;
  }
  if (numOfSongs === 1) {
    return ReleaseType.SINGLE;
  }
  if (numOfSongs <= EP_SONG_COUNT) {
    return ReleaseType.EP;
  }
  return ReleaseType.ALBUM;
};

export interface ReleaseTrack {
  id: number;
  title: string;
  file_version_id: number | null;
  track_number: number;
  play_count: number;
  release: Release;
}
