import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useGetRoleOnFeaturedTrack } from "../../../hooks/portfolioFeatureData";
import useModal from "../../../hooks/useModal";
import { PortfolioFeatureData } from "../../../store/models/portfolio";
import { FeaturedABTrackWrapper } from "../../components/FeaturedABTrack/FeaturedABTrackWrapper";
import { BaseAccordion } from "../../core-ui/components/BaseAccordion/BaseAccordion";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { AlbumIcon, FeaturedTrackInfo } from "../AlbumIcon/AlbumIcon";
import { FeaturedTrackDeleteModalBody } from "./FeaturedTrack.styles";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";

interface ConfirmRemoveModalProps {
  deleteFeatureData: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  closeModal: () => void;
  isUpdatingTrack: boolean;
  children: React.ReactNode;
}
const ConfirmRemoveModal = ({
  deleteFeatureData,
  setIsOpen,
  closeModal,
  isOpen,
  isUpdatingTrack,
  children,
}: ConfirmRemoveModalProps) => {
  return (
    <BaseModal
      setOpen={setIsOpen}
      open={isOpen}
      header="Remove song from profile"
    >
      <FeaturedTrackDeleteModalBody>
        <Text variant={TextStyleVariant.P1}>
          Are you sure you want to remove this song from your profile? You can
          always add it back in if you change your mind.
        </Text>
        {children}
        <Box sx={{ display: "flex", justifyContent: "center", gap: "24px" }}>
          <Button
            style={{ width: "108px" }}
            loading={isUpdatingTrack}
            onClick={closeModal}
            variant={ButtonVariant.OUTLINED}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "108px" }}
            onClick={() => {
              deleteFeatureData();
              closeModal();
            }}
            loading={isUpdatingTrack}
            variant={ButtonVariant.PRIMARY}
          >
            Remove
          </Button>
        </Box>
      </FeaturedTrackDeleteModalBody>
    </BaseModal>
  );
};

interface FeaturedTrack {
  portfolioFeatureData: PortfolioFeatureData;
  isEditMode: boolean;
  isUpdatingTrack: boolean;
  deleteFeatureData: () => void;
}

export const FeaturedTrack = ({
  portfolioFeatureData,
  isEditMode,
  isUpdatingTrack,
  deleteFeatureData,
}: FeaturedTrack) => {
  const projectRole = useGetRoleOnFeaturedTrack(portfolioFeatureData);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const { isOpen, setIsOpen, closeModal } = useModal();
  const albumElement = (
    <AlbumIcon
      id={portfolioFeatureData.id ?? 0}
      isPortfolio
      albumName={portfolioFeatureData.album!}
      imageSource={portfolioFeatureData.imageSource!}
    >
      <FeaturedTrackInfo
        trackTitle={portfolioFeatureData.title}
        albumTitle={portfolioFeatureData.album!}
        readableProjectType={projectRole}
      />
    </AlbumIcon>
  );

  return (
    <>
      <BaseAccordion
        key={portfolioFeatureData.id}
        renderHeader={() => {
          return (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {albumElement}
              {isEditMode && (
                <Box sx={{ marginLeft: "auto", marginRight: "24px" }}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}
                    loading={isUpdatingTrack}
                    variant={ButtonVariant.OUTLINED}
                  >
                    Remove Song
                  </Button>
                </Box>
              )}
            </Box>
          );
        }}
        onChange={(event, expanded) => {
          event.preventDefault();
          setAccordionExpanded(expanded);
        }}
      >
        <>
          {accordionExpanded && portfolioFeatureData.track_details?.project && (
            <FeaturedABTrackWrapper
              project={portfolioFeatureData.track_details?.project}
              portfolioFeatureData={portfolioFeatureData}
            />
          )}
        </>
      </BaseAccordion>
      <ConfirmRemoveModal
        isUpdatingTrack={isUpdatingTrack}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteFeatureData={deleteFeatureData}
        closeModal={closeModal}
      >
        {albumElement}
      </ConfirmRemoveModal>
    </>
  );
};
