import { useHistory } from "react-router-dom";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { SCREENS } from "../../../routes";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  DashboardCard,
  DashboardCardProps,
} from "../Dashboard/DashboardCard/DashboardCard";
import "./CashOut.css";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";

export type CashOutProps = {
  totalStripeBalance: number;
  availableStripeBalance: number;
  currencyToWithdraw: string;
  meetsStripeRequirements: string | null | undefined;
  onClickCashOut?: () => void;
  cashingOut: boolean;
  onClickExtend: () => void;
  onClickViewTransactions: () => void;
  onSidePanel?: boolean;
  isLoading?: boolean;
} & Pick<DashboardCardProps, "contentOnly" | "noHeaderIcon">;

export const CashOut = ({
  totalStripeBalance,
  availableStripeBalance,
  currencyToWithdraw,
  meetsStripeRequirements,
  onClickCashOut,
  cashingOut,
  onClickExtend,
  onClickViewTransactions,
  onSidePanel = false,
  contentOnly = false,
  noHeaderIcon = false,
  isLoading,
}: CashOutProps) => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const history = useHistory();

  return (
    <DashboardCard
      cardHeight={isDesktop ? 125 : 205}
      contentOnly={contentOnly}
      headerText="Balance"
      handleHeaderClick={onClickExtend}
      noHeaderIcon={noHeaderIcon}
    >
      {isLoading ? (
        <SoundWaveLoader width={100} height={50} />
      ) : (
        <div className="cashout-content">
          <div className="cashout-content-left">
            <p className="cashout-total-balance">
              {PennyDollarFormatter(currencyToWithdraw).format(
                totalStripeBalance,
              )}
            </p>
            <p className={"cashout-available-to-pay-out"}>
              {`Available to pay out ($${availableStripeBalance} available instantly)`}
            </p>
            {!onSidePanel && (
              <p
                onClick={onClickViewTransactions}
                className={"cashout-transaction-history"}
              >
                View transaction history
              </p>
            )}
          </div>
          <div className="cashout-content-right">
            <div className="cashout-button-group-container">
              {meetsStripeRequirements ? (
                <Button
                  variant={ButtonVariant.PRIMARY}
                  className="withdraw-funds-button"
                  disabled={availableStripeBalance <= 0 || cashingOut}
                  loading={cashingOut}
                  onClick={() => {
                    if (onClickCashOut) {
                      onClickCashOut();
                    }
                  }}
                >
                  {availableStripeBalance > 0
                    ? "Withdraw Funds"
                    : "Nothing To Withdraw"}
                </Button>
              ) : (
                <Button
                  loading={meetsStripeRequirements != null}
                  variant={ButtonVariant.PRIMARY}
                  className="withdraw-funds-button"
                  onClick={() => {
                    history.push(SCREENS.SETUP_STRIPE);
                  }}
                >
                  {"Set Up Stripe Connect"}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </DashboardCard>
  );
};
