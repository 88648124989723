import { useEffect, useMemo, useState } from "react";
import {
  getEntityPhotos,
  PaginatedEntityPhotoData,
  PaginatedEntityPhotoDataPayload,
} from "../store/actions/entityPhotoStore";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import EntityPhoto from "../store/models/entityPhoto";

export const enum SupportedEntityTypes {
  Studio,
  StudioRoom,
  Release,
}

export const INVALID_ENTITY_ID = -1;

const convertPaginatedDataToPayloadData = (
  data: PaginatedEntityPhotoData | undefined,
): PaginatedEntityPhotoDataPayload | undefined => {
  if (!data) {
    return undefined;
  }
  const indices = Object.keys(data.photos)
    .reduce((acc, curr) => {
      acc.push(+curr);
      return acc;
    }, [] as number[])
    .sort();

  const photos = indices.reduce((acc, index) => {
    if (data.photos[index]) {
      return acc.concat(data.photos[index]);
    }
    return acc;
  }, [] as EntityPhoto[]);

  return {
    page: data.page,
    total_pages: data.total_pages,
    total_photos: data.total_photos,
    photos: photos,
  };
};

export const useEntityPhotos: {
  (
    entityType: SupportedEntityTypes,
    entityId?: number,
    refetch?: boolean,
  ): {
    entityPhotoData: PaginatedEntityPhotoDataPayload | undefined;
    fetching: boolean;
  };
} = (entityType: SupportedEntityTypes, entityId?: number) => {
  const entityPhotoStore = useAppSelector((state) => state.entityPhotoStore);
  const [fetching, setFetching] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const entityPhotoData: PaginatedEntityPhotoDataPayload | undefined =
    useMemo(() => {
      switch (entityType) {
        case SupportedEntityTypes.Studio:
          return convertPaginatedDataToPayloadData(
            entityPhotoStore.studioPhotos[entityId ?? INVALID_ENTITY_ID],
          );
        case SupportedEntityTypes.StudioRoom:
          return convertPaginatedDataToPayloadData(
            entityPhotoStore.studioRoomPhotos[entityId ?? INVALID_ENTITY_ID],
          );
        default:
          return undefined;
      }
    }, [entityPhotoStore, entityType, entityId]);

  useEffect(() => {
    if (entityPhotoData) return;
    if (entityId === undefined) return;
    setFetching(true);
    dispatch(
      getEntityPhotos({ id: entityId, entity_photo_type: entityType }),
    ).finally(() => setFetching(false));
  }, [entityPhotoData, entityType, entityId, dispatch]);

  return useMemo(() => {
    return {
      entityPhotoData,
      fetching,
    };
  }, [entityPhotoData, fetching]);
};
