export enum SUBSCRIPTION_PLAN {
  NO_SUBSCRIPTION_PLAN,
  BASIC_PLAN,
  GOLD_PLAN,
  GOLD_YEARLY_PLAN,
  PLATINUM_PLAN,
  PLATINUM_YEARLY_PLAN,
  DIAMOND_PLAN,
  GOLD_QUARTERLY_PLAN,
}

export enum SUBSCRIPTION_STATUS {
  INACTIVE = "inactive",
  ACTIVE = "active",
  NOT_DEFINED = "not_defined",
  TRIALING = "trialing",
}

export interface Subscription {
  subscription_plan_choice: SUBSCRIPTION_PLAN;
  stripe_subscription_status: SUBSCRIPTION_STATUS;
}

export const SUBSCRIPTION_FEE_MAP = new Map<SUBSCRIPTION_PLAN, number>([
  [SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN, 0.12],
  [SUBSCRIPTION_PLAN.BASIC_PLAN, 0.12],
  [SUBSCRIPTION_PLAN.GOLD_PLAN, 0.05],
  [SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN, 0.05],
  [SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN, 0.05],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN, 0.0],
  [SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN, 0.0],
]);

export const SUBSCRIPTION_FEE_STRING_MAP = new Map<SUBSCRIPTION_PLAN, string>([
  [SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN, "12% service fee"],
  [SUBSCRIPTION_PLAN.BASIC_PLAN, "12% service fee"],
  [SUBSCRIPTION_PLAN.GOLD_PLAN, "5% service fee"],
  [SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN, "5% service fee"],
  [SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN, "5% service fee"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN, "0% service fee"],
  [SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN, "0% service fee"],
  [SUBSCRIPTION_PLAN.DIAMOND_PLAN, "0% service fee"],
]);

export const NUM_OFFERABLE_SERVICES = new Map<SUBSCRIPTION_PLAN, string>([
  [SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN, "None"],
  [SUBSCRIPTION_PLAN.BASIC_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.GOLD_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.PLATINUM_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN, "Unlimited"],
  [SUBSCRIPTION_PLAN.DIAMOND_PLAN, "Unlimited"],
]);

export const getSubsPlanFeatureList = (plan: SUBSCRIPTION_PLAN) => {
  const includeBasic = [
    SUBSCRIPTION_FEE_STRING_MAP.get(plan),
    NUM_OFFERABLE_SERVICES.get(plan),
    "No",
    "Yes",
    "Yes",
    "No",
    "Yes",
    "3% fee",
    "Yes",
    "No",
    "No",
    "", // This added as a hack for spacing purposes.
  ];

  const includeGold = [
    SUBSCRIPTION_FEE_STRING_MAP.get(plan),
    NUM_OFFERABLE_SERVICES.get(plan),
    "Partial",
    "Yes",
    "Yes",
    "Yes",
    "Yes",
    "No Fee",
    "Yes",
    "Yes",
    "Yes",
  ];

  const includePlatinum = [
    SUBSCRIPTION_FEE_STRING_MAP.get(plan),
    NUM_OFFERABLE_SERVICES.get(plan),
    "Yes",
    "Yes",
    "Yes",
    "Yes",
    "Yes",
    "No fee",
    "Yes",
    "Yes",
    "Yes",
  ];

  if (plan === SUBSCRIPTION_PLAN.PLATINUM_PLAN) {
    return includePlatinum;
  } else if (plan === SUBSCRIPTION_PLAN.GOLD_PLAN) {
    return includeGold;
  }
  return includeBasic;
};

export const subscriptionFeatureList = [
  "Per project fee",
  "Number of services offerable",
  "EnginEars Toolbox (plugins and more to come)",
  "Virtual Assistant and calendar for automated scheduling and reminders",
  "Profile to manage bookings and showcase services, awards, and more",
  "Verified credits",
  "Bookable by labels and payable by P.O.",
  "Instant payout from P.Os ",
  "Past work featured on profile with A/B",
  "Access to EngineEars Community Events",
  "EngineEars School of Music including Dolby Atmos Training and Certification Course",
];
