import { useMemo } from "react";
import { PendingSessionData } from "../store/actions/shoppingCart";
import { useAppSelector } from "../store/hooks";
import { Project, ProjectType } from "../store/models/project";
import { RecordingService } from "../store/models/recording";
import { Transaction } from "../store/models/transaction";

export const convertUSDToCents = (usd: number) => {
  return Math.round(usd * 100);
};

export const useTransactionTotalAsPennies = (
  transaction: Transaction | undefined | null,
): number => {
  return useMemo(() => {
    if (!transaction) {
      return 0;
    }
    const total = parseFloat(transaction.total_price);
    const fees = parseFloat(transaction.fees_collected ?? "");
    return convertUSDToCents(total + fees) || 0;
  }, [transaction]);
};

export const useTransactionProjects = (transaction?: Transaction) => {
  return useMemo(() => {
    if (!transaction) {
      return [] as Project[];
    }
    // keep track of unique project ids
    const uniqueProjectIds: Record<number, boolean> = {};

    return transaction.items.reduce((acc, item) => {
      // if transaction item has a project and it is not already in the accumulator, add it
      if (item.project && !uniqueProjectIds[item.project.id]) {
        acc.push(item.project);
        uniqueProjectIds[item.project.id] = true;
      }
      return acc;
    }, [] as Project[]);
  }, [transaction]);
};

/**
 * @TODO Can this be deleted?
 */
export const useGetMixMasterMinimumDepositPercentage = (
  serviceType: ProjectType | undefined,
  serviceId: number | undefined,
) => {
  const engineerServices = useAppSelector((state) => state.engineerServices);
  if (!serviceType || !serviceId) return 1;
  if (serviceType === ProjectType.RECORDING) {
    return 1;
  }
  const service = engineerServices.services.find(
    (service) => service.id === serviceId,
  );
  return service?.minimum_deposit_percentage || 1;
};

/**
 * @TODO Can this be deleted?
 * Returns the maximum of all minimum deposit percentages for recording services in the pending sessions,
 * and all of the recording servivce IDs, required for transaction invoice url creation.
 */
export const useGetRecordingMinimumDepositPercentage = (
  activeRecordingService: RecordingService | null,
) => {
  const engineerRecordingServices = useAppSelector(
    (state) => state.engineerRecordingServices,
  );
  const { activeStudioId, activeStudioRoomId } = useAppSelector(
    (state) => state.generateBookingStore,
  );

  const pendingSessions = useAppSelector(
    (state) => state.shoppingCart.pendingSessionData,
  );
  const studios = useAppSelector((state) => state.studiosSlice);

  return useMemo(() => {
    if (!activeRecordingService)
      return { recordingMinimumDeposit: 1, recordingServiceIds: [] };
    const recordingServiceIds: number[] = [];
    let minimumDeposit = activeRecordingService.minimum_deposit_percentage;
    recordingServiceIds.push(activeRecordingService.id);
    // If the active recording service is for a studio, checks for all recording engineer services
    // included in pending sessions.
    if (activeRecordingService.studio_room) {
      if (pendingSessions) {
        pendingSessions.forEach((session) => {
          if (session.trackingEngineer) {
            const selectedEngService =
              engineerRecordingServices[session.trackingEngineer.id];
            if (selectedEngService) {
              recordingServiceIds.push(selectedEngService.id);
            }
            minimumDeposit = Math.max(
              minimumDeposit,
              selectedEngService?.minimum_deposit_percentage ?? 0,
            );
          }
        });
      }
      // If the active recording service is for an engineer, checks for all studio room recording services
      // included in pending sessions.
    } else if (activeRecordingService.engineer) {
      if (activeStudioId && activeStudioRoomId) {
        const studioRoomService = studios[activeStudioId]?.studio_rooms?.find(
          (room) => room.id === activeStudioRoomId,
        )?.recording_service;
        if (studioRoomService) {
          recordingServiceIds.push(studioRoomService.id);
        }
        minimumDeposit = Math.max(
          minimumDeposit,
          studioRoomService?.minimum_deposit_percentage ?? 0,
        );
      }
    }
    return { recordingMinimumDeposit: minimumDeposit, recordingServiceIds };
  }, [
    activeRecordingService,
    pendingSessions,
    activeStudioId,
    activeStudioRoomId,
    engineerRecordingServices,
    studios,
  ]);
};

// Determines if partial payment should be an option based on earliest recording session start date.
export const usePartialPaymentDateAvailable = (
  pendingSessions: PendingSessionData[] | undefined,
  days: number,
) => {
  return useMemo(() => {
    if (!pendingSessions) return true;
    const today = new Date();
    const dateLimit = new Date();
    dateLimit.setDate(today.getDate() + days);
    const sessionDates = pendingSessions.reduce((acc, session) => {
      if (session.selectedISODate) {
        acc.push(new Date(session.selectedISODate));
      }
      return acc;
    }, [] as Date[]);

    if (sessionDates.length < 1) {
      return true;
    }

    const earliestStartDate = new Date(
      Math.min.apply(
        null,
        sessionDates.map((date) => date.getTime()),
      ),
    );
    return earliestStartDate > dateLimit;
  }, [pendingSessions, days]);
};
