import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIsPrimaryStudioManager } from "../../../hooks/studioHooks";
import { SCREENS } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import { PromoCode } from "../../../store/models/promoCode";
import { Studio, StudioRoom } from "../../../store/models/studio";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../../store/models/subscription";
import { useManagerCanEditStudio } from "../../../store/selectors/teamSelectors";
import { getStudioRoomScreenRoute } from "../../../store/utils/routeGetters";
import { PrimaryEntityImage } from "../PrimaryEntityImage/PrimaryEntityImage";
import { getStudioRoomProfileCard } from "../ProfileCard/ProfileCard";
import { CreateStudioRoomModal } from "../StudioModals/CreateStudioRoomModal";
import { StudioSubModal } from "../StudioSubModal/StudioSubModal";
import "./StudioRoom.css";

export interface StudioRoomListProps {
  studioRooms: StudioRoom[];
  studio: Studio;
  promoCode?: PromoCode;
  showAddRoomCard?: boolean;
}

const StudioRoomList = ({
  studioRooms,
  studio,
  promoCode,
  showAddRoomCard = true,
}: StudioRoomListProps) => {
  const history = useHistory();
  const [showAddRoomModal, setShowAddRoomModal] = useState<boolean>(false);
  const subscription = useAppSelector((state) => state.subscriptionStore);
  const isPrimaryStudioManager = useIsPrimaryStudioManager(studio?.id);
  const [needsUpgradedSubscription, setNeedsUpgradedSubscription] =
    useState<boolean>(false);
  const canEditStudioService = useManagerCanEditStudio(studio?.id);

  const [showStudioSubModal, setShowStudioSubModal] = useState<boolean>(false);

  useEffect(() => {
    if (!isPrimaryStudioManager) return;
    if (studioRooms?.length < 1) return;
    if (
      subscription.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN ||
      subscription.subscription_plan_choice === SUBSCRIPTION_PLAN.BASIC_PLAN ||
      subscription.stripe_subscription_status !== SUBSCRIPTION_STATUS.ACTIVE
    ) {
      setNeedsUpgradedSubscription(true);
    }
    if (
      studioRooms?.length >= 1 &&
      subscription.subscription_plan_choice !== SUBSCRIPTION_PLAN.DIAMOND_PLAN
    ) {
      setNeedsUpgradedSubscription(true);
    }
  }, [subscription, isPrimaryStudioManager, studioRooms]);

  return (
    <div className={"studio-row studio-rooms"}>
      {studioRooms.map((room, index) =>
        getStudioRoomProfileCard(
          room,
          index,
          getStudioRoomScreenRoute(studio.username, room.id),
          promoCode,
          false,
        ),
      )}
      <CreateStudioRoomModal
        show={showAddRoomModal}
        studio={studio}
        showXButton={true}
        onHide={() => {
          if (showAddRoomModal) {
            setShowAddRoomModal(false);
          }
        }}
      />
      {isPrimaryStudioManager &&
        showAddRoomCard &&
        !(studioRooms?.length >= 1 && !canEditStudioService) && (
          <div
            onClick={() => {
              if (needsUpgradedSubscription) {
                setShowStudioSubModal(true);
                return;
              }
              if (!showAddRoomModal) setShowAddRoomModal(true);
            }}
          >
            <AddStudioRoomCard studio={studio} />
          </div>
        )}
      <StudioSubModal
        modalIsOpen={showStudioSubModal}
        explanationText={"Upgrade your subscription to add more rooms."}
        onClickContinue={() => {
          history.push(SCREENS.STUDIO_SUBSCRIPTION);
        }}
        onClickCancel={() => {
          setShowStudioSubModal(false);
        }}
      />
    </div>
  );
};

const AddStudioRoomCard = ({ studio }: { studio: Studio }) => {
  return (
    <div className={"studio-room studio-room-add-room-card"}>
      <div className="studio-room-card-contents">
        <PrimaryEntityImage
          primaryPhoto={null}
          editMode={false}
          imageClassName={"studio-room-image"}
          canManageStudio={true}
        />
        <p className={"b1 studio-room-card-title"}>
          <span
            className={
              "h7-semi-bold studio-room-card-name underlined-room-text"
            }
          >
            Click here to add a new room
          </span>
        </p>
        <p className={"h8-semi-bold studio-room-location-text"}>
          {studio.location
            ? studio.location.city_location ?? "Location"
            : "Location"}
        </p>
        <div className="studio-room-card-pricing">
          <p className={"b1-semi-bold studio-room-card-price"}>
            <span className={"b1"}>
              <strong>$</strong> per hour
            </span>
          </p>
          <p className="b3 studio-room-card-minimum-time">Unset hour minimum</p>
        </div>
      </div>
    </div>
  );
};

export default StudioRoomList;
