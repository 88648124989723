import { useState } from "react";
import User from "../../../../../../store/models/user";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import {
  TEXT_COLOR,
  TEXT_LINE_HEIGHT,
  TEXT_SIZE,
  TEXT_WEIGHT,
  Text,
} from "../../../../../core-ui/components/Text/Text";
import { FeedbackModal } from "../../../../FeedbackModal/FeedbackModal";

interface AnnouncementHelpProps {
  userId: User["id"] | undefined;
}

export const AnnouncementHelpButton: React.FC<AnnouncementHelpProps> = ({
  userId,
}: AnnouncementHelpProps) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  return (
    <>
      <button
        className="announcement-header-link"
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          marginLeft: "1rem",
          padding: "0",
        }}
        type="button"
        onClick={() => {
          emitAnalyticsTrackingEvent("logged_in_open_feedback", {}, userId);
          setShowFeedbackModal(!showFeedbackModal);
        }}
      >
        <Text
          color={TEXT_COLOR.INVERT}
          lineHeight={TEXT_LINE_HEIGHT.SHORT}
          size={TEXT_SIZE.SMALL}
          weight={TEXT_WEIGHT.BOLD}
        >
          Help
        </Text>
      </button>
      {showFeedbackModal && (
        <FeedbackModal
          onClose={() => {
            setShowFeedbackModal(false);
          }}
        />
      )}
    </>
  );
};
