import { useSubscriptionName } from "../../../hooks/useSubscriptionTrophy";
import {
  getSubsPlanFeatureList,
  SUBSCRIPTION_PLAN,
} from "../../../store/models/subscription";
import checkmark_accepted from "../../assets/checkmark_accepted.svg";
import "./SubsPlanFeatureList.css";

export interface SubsPlanFeatureListProps {
  plan: SUBSCRIPTION_PLAN;
}
export const SubsPlanFeatureList = ({ plan }: SubsPlanFeatureListProps) => {
  const featureIncluded = getSubsPlanFeatureList(plan);
  const addCheckmark = (included: string) =>
    included === "Yes" ? "checkmark" : "";
  return (
    <div className="plan-feature-card">
      <div>
        <strong>{useSubscriptionName(plan)}</strong>
      </div>
      {featureIncluded.map((included, idx) => {
        return (
          <div
            className={included === "Yes" ? "included-checkmark" : "included"}
            key={idx}
          >
            <p className="subscription-plan-item">
              {included !== "Yes" ? included : ""}
            </p>
          </div>
        );
      })}
    </div>
  );
};
