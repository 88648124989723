import { Box } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { useEngineerIsBooking } from "../../../hooks/useEngineerCanHostServices";
import { useGetSubscriptionStatus } from "../../../hooks/useGetSubscriptionStatus";
import { SCREENS } from "../../../routes";
import { useAppSelector } from "../../../store/hooks";
import Engineer from "../../../store/models/engineer";
import { ProjectType } from "../../../store/models/project";
import { RecordingService } from "../../../store/models/recording";
import Service from "../../../store/models/service";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../../store/models/subscription";
import User from "../../../store/models/user";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { PreSubscriptionView } from "../PreSubscriptionView/PreSubscriptionView";

interface EngineerServicesWrapperProps {
  combinedServices: (Service | RecordingService)[];
  userData: User;
  engineer: Engineer;
  userOnOwnProfile: boolean;
  editServiceType?: ProjectType;
  addServiceType?: ProjectType;
  setAddServiceType: (serviceType?: ProjectType) => void;
  children: ReactNode;
  remainingServiceOptions: OptionType<ProjectType>[];
}

export const EngineerServicesWrapper = ({
  combinedServices,
  userData,
  engineer,
  userOnOwnProfile,
  editServiceType,
  addServiceType,
  setAddServiceType,
  children,
  remainingServiceOptions,
}: EngineerServicesWrapperProps) => {
  const { meetsStripeRequirements } = useAppSelector(
    (state) => state.accountInfo,
  );
  const engineerIsBooking = useEngineerIsBooking(engineer);
  const { data: subscription } = useGetSubscriptionStatus({
    user: userData,
  });

  const showPreSubscriptionView = useMemo(() => {
    return (
      subscription?.subscription_plan_choice ===
        SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN &&
      subscription?.stripe_subscription_status !==
        SUBSCRIPTION_STATUS.NOT_DEFINED &&
      meetsStripeRequirements &&
      userOnOwnProfile
    );
  }, [
    meetsStripeRequirements,
    subscription?.stripe_subscription_status,
    subscription?.subscription_plan_choice,
    userOnOwnProfile,
  ]);

  if (
    showPreSubscriptionView &&
    combinedServices.length > 0 &&
    !(addServiceType || editServiceType)
  ) {
    return <Redirect to={SCREENS.ADD_SUBSCRIPTION} />;
  }

  if (!userOnOwnProfile && !engineerIsBooking) {
    return (
      <Text>{`@${userData.username}  is not currently booking projects`}</Text>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
      {!engineer?.verified && !userOnOwnProfile && (
        <Text bold>
          {`Note that ${getDisplayableNameForUser(userData)} is still in the verification process and has not yet been verified by the EngineEars team.`}
        </Text>
      )}
      {showPreSubscriptionView && (
        <PreSubscriptionView
          onClickAddService={
            remainingServiceOptions.length > 0
              ? () => {
                  setAddServiceType(remainingServiceOptions[0].value);
                }
              : undefined
          }
        />
      )}
      {children}
    </Box>
  );
};
