import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ToolTipBubble,
  ToolTipTypes,
} from "../../components/ToolTipBubble/ToolTipBubble";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import "./ChatBubbleButton.css";

export interface ChatBubbleButtonProps {
  onClick?: () => void;
}

export const ChatBubbleButton = ({
  onClick = () => {},
}: ChatBubbleButtonProps) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [tooltipProps, setTooltipProps] = useState<Record<string, unknown>>({});
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const hasShownBefore = localStorage.getItem(ToolTipTypes.ChatBubbleButton);
    if (!hasShownBefore) {
      setShowToolTip(true);
    }
  }, []);

  const handleOnClick = useCallback(() => {
    if (showToolTip) {
      localStorage.setItem(ToolTipTypes.ChatBubbleButton, "true");
      setShowToolTip(false);
    }
    if (onClick) onClick();
  }, [onClick, showToolTip]);

  useLayoutEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    const right = (rect?.width || 0) / 2;
    const top = (rect?.height || 0) + 5;
    const maxWidth = window.innerWidth - (rect?.x || 0) + right;
    setTooltipProps({
      top,
      right,
      maxWidth: `${maxWidth}px`,
    });
  }, [setTooltipProps, ref]);

  return (
    <ToolTipBubble
      showToolTip={showToolTip}
      {...tooltipProps}
      onClick={() => {
        localStorage.setItem(ToolTipTypes.ChatBubbleButton, "true");
        setShowToolTip(false);
      }}
      header={"Send a message to your collaborator"}
      text={
        "Look for this chat bubble on any project page to send messages directly to your collaborator"
      }
    >
      <Button
        onClick={handleOnClick}
        labelIcon={<FontAwesomeIcon icon={faComments} size="lg" />}
        variant={ButtonVariant.OUTLINED}
        ref={ref}
      >
        Chat
      </Button>
    </ToolTipBubble>
  );
};
