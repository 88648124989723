import { Box } from "@mui/material";
import { TrendingArtistReleaseTracks } from "../TrendingArtistReleaseTracks/TrendingArtistReleaseTracks";
import { ArtistReleases } from "../ArtistReleases/ArtistReleases";
import { ReactNode } from "react";


interface ReleaseOverviewProps {
  shouldShowDesktop: boolean;
  onReleaseClick: (releaseId?: number) => void;
  children?: ReactNode;
}

export const ReleaseOverview = ({
  children,
  onReleaseClick,
  shouldShowDesktop,
}: ReleaseOverviewProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "54px" }}>
      {children}
      <TrendingArtistReleaseTracks
        onReleaseClick={onReleaseClick}
        shouldShowDesktop={shouldShowDesktop}
      />
      <ArtistReleases
        onReleaseClick={onReleaseClick}
        shouldShowDesktop={shouldShowDesktop}
      />
    </Box>
  );
};
