import { useMemo } from "react";
import {
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
  ChannelStateOptions,
  DefaultGenerics,
  StreamChat,
} from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { CHANNEL_ID_PREFIX } from "../../constants/chat";
import { getChannelIdAndMembers } from "../../utils/chatUtils";
import { useQuery } from "@tanstack/react-query";
interface Params {
  creatorId?: number;
  prefix: CHANNEL_ID_PREFIX;
  relatedId?: number;
  extraMembers?: number[];
  filters?: Omit<ChannelFilters, "id">;
  sort?: ChannelSort;
  options?: ChannelOptions;
  stateOptions?: ChannelStateOptions;
}
export const useQueryChannel = ({
  creatorId,
  prefix,
  relatedId,
  extraMembers = [],
  filters,
  sort = { last_message_at: -1 },
  options = {
    watch: true,
    state: true,
  },
  stateOptions = undefined,
}: Params) => {
  const { client } = useChatContext("useExistingChannel") as {
    client: StreamChat<DefaultGenerics>;
  };

  const { channelId } = useMemo(() => {
    if (!creatorId || !relatedId) return { channelId: undefined, members: [] };
    return getChannelIdAndMembers({
      creatorId,
      prefix,
      relatedId,
      extraMembers,
    });
  }, [creatorId, prefix, relatedId, extraMembers]);
  return useQuery({
    queryKey: ["channel", channelId],
    queryFn: async () => {
      return client
        .queryChannels(
          { id: channelId, ...filters },
          sort,
          options,
          stateOptions,
        )
        .then((data) => {
          if (data.length) {
            return data[0];
          }
          return null;
        });
    },
    enabled: Boolean(client) && Boolean(channelId),
  });
};

export default useQueryChannel;
