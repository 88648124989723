import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  artistMasteringTransitions,
  artistMixingTransitions,
} from "../../../../store/actions/projects";
import { useAppDispatch } from "../../../../store/hooks";
import {
  APIMasteringProjectBase,
  APIMixingProjectBase,
  MasteringTransitions,
  MixingProjectSteps,
  MixingTransitions,
} from "../../../../store/models/project";

export interface TransferFileArgs {
  mixingProject?: APIMixingProjectBase;
  masteringProject?: APIMasteringProjectBase;
  projectId: number;
  artistNotes: string;
  code?: string;
}

export const useArtistTransferFiles = (args: TransferFileArgs) => {
  const dispatch = useAppDispatch();
  const [transferLoading, setTransferLoading] = useState(false);
  const [skipUploadLoading, setSkipUploadLoading] = useState(false);

  const mixingAcceptTransitions = useMemo(() => {
    if (args.mixingProject?.step === MixingProjectSteps.BOOKING_REQUESTED) {
      return MixingTransitions.ARTIST_UPLOAD_REFERENCE;
    }
    if (
      args.mixingProject?.step === MixingProjectSteps.PROJECT_ACCEPTED ||
      args.mixingProject?.step === MixingProjectSteps.FILES_NEED_REUPLOAD
    ) {
      return MixingTransitions.ARTIST_UPLOAD_FILES;
    }
  }, [args.mixingProject?.step]);

  const mixingRejectTransitions = useMemo(() => {
    if (args.mixingProject?.step === MixingProjectSteps.BOOKING_REQUESTED) {
      return MixingTransitions.ARTIST_SKIP_REFERENCE_UPLOAD;
    }
    if (
      args.mixingProject?.step === MixingProjectSteps.REFERENCE_UPLOADED ||
      args.mixingProject?.step === MixingProjectSteps.PROJECT_ACCEPTED ||
      args.mixingProject?.step === MixingProjectSteps.ENG_FILE_REVIEW ||
      args.mixingProject?.step === MixingProjectSteps.FILES_NEED_REUPLOAD
    ) {
      return MixingTransitions.ARTIST_SKIP_FILE_UPLOADS;
    }
  }, [args.mixingProject?.step]);

  const aristUploadSkip = useCallback(async () => {
    // prevent forever loading state
    if (!args.masteringProject && !args.mixingProject) {
      toast.error("Project not found");
      return;
    }
    setSkipUploadLoading(true);
    if (args.masteringProject) {
      void dispatch(
        artistMasteringTransitions({
          project_id: args.projectId.toString(),
          artist_master_notes: args.artistNotes,
          transition: MasteringTransitions.ARTIST_SKIP_UPLOAD_FILES,
          code: args.code,
        }),
      )
        .unwrap()
        .finally(() => setSkipUploadLoading(false));
    }
    if (args.mixingProject && mixingRejectTransitions) {
      void dispatch(
        artistMixingTransitions({
          project_id: args.projectId.toString(),
          artist_mix_notes: args.artistNotes,
          transition: mixingRejectTransitions,
          code: args.code,
        }),
      )
        .unwrap()
        .finally(() => setSkipUploadLoading(false));
    }
  }, [
    dispatch,
    args.projectId,
    args.artistNotes,
    args.code,
    mixingRejectTransitions,
    args.mixingProject,
    args.masteringProject,
  ]);

  const artistUpload = useCallback(async () => {
    // prevent forever loading state
    if (!args.masteringProject && !args.mixingProject) {
      toast.error("Project not found");
      return;
    }
    setTransferLoading(true);
    if (args.masteringProject) {
      void dispatch(
        artistMasteringTransitions({
          project_id: args.projectId.toString(),
          artist_master_notes: args.artistNotes,
          transition: MasteringTransitions.ARTIST_UPLOAD_FILE,
          code: args.code,
        }),
      )
        .unwrap()
        .finally(() => setTransferLoading(false));
    }
    if (args.mixingProject && mixingAcceptTransitions) {
      void dispatch(
        artistMixingTransitions({
          project_id: args.projectId.toString(),
          artist_mix_notes: args.artistNotes,
          transition: mixingAcceptTransitions,
          code: args.code,
        }),
      )
        .unwrap()
        .finally(() => setTransferLoading(false));
    }
  }, [
    dispatch,
    args.projectId,
    args.artistNotes,
    args.code,
    mixingAcceptTransitions,
    args.mixingProject,
    args.masteringProject,
  ]);
  return {
    transferFileTransition: artistUpload,
    transferLoading,
    skipUploadFileTransition: aristUploadSkip,
    skipUploadLoading,
  };
};
