import { EngineEarsError } from "../store/actions/errorStore";
import {
  exceptionCodeErrorStringMap,
  TRANSITION_NOT_ALLOWED,
  UNKNOWN_ERROR,
} from "../store/models/exceptions";
import { toast } from "react-toastify";

export const assertEngineEarsErrorType = (
  obj: unknown,
): obj is EngineEarsError => {
  return (
    typeof obj === "object" &&
    typeof (obj as any).code !== "undefined" &&
    (typeof (obj as any).non_field_errors !== "undefined" ||
      typeof (obj as any).detail !== "undefined")
  );
};

export const displayEngineEarsError = (error: EngineEarsError | undefined) => {
  if (error) {
    if ("code" in error && error.code === UNKNOWN_ERROR) {
      if (error.non_field_errors && error.non_field_errors.length > 0) {
        toast.error(error.non_field_errors[0]);
      }
    } else if (
      "code" in error &&
      error.code === TRANSITION_NOT_ALLOWED &&
      "detail" in error &&
      error.detail &&
      error.detail.length > 0
    ) {
      toast.error(error.detail);
    } else {
      if (exceptionCodeErrorStringMap.get(error.code)?.length !== 0) {
        toast.error(exceptionCodeErrorStringMap.get(error.code));
      }
    }
  }
};
