import { useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Chat } from "stream-chat-react";
import { connectionEstablishedAtom } from "../../../hooks/chatHooks/atoms";
import { streamApiKey } from "../../../store/utils";
import {
  OwnUserResponse,
  TokenOrProvider,
  UserResponse,
  StreamChat,
  DefaultGenerics,
} from "stream-chat";

export interface ChatInjectorParams {
  children?: React.ReactNode;
  token: TokenOrProvider;
  userData?: OwnUserResponse<DefaultGenerics> | UserResponse<DefaultGenerics>;
}
const ChatInjector = ({ children = null }: ChatInjectorParams) => {
  const [chatClient, setChatClient] = useState<null | StreamChat>(null);
  const setConnectionEstablished = useSetAtom(connectionEstablishedAtom);

  useEffect(() => {
    const streamChatClient = StreamChat.getInstance(streamApiKey, {
      timeout: 5000,
    });
    setChatClient(streamChatClient);
    setConnectionEstablished(true);
  }, []);

  if (!chatClient) {
    return <>{children}</>;
  }

  return (
    <Chat initialNavOpen={false} client={chatClient}>
      {children}
    </Chat>
  );
};

export default ChatInjector;
