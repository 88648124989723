import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { SCREENS } from "../../../routes";
import { loadEngineerServices } from "../../../store/actions/services";
import {
  createStripeCustomer,
  getStripeCheckoutSession,
} from "../../../store/actions/subscriptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BannerColor } from "../../../store/models/profile";
import { SUBSCRIPTION_PLAN } from "../../../store/models/subscription";
import { isEngineerVerifiedSelector } from "../../../store/selectors/userInfoSelectors";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  ENGINEER_SETUP_STEPS,
  EngineerSetupSteps,
} from "../../components/ProjectStepper/Steps";
import { SignInModal } from "../../components/SignInModal/SignInModal";
import { BannerImage } from "../../elements/BannerImage/BannerImage";
import { SelectSubPaymentPlanScreen } from "../SelectSubPaymentPlanScreen/SelectSubPaymentPlanScreen";
import { SelectSubscriptionScreen } from "../SelectSubscriptionScreen/SelectSubscriptionScreen";
import { SubscriptionSurveyScreen } from "../SubscriptionSurveyScreen/SubscriptionSurveyScreen";
import "./AddSubscriptionScreen.css";

const SUBSCRIPTION_SURVEY_STEP = 0;
const SELECT_SUBSCRIPTION_PACKAGE_STEP = 1;
const SELECT_PAYMENT_PLAN_STEP = 2;

export const AddSubscriptionScreen = () => {
  const { isAuthenticated } = useAppSelector((state) => state.accountInfo);

  const [currentStep, setCurrentStep] = useState(SUBSCRIPTION_SURVEY_STEP);
  const [numberOfSongs, setNumberOfSongs] = useState(10);
  const [averageProjectPrice, setAverageProjectPrice] = useState(150);
  const [selectedSubscription, setSelectedSubscription] =
    useState<SUBSCRIPTION_PLAN>(SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const engineer = useAppSelector((state) => state.accountInfo.user?.engineer);
  const loading = useAppSelector((state) => state.engineerServices.isLoading);
  const meetsStripeRequirements = useAppSelector(
    (state) => state.accountInfo.meetsStripeRequirements,
  );
  const isVerifiedEngineer = useAppSelector(isEngineerVerifiedSelector);

  useSetPageTitle(undefined);

  useEffect(() => {
    if (!engineer) return;
    dispatch(loadEngineerServices({ engineer_id: engineer?.id }));
  }, [dispatch, engineer]);

  useEffect(() => {
    if (!user) return;
    if (!engineer) return;
    dispatch(
      createStripeCustomer({
        billing_email: user.email,
        name: `${user.first_name} ${user.last_name}`,
      }),
    );
  }, [dispatch, user, engineer]);

  const subscriptionSurveyScreenProps = {
    numberOfSongs,
    setNumberOfSongs,
    averageProjectPrice,
    setAverageProjectPrice,
    onClickNextStep: () => setCurrentStep(SELECT_SUBSCRIPTION_PACKAGE_STEP),
  };

  const selectSubscriptionScreenProps = {
    numberOfSongs,
    averageProjectPrice,
    onClickPreviousStep: () => setCurrentStep(SUBSCRIPTION_SURVEY_STEP),
    onSubscriptionSelected: (selected: SUBSCRIPTION_PLAN) => {
      setSelectedSubscription(selected);
      if (selected !== SUBSCRIPTION_PLAN.BASIC_PLAN)
        setCurrentStep(SELECT_PAYMENT_PLAN_STEP);
    },
  };

  const selectSubPaymentPlanScreenProps = {
    selectedSubscription,
    setSelectedSubscription,
    onClickPreviousStep: () => setCurrentStep(SELECT_SUBSCRIPTION_PACKAGE_STEP),
    onGoToCheckout: () => {
      window.analytics.track(
        getDebugEventPrefix + "start_stripe_subscription_checkout",
        {
          user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
        },
      );
      dispatch(getStripeCheckoutSession({ plan: selectedSubscription }))
        .unwrap()
        .then((result) => {
          window.location.href = result.checkout_url;
        });
    },
  };
  const bannerColor = useAppSelector(
    (state) => state.accountInfo.user?.profile?.banner_color,
  );

  if (loading) {
    return <LoadingScreen />;
  }
  if (!isAuthenticated) {
    return <SignInModal label={"Sign In"} />;
  }
  if (!meetsStripeRequirements && !isVerifiedEngineer) {
    return <Redirect to={SCREENS.SETUP_STRIPE} />;
  }

  return (
    <>
      <BannerImage
        editMode={false}
        color={bannerColor ?? BannerColor.BOXY_YELLOW}
      />
      <div className="subscription-setup-container">
        <p className="mt-5 mb-3 label2">Steps to get started</p>
        <div className="setup-stepper-container">
          <EngineerSetupSteps step={ENGINEER_SETUP_STEPS.AddSubscription} />
        </div>
      </div>
      <div className="container-fluid add-subscription-container">
        <div className="container">
          <div className="row justify-content-md-center m-0">
            {
              [
                <SubscriptionSurveyScreen
                  {...subscriptionSurveyScreenProps}
                  key={0}
                />,
                <SelectSubscriptionScreen
                  {...selectSubscriptionScreenProps}
                  key={1}
                />,
                <SelectSubPaymentPlanScreen
                  {...selectSubPaymentPlanScreenProps}
                  key={2}
                />,
              ][currentStep]
            }
          </div>
        </div>
      </div>
    </>
  );
};
