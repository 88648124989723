import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Container, styled, Tab, Tabs } from "@mui/material";
import { ReactNode } from "react";
import { MUI_CONTAINER_NO_PADDING_CUT_OFF } from "../../../utils/breakpoints";

export const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    // To match exactly what the design looks like
    maxWidth: "var(--max-screen-width)",
  },

  [theme.breakpoints.up(MUI_CONTAINER_NO_PADDING_CUT_OFF)]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const EETab = styled(Tab)(({ theme }) => ({
  "&, p": {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.disabled,
    fontSize: theme.typography.pxToRem(16),
  },

  "&.Mui-selected": {
    "&,  p": {
      color: theme.palette.text.primary,
    },
  },
}));

export const EETabs = styled(Tabs, {
  shouldForwardProp: (props) => props !== "$isDisabled",
})<{ $isDisabled?: boolean }>(({ $isDisabled, theme }) => ({
  ".MuiTabs-indicator": {
    height: "4px",
    backgroundColor: $isDisabled
      ? theme.palette.customColor.disabledTextPrimaryColor
      : theme.palette.primary.main,
  },
}));

export const TabsBottomGreyBar = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "4px",
  background: theme.palette.customColor.darkerMidgroundColor,
}));

export const SidebarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
  padding: "32px 0",
  [theme.breakpoints.down("md")]: {
    paddingBottom: "8px",
    rowGap: "unset",
    "& > :first-child": {
      marginBottom: "24px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: "16px 0",
    "& > :first-child": {
      marginBottom: "15px",
    },
  },
}));

interface TabPanelProps {
  children: ReactNode;
  hidden?: boolean;
  index?: number | string;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, hidden = false, index = 0, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export const SuccessIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const TabTextContainer = styled(Box)({
  display: "flex",
  columnGap: "6px",
  alignItems: "center",
});

export const CenteredFlexContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});
