import soundwave from "../../assets/Soundwave_Small_1_artist.svg";
import { Button } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import "./PreSubscriptionView.css";

export interface PreSubscriptionViewProps {
  onClickAddService?: () => void;
}

export const PreSubscriptionView = (props: PreSubscriptionViewProps) => {
  return (
    <div className="pre-subscription-view-container">
      <img
        src={soundwave}
        height={50}
        width={100}
        className="pb-3"
        alt="soundwave"
      />
      <Text variant={TextStyleVariant.H4}>
        Get started on your first project for free*
      </Text>
      <Text variant={TextStyleVariant.H6}>
        *0% service fee on your first ten projects for{" "}
        <strong>Gold and Platinum members</strong>
      </Text>
      {props.onClickAddService && (
        <Button onClick={props.onClickAddService}>Add Service</Button>
      )}
    </div>
  );
};
