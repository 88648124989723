import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { downloadFilesById } from "../../../store/actions/projects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ProjectById,
  ProjectWorkflowSteps,
} from "../../../store/models/project";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { CheckboxGroup, Select } from "../../core-ui/components/Select/Select";
import { FileLinkTable } from "../FileLinkTable/FileLinkTable";
import { FileVersionTable } from "../FileVersionTable/FileVersionTable";
import { FileTableFooterContainer } from "./DownloadFilesView.styles";
import { useDisabledDownloads } from "./useDisabledDownloads";
import { useFilesToDownload } from "./useFilesToDownload";
import { isUserOnProject } from "../ScheduledProjectTable/utils";
import { useIsAdmin } from "../../../hooks/useIsAdmin";

export interface DownloadFilesViewProps {
  isEngineer: boolean;
  isArtist: boolean;
  handleDismissDownloadFiles: () => void;
  project: ProjectById;
  projectWorkflowStep: ProjectWorkflowSteps;
  disableDownloads?: boolean;
  code?: string;
}

enum FileDownloadCategory {
  Reference = "Working Files",
  Deliverables = "Deliverables",
}

export const DownloadFilesView = ({
  isEngineer,
  isArtist,
  project,
  projectWorkflowStep,
  handleDismissDownloadFiles,
  code,
  disableDownloads = false,
}: DownloadFilesViewProps) => {
  const isInProgress = projectWorkflowStep === ProjectWorkflowSteps.IN_MIX;
  // auto-select reference files for engineers and if the mix/master is in progress
  const [fileDownloadCategory, setFileDownloadCategory] =
    useState<FileDownloadCategory>(
      isEngineer || isInProgress
        ? FileDownloadCategory.Reference
        : FileDownloadCategory.Deliverables,
    );
  const [isLoading, setIsLoading] = useState(false);
  const disabledArtistDownloads = useDisabledDownloads(
    isArtist,
    projectWorkflowStep,
    fileDownloadCategory === FileDownloadCategory.Reference,
    project,
  );
  const { scheduledProject } = useAppSelector(
    (state) => state.scheduledProjectsStore,
  );
  const { user: currentUser } = useAppSelector((state) => state.accountInfo);
  const isProjectUser = isUserOnProject(scheduledProject, currentUser);
  const isAdmin = useIsAdmin(currentUser);
  const { activePaginatedScheduledProject } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );

  const isSecondaryEngOrAdmin =
    (code && !isProjectUser && isAdmin) ||
    (isProjectUser &&
      !activePaginatedScheduledProject?.user_is_primary_artist_or_engineer);

  const disableDownloading = disableDownloads || disabledArtistDownloads;

  const showReference = fileDownloadCategory == FileDownloadCategory.Reference;
  const filesToDownload = useFilesToDownload(project, showReference);
  const theme = useTheme();
  const downloadCategory = (
    <Select
      dropdownZIndex={theme.zIndexes.projectSidePanelContent}
      triggerLabel={`${fileDownloadCategory}`}
    >
      <CheckboxGroup
        options={[
          {
            label: FileDownloadCategory.Reference,
            value: FileDownloadCategory.Reference,
          },
          {
            label: FileDownloadCategory.Deliverables,
            value: FileDownloadCategory.Deliverables,
          },
        ]}
        multiple={false}
        selected={fileDownloadCategory}
        onSelect={(selected) => {
          if (!selected) return;
          setFileDownloadCategory(selected as FileDownloadCategory);
        }}
      />
    </Select>
  );

  const dispatch = useAppDispatch();

  const handleDownloadAllFiles = async () => {
    if (disableDownloading) return;
    if (!isEngineer && !isArtist && !isSecondaryEngOrAdmin) {
      toast.error("You do not have permission to download files");
      return;
    }
    setIsLoading(true);
    await dispatch(
      downloadFilesById({
        project_id: project.id,
        file_version_ids: filesToDownload.map((file) => file.id),
        code,
      }),
    )
      .unwrap()
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {downloadCategory}
      <FileLinkTable fileVersions={filesToDownload} />
      <FileVersionTable
        fileVersions={filesToDownload}
        code={code}
        project={project}
      />
      <FileTableFooterContainer>
        <Button
          variant={ButtonVariant.UNSTYLED}
          onClick={handleDismissDownloadFiles}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: "0.5rem" }}
          />
          Back
        </Button>
        <Button
          disabled={disableDownloading || isLoading}
          loading={isLoading}
          variant={ButtonVariant.PRIMARY}
          onClick={handleDownloadAllFiles}
          style={{ minWidth: "160px" }}
        >
          Download all files
        </Button>
      </FileTableFooterContainer>
    </>
  );
};
