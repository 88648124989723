import { MouseEventHandler, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ProjectType } from "../../../../store/models/project";
import { getProfileScreenRoute } from "../../../../store/utils/routeGetters";
import missingFileArt from "../../../assets/BlankAlbumArt.png";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import {
  StyledFooterProjectInfoContainer,
  StyledFooterProjectInfoImage,
  StyledFooterProjectInfoProjectDetails,
  StyledFooterProjectInfoSubContainer,
  StyledFooterProjectInfoTrackInfo,
  StyledFooterProjectInfoTrackTitle,
  StyledFooterProjectInfoUserInfo,
} from "./FooterProjectInfo.styles";

const EXPANDED_SIZE = 312;
export const COLLAPSED_SIZE = 54;

// It probably won't ever recording in the near future, but we should cover all the cases.
const projectTypeReadableRole = new Map<ProjectType, string>([
  [ProjectType.NO_TYPE, "Engineer"],
  [ProjectType.RECORDING, "Recording done by"],
  [ProjectType.MIXING, "mixed by"],
  [ProjectType.MASTERING, "mastered by"],
  [ProjectType.TWO_TRACK_MIXING, "mixed by"],
  [ProjectType.ATMOS_MIXING, "mixed by"],
]);

export interface StyledFooterProjectInfoProps {
  artworkSource?: string;
  artistUser?: string;
  engineerUser?: string;
  artistUsername?: string;
  engineerUsername?: string;
  trackTitle?: string;
  serviceType?: ProjectType;
  onClick?: MouseEventHandler<HTMLDivElement>;
  expandFooter?: boolean;
}

export const FooterProjectInfo = ({
  artworkSource,
  artistUser,
  engineerUser,
  artistUsername,
  engineerUsername,
  trackTitle = "",
  serviceType = ProjectType.NO_TYPE,
  onClick,
  expandFooter,
}: StyledFooterProjectInfoProps) => {
  const engineerProjectRole =
    projectTypeReadableRole.get(serviceType) || "Engineer";

  const stopBubble: MouseEventHandler<HTMLAnchorElement> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const artistUserHref = useMemo(() => {
    return artistUsername ? getProfileScreenRoute(artistUsername) : "#";
  }, [artistUsername]);

  const engineerUserHref = useMemo(() => {
    return engineerUsername ? getProfileScreenRoute(engineerUsername) : "#";
  }, [engineerUsername]);

  return (
    <StyledFooterProjectInfoContainer
      onClick={onClick}
      $expandFooter={expandFooter}
    >
      <StyledFooterProjectInfoImage
        height={expandFooter ? EXPANDED_SIZE : COLLAPSED_SIZE}
        width={expandFooter ? EXPANDED_SIZE : COLLAPSED_SIZE}
        src={artworkSource ?? missingFileArt}
      />
      <StyledFooterProjectInfoTrackInfo $expandFooter={expandFooter}>
        <StyledFooterProjectInfoTrackTitle
          variant={expandFooter ? TextStyleVariant.S2 : TextStyleVariant.P3}
          $expandFooter={expandFooter}
          title={trackTitle}
        >
          {trackTitle}
        </StyledFooterProjectInfoTrackTitle>
        <StyledFooterProjectInfoSubContainer>
          <StyledFooterProjectInfoProjectDetails>
            {!expandFooter && (
              <StyledFooterProjectInfoUserInfo variant={TextStyleVariant.P4}>
                artist
              </StyledFooterProjectInfoUserInfo>
            )}
            <Link to={artistUserHref} onClick={stopBubble}>
              <StyledFooterProjectInfoUserInfo
                $isUserInfo
                $expandFooter={expandFooter}
                variant={
                  expandFooter ? TextStyleVariant.P2 : TextStyleVariant.P4
                }
              >
                {artistUser}
              </StyledFooterProjectInfoUserInfo>
            </Link>
          </StyledFooterProjectInfoProjectDetails>
          {!expandFooter && (
            <StyledFooterProjectInfoProjectDetails>
              <StyledFooterProjectInfoUserInfo variant={TextStyleVariant.P4}>
                {engineerProjectRole}
              </StyledFooterProjectInfoUserInfo>
              <Link to={engineerUserHref} onClick={stopBubble}>
                <StyledFooterProjectInfoUserInfo
                  $isUserInfo
                  variant={TextStyleVariant.P4}
                >
                  {engineerUser}
                </StyledFooterProjectInfoUserInfo>
              </Link>
            </StyledFooterProjectInfoProjectDetails>
          )}
        </StyledFooterProjectInfoSubContainer>
      </StyledFooterProjectInfoTrackInfo>
    </StyledFooterProjectInfoContainer>
  );
};
