import { styled, Box } from "@mui/material";
import { Button } from "../../core-ui/components/Button/Button";

export const MusoCreditsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: theme.spacing(2),
  backgroundColor: "transparent",
}));

export const MusoCreditsMoreContainer = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  [theme.breakpoints.down("md")]: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export const MusoLinkButton = styled(Button)(({ theme }) => ({
  "&&": {
    backgroundColor: theme.palette.customColor.musoColor,
  },
}));

export const MusoCreditsEmptyContainer = styled(Box)(({ theme }) => ({
  height: "190px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: theme.spacing(4),
  backgroundColor: theme.palette.customColor.midgroundColor,
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    height: "140px",
    gap: theme.spacing(3),
  },
}));

export const MusoLoadingBox = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.customColor.midgroundColor,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.customColor.musoColor,
}));
