import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useQueryParamEntries } from "../../../hooks/useQueryParamEntries";
import {
  setCollaboratorFilter,
  setStudioFilter,
  setStudioRoomFilter,
} from "../../../store/actions/bookingsSearch";
import { fetchPendingProjectCounts } from "../../../store/actions/dashboard";
import { setSessionStages } from "../../../store/actions/paginatedRecordingSessions";
import {
  setSelectedServices,
  setStages,
  setTrackStages,
} from "../../../store/actions/paginatedScheduledProjects";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType, TrackStage } from "../../../store/models/project";
import {
  RecordingSessionStages,
  SESSION_STAGE_OPTIONS,
  SESSION_STAGE_REVERSE_OPTIONS,
} from "../../../store/models/recordingSession";
import { ScheduledProjectStage } from "../../../store/models/scheduledproject";
import { selectIsArtistPrimaryAccountType } from "../../../store/selectors/userInfoSelectors";
import { getMyBookingsRoute } from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { ApplyFiltersParams } from "../../components/ProjectFilters/SidePanelFilters/ProjectSidePanelFilters";

export enum BookingTabs {
  NotSet = -1,
  Projects,
  Sessions,
}

export enum SessionTabs {
  ListView,
  CalendarView,
}

/**
 * Query parameter keys for the booking screen.
 * @example ?services=1,2&stages=active,pending
 */
export enum BookingQueryParamKeys {
  Services = "services",
  Stages = "stages",
  TrackStages = "track_stages",
  User = "User",
  ScheduledProjectId = "scheduled_project_id",
  Studio = "Studio",
  StudioRoom = "StudioRoom",
  SessionStages = "session_stages",
}

/**
 * Human readable values for various query parameters.
 */
const SERVICES: Record<ProjectType, string> = {
  [ProjectType.NO_TYPE]: "No Type",
  [ProjectType.RECORDING]: "Recording",
  [ProjectType.MIXING]: "Mixing",
  [ProjectType.MASTERING]: "Mastering",
  [ProjectType.TWO_TRACK_MIXING]: "Mixing (2-track)",
  [ProjectType.ATMOS_MIXING]: "Dolby Atmos",
};

const STAGES: Record<ScheduledProjectStage, string> = {
  [ScheduledProjectStage.ALL]: "All",
  [ScheduledProjectStage.ACTIVE]: "Active",
  [ScheduledProjectStage.COMPLETED]: "Completed",
  [ScheduledProjectStage.PENDING]: "Pending",
  [ScheduledProjectStage.REFUNDED]: "Refunded",
};

const TRACK_STAGES: Record<TrackStage, string> = {
  [TrackStage.ALL]: "All",
  [TrackStage.FILE_TRANSFER]: "File Transfer",
  [TrackStage.IN_REVIEW]: "In Review",
  [TrackStage.IN_PROGRESS]: "In-progress",
  [TrackStage.REVISION_IN_PROGRESS]: "Revision in-progress",
  [TrackStage.FINAL_FILE_TRANSFER]: "Final file transfer",
  [TrackStage.REFUNDED]: "Refunded",
  [TrackStage.COMPLETED]: "Completed",
};

export interface TabViewProps {
  selectedTab: BookingTabs;
  sessionTab?: SessionTabs;
  setSessionTab?: (tab: SessionTabs) => void;
}

export const useBookingScreenTabs = () => {
  const history = useHistory();
  const { isAuthenticated, user } = useAppSelector(
    (state) => state.accountInfo,
  );
  const isArtistPrimaryAccountType = useAppSelector(
    selectIsArtistPrimaryAccountType,
  );
  const { recordingService } = useAppSelector(
    (state) => state.engineerServices,
  );
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const dispatch = useAppDispatch();
  const { tab: currentTab }: { tab: string | undefined } = useParams();
  const [tabs, setTabs] = useState<ReactNode[]>(
    selectedProfile.studio ? ["Sessions"] : ["Projects", "Sessions"],
  );
  const sessionStagesQuery = useQueryParam(BookingQueryParamKeys.SessionStages);
  const sessionStages = sessionStagesQuery.get();
  const [selectedSessionTab, setSelectedSessionTab] = useState<SessionTabs>(
    sessionStages == null && !isArtistPrimaryAccountType && recordingService
      ? SessionTabs.CalendarView
      : SessionTabs.ListView,
  );
  const [selectedTab, setSelectedTab] = useState<BookingTabs>(
    BookingTabs.NotSet,
  );

  const onTabClicked = useCallback(
    (clickedTab: BookingTabs) => {
      if (clickedTab === selectedTab) return;
      emitAnalyticsTrackingEvent(
        "my_bookings_tab_clicked",
        {
          tab: clickedTab === BookingTabs.Projects ? "Projects" : "Sessions",
        },
        user?.id,
      );
      if (clickedTab === BookingTabs.Projects) {
        history.push({
          ...history.location,
          pathname: getMyBookingsRoute("projects"),
        });
      }
      if (clickedTab === BookingTabs.Sessions) {
        history.push({
          ...history.location,
          pathname: getMyBookingsRoute("sessions"),
        });
      }
    },
    [history, user, selectedTab],
  );

  const pendingCounts = useAppSelector(
    (state) => state.dashboard.pendingProjects,
  );

  // if the user switches to a studio profile and they're on the
  // projects tab, switch over to the sessions tab
  useEffect(() => {
    if (selectedProfile.studio && selectedTab === BookingTabs.Projects) {
      onTabClicked(BookingTabs.Sessions);
    }
  }, [selectedProfile.studio, selectedTab]);

  useEffect(() => {
    const {
      pendingScheduledProjectCount,
      pendingRecordingSessionBookingCount,
    } = pendingCounts;

    const updatedTabs = [];
    if (!selectedProfile.studio) {
      updatedTabs.push(
        <>
          Projects
          {pendingScheduledProjectCount ? (
            <span>&nbsp;({pendingScheduledProjectCount})</span>
          ) : (
            ""
          )}
        </>,
      );
    }
    updatedTabs.push(
      <>
        Sessions
        {pendingRecordingSessionBookingCount ? (
          <span>&nbsp;({pendingRecordingSessionBookingCount})</span>
        ) : (
          ""
        )}
      </>,
    );

    setTabs(updatedTabs);
  }, [pendingCounts, selectedProfile.studio]);

  useEffect(() => {
    if (currentTab?.includes("projects")) {
      setSelectedTab(BookingTabs.Projects);
    } else if (currentTab?.includes("sessions")) {
      setSelectedTab(BookingTabs.Sessions);
    } else if (!currentTab) {
      // A default if no tab is selected
      setSelectedTab(BookingTabs.Projects);
    }
  }, [currentTab]);

  useEffect(() => {
    if (!isAuthenticated) return;
    void dispatch(fetchPendingProjectCounts());
  }, [isAuthenticated, dispatch]);

  return {
    tabs,
    onTabClicked,
    selectedTab,
    selectedSessionTab,
    setSelectedSessionTab,
    isOnlySessionsTabActive: Boolean(selectedProfile.studio),
  };
};

export const useProjectsSelectedServices = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const { tab: currentTab }: { tab: string | undefined } = useParams();
  const dispatch = useAppDispatch();
  const query = useQueryParam(BookingQueryParamKeys.Services);
  const servicesQuery = query.get();
  const { selectedServices } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );

  const handleSelectServices = useCallback(
    (services: ProjectType[]) => {
      if (!user) return;
      emitAnalyticsTrackingEvent(
        "selected_services",
        {
          services: services.join(","),
        },
        user.id,
      );
      if (services.length === 2 && services[0] === ProjectType.NO_TYPE) {
        const filteredServices = services.filter(
          (service) => service !== ProjectType.NO_TYPE,
        );
        query.set(filteredServices);
      } else if (
        services.length > 1 &&
        services[services.length - 1] === ProjectType.NO_TYPE
      ) {
        query.remove();
      } else if (services.length > 0) {
        query.set(services);
      } else {
        query.remove();
      }
    },
    [user, query],
  );

  useEffect(() => {
    if (currentTab !== "projects") return;
    if (!servicesQuery) {
      dispatch(setSelectedServices([ProjectType.NO_TYPE]));
    } else {
      if (servicesQuery.includes(",")) {
        const split = servicesQuery.split(",");
        const numbers = split.map((s) => parseInt(s, 10));
        const services = numbers.map((n) => +n as ProjectType);
        dispatch(setSelectedServices(services));
      } else {
        dispatch(
          setSelectedServices([+servicesQuery as unknown as ProjectType]),
        );
      }
    }
  }, [servicesQuery, dispatch, currentTab]);

  return { selectedServices, handleSelectServices };
};

export const useSessionStages = () => {
  const { tab: currentTab }: { tab: string | undefined } = useParams();
  const queryParam = useQueryParam(BookingQueryParamKeys.SessionStages);
  const dispatch = useAppDispatch();
  const sessionStages = useAppSelector(
    (state) => state.paginatedRecordingSessions.stages,
  );
  const handleSelectSessionStages = useCallback(
    (updatedStages: RecordingSessionStages[]) => {
      if (
        updatedStages.length === 2 &&
        updatedStages[0] === RecordingSessionStages.ALL
      ) {
        const filteredStages = updatedStages.filter(
          (stage) => stage !== RecordingSessionStages.ALL,
        );
        const recordingSessionStageStringValue = filteredStages.map(
          (sessionStage) => SESSION_STAGE_REVERSE_OPTIONS[sessionStage],
        );
        queryParam.set(recordingSessionStageStringValue);
      } else if (
        updatedStages.length > 1 &&
        updatedStages[updatedStages.length - 1] === RecordingSessionStages.ALL
      ) {
        queryParam.remove();
      } else if (updatedStages.length > 0) {
        const recordingSessionStageStringValue = updatedStages.map(
          (sessionStage) => SESSION_STAGE_REVERSE_OPTIONS[sessionStage],
        );
        queryParam.set(recordingSessionStageStringValue);
      } else {
        queryParam.remove();
      }
    },
    [queryParam],
  );

  useEffect(() => {
    const stagesQueryParam = queryParam.get();
    if (!stagesQueryParam) {
      dispatch(setSessionStages([RecordingSessionStages.ALL]));
      return;
    }
    const stagesFromQueryParam = stagesQueryParam.split(",");
    const convertStringValueToEnum = (stage: string) => {
      return SESSION_STAGE_OPTIONS.find((option) => option.label === stage)
        ?.value;
    };
    const stages = stagesFromQueryParam
      .map(convertStringValueToEnum)
      .filter((stage) => stage !== undefined) as RecordingSessionStages[];
    dispatch(setSessionStages(stages));
  }, [currentTab, dispatch, queryParam]);

  return { handleSelectSessionStages, sessionStages };
};

export const useScheduledProjectStages = () => {
  const { tab: currentTab }: { tab: string | undefined } = useParams();
  const dispatch = useAppDispatch();
  const { stages } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );
  const queryParam = useQueryParam(BookingQueryParamKeys.Stages);

  const handleSelectStages = useCallback(
    (updatedStages: ScheduledProjectStage[]) => {
      if (
        updatedStages.length === 2 &&
        updatedStages[0] === ScheduledProjectStage.ALL
      ) {
        const filteredStages = updatedStages.filter(
          (stage) => stage !== ScheduledProjectStage.ALL,
        );
        queryParam.set(filteredStages);
      } else if (
        updatedStages.length > 1 &&
        updatedStages[updatedStages.length - 1] === ScheduledProjectStage.ALL
      ) {
        queryParam.remove();
      } else if (updatedStages.length > 0) {
        queryParam.set(updatedStages);
      } else {
        queryParam.remove();
      }
    },
    [queryParam],
  );

  useEffect(() => {
    const stagesQueryParam = queryParam.get();
    if (!stagesQueryParam) {
      dispatch(setStages([ScheduledProjectStage.ALL]));
      return;
    }
    const stagesFromQueryParam = stagesQueryParam
      .split(",")
      .filter((stage) =>
        Object.values(ScheduledProjectStage).includes(
          stage as ScheduledProjectStage,
        ),
      );
    dispatch(setStages(stagesFromQueryParam as ScheduledProjectStage[]));
  }, [currentTab, dispatch, queryParam]);

  return { stages, handleSelectStages };
};

export const useTrackStages = () => {
  const { tab: currentTab }: { tab: string | undefined } = useParams();
  const dispatch = useAppDispatch();
  const { trackStages } = useAppSelector(
    (state) => state.paginatedScheduledProjects,
  );
  const queryParam = useQueryParam(BookingQueryParamKeys.TrackStages);

  const handleSelectTrackStages = useCallback(
    (updatedStages: TrackStage[]) => {
      if (updatedStages.length === 2 && updatedStages[0] === TrackStage.ALL) {
        const filteredStages = updatedStages.filter(
          (stage) => stage !== TrackStage.ALL,
        );
        queryParam.set(filteredStages);
      } else if (
        updatedStages.length > 1 &&
        updatedStages[updatedStages.length - 1] === TrackStage.ALL
      ) {
        queryParam.remove();
      } else if (updatedStages.length > 0) {
        queryParam.set(updatedStages);
      } else {
        queryParam.remove();
      }
    },
    [queryParam],
  );

  useEffect(() => {
    if (currentTab !== "projects") return;
    const trackStageQueryParam = queryParam.get();
    if (!trackStageQueryParam) {
      dispatch(setTrackStages([TrackStage.ALL]));
      return;
    }
    const trackStagesFromQueryParam = trackStageQueryParam
      .split(",")
      .filter((stage) =>
        Object.values(TrackStage).includes(stage as TrackStage),
      );
    dispatch(setTrackStages(trackStagesFromQueryParam as TrackStage[]));
  }, [dispatch, currentTab, queryParam]);

  return { trackStages, handleSelectTrackStages };
};

const useFilterEffect = (
  filterParam: {
    get: () => string | null;
  },
  dispatchFilterAction: ActionCreatorWithPayload<string[]>,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const filterParamValue = filterParam.get();
    if (!filterParamValue) {
      dispatch(dispatchFilterAction([]));
      return;
    }
    const filterValues = filterParamValue.includes(",")
      ? filterParamValue.split(",")
      : [filterParamValue];
    dispatch(dispatchFilterAction(filterValues));
  }, [filterParam, dispatch, dispatchFilterAction]);
};

export const useSearchFilters = () => {
  const collaboratorFilterParam = useQueryParam(BookingQueryParamKeys.User);
  const studioRoomFilterParam = useQueryParam(BookingQueryParamKeys.StudioRoom);
  const studioFilterParam = useQueryParam(BookingQueryParamKeys.Studio);
  useFilterEffect(studioRoomFilterParam, setStudioRoomFilter);
  useFilterEffect(studioFilterParam, setStudioFilter);
  useFilterEffect(collaboratorFilterParam, setCollaboratorFilter);
};

export const useScheduledProjectFilters = () => {
  const { selectedServices, handleSelectServices } =
    useProjectsSelectedServices();
  const { stages, handleSelectStages } = useScheduledProjectStages();
  const { trackStages, handleSelectTrackStages } = useTrackStages();
  const servicesQuery = useQueryParam(BookingQueryParamKeys.Services);
  const stagesQuery = useQueryParam(BookingQueryParamKeys.Stages);
  const trackStagesQuery = useQueryParam(BookingQueryParamKeys.TrackStages);
  const onApplyFilters = useCallback(
    (appliedFilters: ApplyFiltersParams) => {
      // Service Filters
      if (appliedFilters.services.length > 0) {
        servicesQuery.set(appliedFilters.services);
      } else {
        servicesQuery.remove();
      }

      // Project Status Filters
      if (appliedFilters.projectStages.length > 0) {
        stagesQuery.set(appliedFilters.projectStages);
      } else {
        stagesQuery.remove();
      }

      // Track Status Filters
      if (appliedFilters.trackStages.length > 0) {
        trackStagesQuery.set(appliedFilters.trackStages);
      } else {
        trackStagesQuery.remove();
      }
    },
    [servicesQuery, stagesQuery, trackStagesQuery],
  );

  return {
    selectedServices,
    handleSelectServices,
    stages,
    handleSelectStages,
    trackStages,
    handleSelectTrackStages,
    onApplyFilters,
  };
};

/**
 * Converts the query parameters to human-readable values for the booking screen.
 * @returns An array of key-value pairs with human-readable values.
 */
export const useBookingScreenURLParamsParser = () => {
  const queryParamEntries = useQueryParamEntries();

  // returns updated entries
  return useMemo(
    () =>
      queryParamEntries.map(([key, value]) => {
        switch (key) {
          case BookingQueryParamKeys.Services:
            return [key, SERVICES[parseInt(value) as ProjectType]];
          case BookingQueryParamKeys.Stages:
            return [key, STAGES[value as ScheduledProjectStage]];
          case BookingQueryParamKeys.TrackStages:
            return [key, TRACK_STAGES[value as TrackStage]];
          default:
            return [key, value];
        }
      }),
    [queryParamEntries],
  );
};

/**
 * Converts human-readable filter values into the format used for query parameters for the booking screen.
 */
export const useBookingScreenFiltersParser = () => {
  const { service, stages, trackStages } = useMemo(() => {
    const servicesParams: Record<string, string> = {};
    const stagesParams: Record<string, ScheduledProjectStage> = {};
    const trackStagesParams: Record<string, TrackStage> = {};
    Object.entries(SERVICES).map(
      ([key, value]) => (servicesParams[value] = key),
    );
    Object.entries(STAGES).map(
      ([key, value]) => (stagesParams[value] = key as ScheduledProjectStage),
    );
    Object.entries(TRACK_STAGES).map(
      ([key, value]) => (trackStagesParams[value] = key as TrackStage),
    );

    return {
      service: servicesParams,
      stages: stagesParams,
      trackStages: trackStagesParams,
    };
  }, []);

  return useCallback(
    (key: string, value: string) => {
      switch (key) {
        case BookingQueryParamKeys.Services:
          return service[value];
        case BookingQueryParamKeys.Stages:
          return stages[value];
        case BookingQueryParamKeys.TrackStages:
          return trackStages[value];
        default:
          return value;
      }
    },
    [service, stages, trackStages],
  );
};
