import basicTrophy from "../stories/assets/subscription_free.svg";
import goldTrophy from "../stories/assets/subscription_gold.svg";
import platinumTrophy from "../stories/assets/subscription_platinum.svg";
import diamondTrophy from "../stories/assets/subscription_diamond.svg";

import { useMemo } from "react";
import {
  Subscription,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../store/models/subscription";

export const useSubscriptionTrophy = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return basicTrophy;
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return goldTrophy;
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return platinumTrophy;
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return diamondTrophy;
      default:
        return basicTrophy;
    }
  }, [plan]);
};

export const useSubscriptionToDisplay = (subscription: Subscription) => {
  const basicSubscription: Subscription = {
    subscription_plan_choice: SUBSCRIPTION_PLAN.BASIC_PLAN,
    stripe_subscription_status: SUBSCRIPTION_STATUS.ACTIVE,
  };
  return useMemo(() => {
    switch (subscription.subscription_plan_choice) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return subscription;
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return subscription.stripe_subscription_status !==
          SUBSCRIPTION_STATUS.INACTIVE
          ? subscription
          : basicSubscription;
    }
  }, [subscription]);
};

export const useSubscriptionName = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return "Basic Membership";
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return "Gold Membership";
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return "Platinum Membership";
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return "Diamond Membership";
    }
  }, [plan]);
};

export const useEngineerSubscriptionBenefits = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return [
          "EngineEars automated workflows",
          "EngineEars virtual assistant",
          "3% fee for instant payments on label projects",
        ];
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return [
          "0% service fee for your first 10 songs",
          "iZotope MPS Pro Bundle ($499 value)",
          "Access to exclusive community events",
          "EngineEars School of Music, including Dolby ATMOS Certificate Course ($10,000+ value)",
        ];
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return [
          "Unlocked after you book 10 songs",
          "Everything in the Gold membership",
          "iZotope MPS Pro Universal + Melodyne ($999 value)",
          "Priority access to in-person education and community events",
        ];
    }
  }, [plan]);
};

export const useStudioSubscriptionBenefits = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return [
          "Reduced visibility in search",
          "Limited studio management features",
          "12% per session fee",
        ];
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return [
          "Automatic session scheduling, management, reminders, and payment splits",
          "One room supported",
          "One affiliated session engineer",
          "Community events, plug ins, education",
        ];
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return [
          "Automatic session scheduling, management, reminders, and payment splits",
          "One room supported",
          "Up to three affiliated session engineers",
          "Community events, plug ins, education",
        ];
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return [
          "Automatic session scheduling, management, reminders, and payment splits",
          "Unlimited rooms supported",
          "Unlimited affiliated session engineers",
          "Community events, plug ins, education",
        ];
    }
  }, [plan]);
};

export const useSubscriptionSubTypeBenefits = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return [
          "Monthly membership, renews automatically",
          "Unlimited free trial: you will not be charged until you complete your first project",
          "Cancel anytime",
        ];
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
        return [
          "1-Year membership, renews automatically",
          "Unlimited free trial: you will not be charged until you complete your first project",
          "Save 17% (two free months)",
        ];
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
        return [
          "Monthly membership, renews automatically",
          "Keep 100% of the money you make through EngineEars",
          "Cancel anytime",
        ];
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return [
          "1-Year membership, renews automatically",
          "Keep 100% of the money you make through EngineEars",
          "Save 17% (two free months)",
        ];
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return [
          "Annual commitment, billed quarterly",
          "Keep 100% of the money you make through EngineEars",
          "Affiliated engineers keep 100%",
        ];
    }
    return [];
  }, [plan]);
};

export const useStudioSubscriptionSubTypeBenefits = (
  plan: SUBSCRIPTION_PLAN,
) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return [
          "Monthly membership, renews automatically",
          "Unlimited free trial: you will not be charged until you complete your first session",
          "Cancel anytime",
        ];
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
        return [
          "1-Year membership, renews automatically",
          "Unlimited free trial: you will not be charged until you complete your first session",
          "Save 17% (two free months)",
        ];
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
        return [
          "Monthly membership, renews automatically",
          "Unlimited free trial: you will not be charged until you complete your first session",
          "Cancel anytime",
        ];
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return [
          "1-Year membership, renews automatically",
          "Unlimited free trial: you will not be charged until you complete your first session",
          "Save 17% (two free months)",
        ];
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return [
          "Annual commitment, billed quarterly",
          "Unlimited free trial: you will not be charged until you complete your first session",
          "Affiliated engineers keep 100%",
        ];
    }
    return [];
  }, [plan]);
};

export const useSubscriptionFeePercentage = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
        return 12;
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return 12;
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return 5;
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return 0;
    }
  }, [plan]);
};

export const useSubscriptionPrice = (plan: SUBSCRIPTION_PLAN) => {
  return useMemo(() => {
    switch (plan) {
      case SUBSCRIPTION_PLAN.NO_SUBSCRIPTION_PLAN:
        return "0.00";
      case SUBSCRIPTION_PLAN.BASIC_PLAN:
        return "0.00";
      case SUBSCRIPTION_PLAN.GOLD_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_YEARLY_PLAN:
      case SUBSCRIPTION_PLAN.GOLD_QUARTERLY_PLAN:
        return "29.99";
      case SUBSCRIPTION_PLAN.PLATINUM_PLAN:
      case SUBSCRIPTION_PLAN.PLATINUM_YEARLY_PLAN:
        return "69.99";
      case SUBSCRIPTION_PLAN.DIAMOND_PLAN:
        return "99.99";
    }
  }, [plan]);
};
