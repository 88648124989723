import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, SelectProps } from "@mui/material";
import { OptionType } from "../../../elements/DropDownSelector/DropdownSelector";

type OptionsDropdownProps<T> = SelectProps<T> & {
  placeholder?: string;
  options: OptionType<T>[];
};

export const OptionsDropdown = <T extends string | number>({
  placeholder = "Choose an option",
  options,
  ...restProps
}: OptionsDropdownProps<T>) => {
  return (
    <Select
      displayEmpty
      IconComponent={(iconProps) => (
        <FontAwesomeIcon icon={faChevronDown} {...iconProps} />
      )}
      MenuProps={{
        PaperProps: {
          sx: (theme) => {
            return {
              border: `1px solid ${theme.palette.text.primary}`,
              marginTop: "8px",
              padding: "8px",
              boxSizing: "border-box",
              ".MuiMenuItem-root.Mui-selected": {
                backgroundColor: theme.palette.customColor.midgroundColor,

                "&:hover": {
                  backgroundColor: theme.palette.customColor.midgroundColor,
                },
              },

              ".MuiList-root.MuiMenu-list": {
                paddingTop: 0,
                paddingBottom: 0,
              },

              ".MuiMenuItem-root": {
                padding: "16px",
                color: theme.palette.text.secondary,
              },
            };
          },
        },
      }}
      sx={(theme) => ({
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.text.primary,
            borderWidth: "1px",
          },

        "&.MuiOutlinedInput-root .MuiOutlinedInput-input": {
          padding: "14px 16px",
          minHeight: "unset",
          lineHeight: 1,
        },

        "& .MuiSelect-icon": {
          right: "16px",
          color: theme.palette.text.primary,
        },
      })}
      {...restProps}
    >
      <MenuItem disabled value={undefined}>
        {placeholder}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
