import { useMemo } from "react";
import { useAppSelector } from "../../store/hooks";
import { RecordingService } from "../../store/models/recording";
import Service from "../../store/models/service";
import { useIsAandR } from "../useIsAandR";

export const useGetServiceOriginalRate = (
  service?: RecordingService | Service,
) => {
  const { user } = useAppSelector((state) => state.accountInfo);
  const isAandR = useIsAandR(user);

  const isLabelPriceApplied = useMemo(() => {
    return Boolean(isAandR && service?.service_rate?.label_price);
  }, [isAandR, service?.service_rate?.label_price]);

  const originalRate = useMemo(() => {
    const price = isLabelPriceApplied
      ? service?.service_rate?.label_price
      : service?.service_rate?.price;

    return price || 0;
  }, [
    isLabelPriceApplied,
    service?.service_rate?.label_price,
    service?.service_rate?.price,
  ]);

  return { originalRate, isLabelPriceApplied };
};
