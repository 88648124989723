import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useUploadedFileSinceLastTransition } from "../../../../hooks/useProjectFilesUploaded";
import {
  engMasteringTransition,
  engMixTransition,
} from "../../../../store/actions/projects";
import { useAppDispatch } from "../../../../store/hooks";
import {
  MasteringTransitions,
  MixingTransitions,
  ProjectById,
  ProjectType,
  projectTypeReadableFileName,
} from "../../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import {
  CONTAINER_NAME,
  usePopoverContainerContext,
} from "../../../core-ui/components/BasePopover/PopoverContainerContext";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { PopConfirm } from "../../../core-ui/components/PopConfirm/PopConfirm";
import { Text } from "../../../core-ui/components/Text/Text";
import { InProgressButtonRow } from "../../InProgressTransitionView/InProgressTransitionView.styles";
import { MainProjectWorkflowButtonRow } from "../../MainProjectWorkflowPanel/MainProjectWorkflowButtonRow";
import { CompleteTransitionViewIndex } from "../CompleteTransitionView";
import { isAltUploadRequired } from "../utils/isAltUploadRequired";

interface CompleteEngineerButtonRowProps {
  isDeliverablesUploaded: boolean;
  project: ProjectById;
  setViewIndex: Dispatch<SetStateAction<CompleteTransitionViewIndex>>;
}

export const CompleteEngineerButtonRow = ({
  isDeliverablesUploaded,
  project,
  setViewIndex,
}: CompleteEngineerButtonRowProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const uploadedFiles = useUploadedFileSinceLastTransition(project);
  const projectId = project.id.toString();
  const { containerElement } = usePopoverContainerContext(
    CONTAINER_NAME.SIDE_PANEL,
  );
  const projectType = project.service_type;
  const readableProjectType = projectTypeReadableFileName.get(projectType);
  const fileTypeText = projectType === ProjectType.MASTERING ? "alts" : "stems";
  const altUploadIsRequired = isAltUploadRequired(uploadedFiles, project);

  const uploadEngineerMix = useCallback(() => {
    emitAnalyticsTrackingEvent("engineer_upload_final_stems", {
      project_id: projectId,
    });
    setLoading(true);
    if (projectType === ProjectType.MASTERING) {
      void dispatch(
        engMasteringTransition({
          project_id: projectId,
          transition: MasteringTransitions.ENG_UPLOADING_ALTS_TRANSITION,
        }),
      )
        .unwrap()
        .then(() =>
          toast.success("Your files have been uploaded successfully!"),
        )
        .catch(() => {})
        .finally(() => setLoading(false));
    } else {
      void dispatch(
        engMixTransition({
          project_id: projectId,
          transition: MixingTransitions.ENGINEER_UPLOAD_STEMS,
        }),
      )
        .unwrap()
        .then(() =>
          toast.success("Your files have been uploaded successfully!"),
        )
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [dispatch, projectId, projectType]);

  return (
    <MainProjectWorkflowButtonRow>
      <InProgressButtonRow>
        <Button
          fullWidth
          onClick={() =>
            setViewIndex(CompleteTransitionViewIndex.DOWNLOAD_FILES)
          }
          variant={
            isDeliverablesUploaded
              ? ButtonVariant.PRIMARY
              : ButtonVariant.OUTLINED
          }
        >
          Download files
        </Button>
        {!isDeliverablesUploaded && (
          <PopConfirm
            side="top"
            title="Submit final stems"
            description={
              <Text style={{ maxWidth: "550px" }}>
                Submitting your {fileTypeText} will mark this project as
                complete. Make sure you&apos;ve uploaded all the files used in
                your {readableProjectType} for your artist.
              </Text>
            }
            onConfirm={uploadEngineerMix}
            okButtonProps={{
              loading,
              disabled: loading,
            }}
            wrapperElement={containerElement}
          >
            <Button
              disabled={altUploadIsRequired || loading}
              fullWidth
              loading={loading}
              variant={ButtonVariant.PRIMARY}
            >
              Submit final deliverables
            </Button>
          </PopConfirm>
        )}
      </InProgressButtonRow>
    </MainProjectWorkflowButtonRow>
  );
};
