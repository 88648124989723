import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { getSubscriptionStatus } from "../store/actions/subscriptions";
import { useAppDispatch } from "../store/hooks";
import User from "../store/models/user";
import { useIsVisitingOwnProfile } from "./profileScreenHooks/useIsVisitingOwnProfile";
interface useGetSubscriptionStatusProps {
  user?: User;
  refetchSubsStatus?: boolean;
}

export const useGetSubscriptionStatus = ({
  user,
  refetchSubsStatus = false,
}: useGetSubscriptionStatusProps) => {
  const dispatch = useAppDispatch();
  const isVisitingOwnProfile = useIsVisitingOwnProfile();

  return useQuery({
    queryKey: [QUERY_KEYS.GET_SUBSCRIPTION_STATUS, user?.id],
    queryFn: async () => {
      const result = await dispatch(
        getSubscriptionStatus({ refetch_subs_status: refetchSubsStatus }),
      ).unwrap();
      return result;
    },
    enabled: Boolean(user && isVisitingOwnProfile),
  });
};
