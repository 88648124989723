import { Box, styled } from "@mui/material";
import { useGetItemizedTransaction } from "../../../hooks/scheduledProjectHooks/useGetItemizedTransaction";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { Text } from "../../core-ui/components/Text/Text";

interface ProjectPurchaseOrdersProps {
  scheduledProjectId: number;
}

export const ProjectPurchaseOrders = ({
  scheduledProjectId,
}: ProjectPurchaseOrdersProps) => {
  const { data: itemizedTransaction } = useGetItemizedTransaction({
    getPurchaseOrder: true,
    transactionCode: "",
    scheduledProjectId,
  });

  const purchaseOrders = itemizedTransaction?.purchase_orders ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        marginTop: 2,
      }}
    >
      {purchaseOrders.map(
        ({
          id,
          cost_center,
          eventually_owed_to,
          general_ledger,
          order_number,
          total,
          work_breakdown_structure,
        }) => {
          const hasBillingInfo = Boolean(
            cost_center || work_breakdown_structure || general_ledger,
          );
          return (
            <Box
              key={id}
              sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.border.radius.md,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
              })}
            >
              <PurchaseOrderRow sx={{ marginBottom: 1 }}>
                <Text bold>
                  {eventually_owed_to || "Pending Purchase Order"}:
                </Text>
                <Text bold>{PennyDollarFormatter().format(total)}</Text>
              </PurchaseOrderRow>
              {order_number && (
                <PurchaseOrderRow>
                  <Text>Purchase Order:</Text>
                  <Text bold>{order_number}</Text>
                </PurchaseOrderRow>
              )}
              {hasBillingInfo && (
                <>
                  <PurchaseOrderRow>
                    <Text>Cost Center:</Text>
                    <Text bold>{cost_center || "N/A"}</Text>
                  </PurchaseOrderRow>
                  <PurchaseOrderRow>
                    <Text>Work Breakdown Structure:</Text>
                    <Text bold>{work_breakdown_structure || "N/A"}</Text>
                  </PurchaseOrderRow>
                  <PurchaseOrderRow>
                    <Text>General Ledger:</Text>
                    <Text bold>{general_ledger || "N/A"}</Text>
                  </PurchaseOrderRow>
                </>
              )}
              {!hasBillingInfo && !order_number && (
                <Text>No purchase order or billing information submitted</Text>
              )}
            </Box>
          );
        },
      )}
    </Box>
  );
};

const PurchaseOrderRow = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 1,
}));
