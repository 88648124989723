import { useMemo } from "react";
import {
  useFetchReleaseDetails,
  useFetchReleaseTracks,
} from "../../../hooks/releaseAPIhooks";
import { Release } from "../../../store/models/release";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  ReleaseItemDetailsContainer,
  ReleaseListItemContainer,
  ReleaseListContainerStyle,
} from "./ReleaseList.styles";
import { ReleaseListItemCoverPhoto } from "./ReleaseListItemCoverPhoto";
import { useTheme, Box, Skeleton } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { ReleasePlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";

interface ReleaseListItemProps {
  release: Release;
  onReleaseClick: (releaseId?: number) => void;
}

const ReleaseListItem = ({ release, onReleaseClick }: ReleaseListItemProps) => {
  const theme = useTheme();
  const { data, isPending } = useFetchReleaseTracks(release.id);
  const { data: releaseDetails, isLoading: isReleaseDetailsInitialLoading } =
    useFetchReleaseDetails(release?.id);
  const { currentTrackIndex, playlistId } = useAppSelector(
    (state) => state.abPlayerStore,
  );

  const songIndex = useMemo(() => {
    if (playlistId === release.id) {
      return currentTrackIndex;
    }
    return 0;
  }, [playlistId, currentTrackIndex, release.id]);

  const selectedTrack = data[songIndex];

  return (
    <ReleaseListItemContainer
      onClick={() => {
        onReleaseClick(release.id);
      }}
    >
      <ReleaseListItemCoverPhoto releaseCover={release.release_cover}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReleasePlayPauseButton releaseId={release.id} />
        </Box>
      </ReleaseListItemCoverPhoto>
      <ReleaseItemDetailsContainer>
        <Text bold variant={TextStyleVariant.P2}>
          {release.title}
        </Text>
        {isPending ? (
          <Skeleton height={5} width={100} />
        ) : (
          <Text variant={TextStyleVariant.P3}>{selectedTrack?.title}</Text>
        )}
        {isReleaseDetailsInitialLoading ? (
          <Skeleton style={{ marginTop: 8 }} height={5} width={100} />
        ) : (
          <Text
            style={{
              color: theme.palette.secondary.light,
              marginTop: 8,
            }}
            variant={TextStyleVariant.P4}
          >{`${releaseDetails?.total_play} plays`}</Text>
        )}
      </ReleaseItemDetailsContainer>
    </ReleaseListItemContainer>
  );
};

const LoadingReleaseListItem = () => {
  return (
    <ReleaseListItemContainer>
      <Skeleton variant={"rounded"} width={"64px"} height={"64px"} />
      <ReleaseItemDetailsContainer>
        <Skeleton height={10} width={200} />
        <Skeleton height={5} width={200} />
      </ReleaseItemDetailsContainer>
    </ReleaseListItemContainer>
  );
};

const ReleaseListItemSkeleton = () => {
  return (
    <ReleaseListContainerStyle>
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
      <LoadingReleaseListItem />
    </ReleaseListContainerStyle>
  );
};

interface ReleaseListProps {
  releases: Release[];
  isPending: boolean;
  onReleaseClick: (releaseId?: number) => void;
}

export const ReleaseList = ({
  releases,
  isPending,
  onReleaseClick,
}: ReleaseListProps) => {
  if (isPending) {
    return <ReleaseListItemSkeleton />;
  }
  return (
    <ReleaseListContainerStyle>
      {releases.map((release) => (
        <ReleaseListItem
          key={release.id}
          release={release}
          onReleaseClick={onReleaseClick}
        />
      ))}
    </ReleaseListContainerStyle>
  );
};
