import { Box } from "@mui/material";
import { ReleaseType, ReleaseTypeOptions } from "../../../store/models/release";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import { Text } from "../../core-ui/components/Text/Text";
import { format } from "date-fns";
import { ReleaseTypeLabel } from "../ArtistReleases/ArtistReleases.styles";
import { MadeOnEngineers } from "./MadeOnEngineers";
import { FeatureReleasePill } from "./FeaturedReleasePill";
import { ReleaseTabViewSectionProps } from "../ArtistReleaseTabView/ArtistReleaseTabView";

interface FeaturedAristReleaseHeaderProps extends ReleaseTabViewSectionProps {
  title: string;
  artistUsername: string;
  artistDisplayName: string;
  releaseType: ReleaseType;
  createdAt: string;
}

const textStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
};

export const FeaturedAristReleaseHeader = ({
  title,
  artistUsername,
  artistDisplayName,
  releaseType,
  createdAt,
  shouldShowDesktop,
}: FeaturedAristReleaseHeaderProps) => {
  const year = format(new Date(createdAt), "yyyy");
  const releaseOption = ReleaseTypeOptions.find(
    (option) => option.value === releaseType,
  );
  const releaseText = releaseOption?.label || "";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: shouldShowDesktop ? "row" : "column-reverse",
        gap: "12px",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Text variant={TextStyleVariant.S3} bold>
          {title}
        </Text>
        <Box sx={textStyle}>
          {artistDisplayName !== `@${artistUsername}` && (
            <Text
              style={{
                textTransform: "capitalize",
              }}
              variant={TextStyleVariant.P1}
            >
              {artistDisplayName}
            </Text>
          )}
          <Text variant={TextStyleVariant.P1} bold>{`@${artistUsername}`}</Text>
        </Box>
        <Box sx={textStyle}>
          <Text variant={TextStyleVariant.P1}>{year}</Text>
          <ReleaseTypeLabel>
            <Text color={TextColor.SECONDARY} variant={TextStyleVariant.P2}>
              {releaseText}
            </Text>
          </ReleaseTypeLabel>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <MadeOnEngineers />
        <FeatureReleasePill />
      </Box>
    </Box>
  );
};
