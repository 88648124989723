import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResponsiveLine } from "@nivo/line";
import classNames from "classnames";
import { endOfDay, startOfDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  ChartData,
  DataPoint,
  FetchChartDataResponse,
  fetchChartData,
  fetchChartDataParams,
} from "../../../store/actions/dashboard";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ProjectType } from "../../../store/models/project";
import { Studio } from "../../../store/models/studio";
import { convertLocalDateToUTCDate } from "../../../store/utils/dateTimeUtils";
import { PennyDollarFormatter } from "../../../store/utils/formatUtils";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import BlurredContent from "../../core-ui/components/BlurredContent/BlurredContent";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  DropdownSelector,
  OptionType,
} from "../../elements/DropDownSelector/DropdownSelector";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { TrendingPercentage } from "../../elements/TrendingPercentageText/TrendingPercentageText";
import { AddAStudioModal } from "../AddAStudioModal/AddAStudioModal";
import { DashboardCard } from "../Dashboard/DashboardCard/DashboardCard";
import "./PerformanceChart.css";

export interface NonOverflowTooltipWrapperProps {
  point: { x: number; y: number };
  children: React.ReactNode;
}

const NonOverflowTooltipWrapper = (props: NonOverflowTooltipWrapperProps) => {
  const [tooltipSize, setTooltipSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  // dynamically get the size of the tooltip
  useEffect(() => {
    const tooltip = document.querySelector(".nivo_tooltip");
    if (tooltip) {
      const { width, height } = tooltip.getBoundingClientRect();
      setTooltipSize({ width, height });
    }
  }, [setTooltipSize]);

  // only show it to the right of the pointer when we are close to the left edge
  const translateX = useMemo(
    () =>
      props.point.x < (tooltipSize.width * 1.3) / 2 ? 0 : -tooltipSize.width,
    [tooltipSize, props.point.x],
  );

  // only show it below the pointer when we are close to the top edge
  const translateY = useMemo(
    () =>
      props.point.y < (tooltipSize.height * 1.3) / 2 ? 0 : -tooltipSize.height,
    [tooltipSize, props.point.y],
  );

  return (
    <div
      className={"nivo_tooltip"}
      style={{
        position: "absolute",
        transform: `translate(${translateX}px, ${translateY}px)`,
        background: "var(--background-color)",
        padding: "12px 16px",
        width: "fit-content",
      }}
    >
      <div style={{ position: "relative", whiteSpace: "nowrap" }}>
        {props.children}
      </div>
    </div>
  );
};

function LineChart({
  data,
  dataFilter,
  dateRangeFilter,
  dateFormat,
  isEngineerDashboard,
}: {
  data: FetchChartDataResponse;
  dataFilter: ChartDataFilter;
  dateRangeFilter: DateRanges;
  dateFormat: string;
  isEngineerDashboard?: boolean;
}) {
  // Set up margin values depending on user type
  // For some reason it changes depending on the user type
  const margin = {
    top: 5,
    right: 33,
    left: dateRangeFilter === DateRanges.LAST_7_DAYS ? 55 : 38,
    bottom: isEngineerDashboard ? 30 : 85,
  };

  const useUTC = dateRangeFilter === DateRanges.TODAY;

  const emptyStateText = (
    <div
      style={{
        color: "var(--text-secondary-color)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <p>Nothing in this date range</p>
    </div>
  );

  const onlyZero = data.chartData.every((series) =>
    series.data.every((point) => point.y === 0),
  );

  let lastDisplayedMonth: number | null = null;
  let lastDisplayedYear: number | null = null;

  const formatTick = (value: string) => {
    const date = new Date(value);
    const isLargeTimePeriod = timePeriodLength >= 365;
    let options: Intl.DateTimeFormatOptions;

    if (dateRangeFilter === DateRanges.TODAY) {
      options = { hour: "numeric", hour12: true };
      return date.toLocaleTimeString("en-US", options);
    } else if (isLargeTimePeriod) {
      options = { month: "short", year: "numeric" };
    } else {
      options = { month: "short", day: "2-digit", year: "numeric" };
    }

    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();

    if (
      isLargeTimePeriod &&
      month === lastDisplayedMonth &&
      year === lastDisplayedYear
    ) {
      return "";
    }

    lastDisplayedMonth = month;
    lastDisplayedYear = year;

    return date.toLocaleDateString("en-US", options);
  };

  if (!data?.chartData[0]?.data[0]) {
    return emptyStateText;
  }
  const minDate = new Date(data.chartData[0].data[0].x);
  const originalEndDate = new Date(
    data.chartData[0].data[data.chartData[0].data.length - 1].x,
  );
  const maxDate = convertLocalDateToUTCDate(originalEndDate);
  const diffTime = Math.abs(maxDate.getTime() - minDate.getTime());
  const interval = diffTime / 4;
  const tickValues: Date[] = [minDate];
  for (let i = 1; i < 4; i++) {
    tickValues.push(new Date(minDate.getTime() + interval * i));
  }
  tickValues.push(maxDate);
  const timePeriodLength = diffTime / (1000 * 60 * 60 * 24);

  return (
    <ResponsiveLine
      data={data.chartData}
      margin={margin}
      xScale={{
        type: "time",
        format: dateFormat,
        precision: "hour",
        min: "auto",
        max: "auto",
        useUTC: useUTC,
      }}
      yScale={{
        type: "linear",
        min: 0,
        max: onlyZero ? 100 : "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="linear"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 10,
        tickValues: tickValues,
        format: formatTick,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 2,
        tickValues: 5,
        format: (d: number): string => {
          if (d >= 1e12) {
            return `${d / 1e12}T`;
          } else if (d >= 1e9) {
            return `${d / 1e9}B`;
          } else if (d >= 1e6) {
            return `${d / 1e6}M`;
          } else if (d >= 1e3) {
            return `${d / 1e3}K`;
          } else {
            return d.toString();
          }
        },
      }}
      enableGridX={true}
      enableGridY={false}
      colors={(chartData: ChartData) => {
        return getColorFromChartData(chartData);
      }}
      lineWidth={2}
      enablePoints={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={6}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
      tooltip={({ point }) => {
        // Create a new Date object from the x value
        const date = new Date(point.data.x);

        // Check if the time period is large (one month or more)
        const isLargeTimePeriod = timePeriodLength >= 365;

        // Format the date as a string without timezone information
        let options: Intl.DateTimeFormatOptions;

        if (dateRangeFilter === DateRanges.TODAY) {
          options = { hour: "numeric", hour12: true };
        } else if (isLargeTimePeriod) {
          options = { month: "short", year: "numeric" };
        } else {
          options = { month: "short", day: "2-digit", year: "numeric" };
        }

        const formattedDate = date.toLocaleDateString("en-US", options);

        return (
          <NonOverflowTooltipWrapper point={point}>
            <strong>{formattedDate}</strong>
            <br />
            <strong>
              {dataFilter === 0
                ? `$${point.data.yFormatted}`
                : Math.floor(Number(point.data.yFormatted))}
            </strong>
          </NonOverflowTooltipWrapper>
        );
      }}
    />
  );
}

const INITIAL_CHART_DATA: FetchChartDataResponse = {
  chartData: [],
  currentDate: "",
  dateFormat: "",
  grandTotal: 0,
  previousDateRangeData: 0,
  previousStartDate: "",
  previousEndDate: "",
  grossVolumePercentage: 0,
  sessionsBookedPercentage: 0,
  projectsBookedPercentage: 0,
  profileVisitsPercentage: 0,
};

export const useFetchChartData = ({
  studio_ids,
  data_filter,
  start_date,
  end_date,
  date_range_filter,
  isUserEngineer,
  filter_by,
  userStudios,
}: fetchChartDataParams) => {
  const dispatch = useAppDispatch();
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(false);
  const [chartData, setChartData] =
    useState<FetchChartDataResponse>(INITIAL_CHART_DATA);

  useEffect(() => {
    if (chartDataLoading) {
      return;
    }

    if (!isUserEngineer && studio_ids !== undefined && !studio_ids?.length) {
      return;
    }

    setChartDataLoading(true);
    if (isUserEngineer) {
      void dispatch(
        fetchChartData({
          studio_ids:
            studio_ids?.length === 0
              ? userStudios?.map((userStudio) => userStudio.id)
              : studio_ids,
          data_filter,
          start_date,
          end_date,
          date_range_filter,
          isUserEngineer,
          filter_by:
            filter_by?.some(
              (serviceFilter) => ProjectType.NO_TYPE === serviceFilter,
            ) && data_filter !== 0
              ? [
                  ProjectType.MIXING,
                  ProjectType.TWO_TRACK_MIXING,
                  ProjectType.ATMOS_MIXING,
                  ProjectType.MASTERING,
                  ProjectType.RECORDING,
                ]
              : filter_by,
        }),
      )
        .unwrap()
        .then((response) => setChartData(response))
        .finally(() => setChartDataLoading(false));
    } else {
      void dispatch(
        fetchChartData({
          studio_ids,
          data_filter,
          start_date,
          end_date,
          date_range_filter,
          isUserEngineer,
        }),
      )
        .unwrap()
        .then((response) => {
          response.chartData.forEach((chartData) => {
            const datedData = chartData.data.map((datedDate) => {
              return {
                x: convertLocalDateToUTCDate(
                  new Date(datedDate.x),
                ).toISOString(),
                y: datedDate.y,
              } as DataPoint;
            });
            chartData.data = datedData;
          });
          setChartData(response);
        })
        .finally(() => setChartDataLoading(false));
    }
  }, [dispatch, data_filter, start_date, end_date, studio_ids, filter_by]);

  return { chartDataLoading, chartData, setChartData };
};

export enum DateRanges {
  NOT_SET = -1,
  ALL_TIME,
  YEAR_TO_DATE,
  QUARTER_TO_DATE,
  MONTH_TO_DATE,
  LAST_12_MONTHS,
  LAST_3_MONTHS,
  LAST_30_DAYS,
  LAST_7_DAYS,
  TODAY,
  CUSTOM,
}

export enum ChartDataFilter {
  GROSS_VOLUME,
  SESSIONS_BOOKED,
  PROJECTS_BOOKED,
  PROFILE_VISITS,
}

// Profile Visits is not yet implemented so leaving out of selectable options in dropdown
const ChartDataFilterOptions: OptionType[] = [
  { value: ChartDataFilter.GROSS_VOLUME, label: "Gross Volume" },
  { value: ChartDataFilter.SESSIONS_BOOKED, label: "Sessions Booked" },
  { value: ChartDataFilter.PROJECTS_BOOKED, label: "Projects Booked" },
  { value: ChartDataFilter.PROFILE_VISITS, label: "Profile Visits" },
];

interface PerformanceChartProps {
  onClick: () => void;
  onClickViewTransactions: () => void;
  onSidePanel?: boolean;
  isStudio: boolean;
  startDate: Date;
  endDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const dateRangeFiltersOptions: OptionType[] = [
  { value: DateRanges.ALL_TIME, label: "All" },
  { value: DateRanges.LAST_7_DAYS, label: "Last 7 days" },
  { value: DateRanges.LAST_30_DAYS, label: "Last 30 days" },
  { value: DateRanges.LAST_3_MONTHS, label: "Last 3 months" },
  { value: DateRanges.LAST_12_MONTHS, label: "Last 12 months" },
  { value: DateRanges.TODAY, label: "Today" },
  { value: DateRanges.MONTH_TO_DATE, label: "MTD" },
  { value: DateRanges.QUARTER_TO_DATE, label: "QTD" },
  { value: DateRanges.YEAR_TO_DATE, label: "YTD" },
  { value: DateRanges.CUSTOM, label: "Custom" },
];

const dateRangeFiltersOptionsForDesktop: OptionType[] = [
  { value: DateRanges.LAST_7_DAYS, label: "Last 7 days" },
  { value: DateRanges.LAST_30_DAYS, label: "Last 30 days" },
  { value: DateRanges.LAST_3_MONTHS, label: "Last 3 months" },
  { value: DateRanges.LAST_12_MONTHS, label: "Last 12 months" },
  { value: DateRanges.TODAY, label: "Today" },
  { value: DateRanges.MONTH_TO_DATE, label: "MTD" },
  { value: DateRanges.QUARTER_TO_DATE, label: "QTD" },
  { value: DateRanges.YEAR_TO_DATE, label: "YTD" },
  { value: DateRanges.CUSTOM, label: "Custom" },
];

// Set up color scheme to use for studio selection and chart
const COLOR_SCHEME: string[] = [
  "#08306b",
  "#08519c",
  "#2171b5",
  "#4292c6",
  "#6baed6",
  "#9ecae1",
  "#c6dbef",
  "#deebf7",
  "#f7fbff",
];

const getColorFromChartData = (chartData?: ChartData): string => {
  if (!chartData) return "grey";
  if (chartData.color) return chartData.color;
  const idParts = chartData.id.split("_");

  if (idParts.length < 2) {
    return COLOR_SCHEME[0];
  }

  const index = idParts[1];
  if (index && !isNaN(Number(index))) {
    const colorIndex = Number(index);
    if (colorIndex > COLOR_SCHEME.length) {
      return COLOR_SCHEME[colorIndex % COLOR_SCHEME.length];
    }
    return COLOR_SCHEME[colorIndex];
  }
  return COLOR_SCHEME[0];
};

const PerformanceChart = ({
  onClick: onClick,
  onClickViewTransactions: onClickViewTransactions,
  onSidePanel = false,
  isStudio,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: PerformanceChartProps) => {
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const { isDesktop, isMobile } = useMediaQueryBreakpoint();

  // Studio Info
  const { userStudios, userStudiosLoading } = useAppSelector(
    (state) => state.accountInfo,
  );
  const [selectedStudio, setSelectedStudio] = useState<Studio | null>(null);
  const [studioIds, setStudioIds] = useState<number[]>([]);
  const [showAddStudioModal, setShowAddStudioModal] = useState<boolean>(false);
  const loggedInUser = useAppSelector((state) => state.accountInfo.user);
  // Date Ranges
  const [selectedDateRange, setSelectedDateRange] = useState<OptionType>(
    dateRangeFiltersOptions[0],
  );
  const isAllTimeOrCustom =
    selectedDateRange.value === DateRanges.ALL_TIME ||
    selectedDateRange.value === DateRanges.CUSTOM;
  const dateJoined = useAppSelector(
    (state) =>
      state.selectedProfileSlice?.user?.date_joined ||
      state.selectedProfileSlice?.studio?.created,
  );
  const [minStartDate, setMinStartDate] = useState<Date>(new Date(dateJoined!));
  const [showEarnings, setShowEarnings] = useState(false);

  const [dateFormat, setDateFormat] = useState<string>("%Y");

  // Data Filter
  const [chartDataFilter, setChartDataFilter] = useState<OptionType>(
    ChartDataFilterOptions[0],
  );

  // Engineer Filters
  const [engineerFilter, setEngineerFilter] = useState<ProjectType[]>([
    ProjectType.NO_TYPE,
  ]);

  const availableFilterOptions = useMemo(() => {
    let availableFilters = ChartDataFilterOptions;
    if (selectedProfile.studio)
      availableFilters = availableFilters.filter(
        (option) => option.value !== ChartDataFilter.PROJECTS_BOOKED,
      );
    return availableFilters;
  }, [selectedProfile.studio]);
  // Current data total
  const [currentGrossVolume, setCurrentGrossVolume] = useState<number>(0);
  const [currentProjectsBooked, setCurrentProjectsBooked] = useState<number>(0);
  const [currentSessionsBooked, setCurrentSessionsBooked] = useState<number>(0);
  const [currentProfileVisits, setCurrentProfileVisits] = useState<number>(0);

  // Data Percentages
  const [grossVolumePercentge, setGrossVolumePercentage] = useState<number>(0);
  const [sessionsBookedPercentage, setSessionsBookedPercentage] =
    useState<number>(0);
  const [projectsBookedPercentage, setProjectsBookedPercentage] =
    useState<number>(0);
  const [profileVisitsPercentage, setProfileVisitsPercentage] =
    useState<number>(0);

  // Previous date range data
  const [previousGrossVolume, setPreviousGrossVolume] = useState<number>(0);
  const [previousProjectsBooked, setPreviousProjectsBooked] =
    useState<number>();
  const [previousSessionsBooked, setPreviousSessionsBooked] =
    useState<number>();
  const [previousProfileVisits, setPreviousProfileVisits] = useState<number>();

  const [previousStartDate, setPreviousStartDate] = useState<Date>();
  const [previousEndDate, setPreviousEndDate] = useState<Date>();

  const dataFilterToPercentageMap = new Map<ChartDataFilter, number>([
    [ChartDataFilter.GROSS_VOLUME, grossVolumePercentge],
    [ChartDataFilter.PROFILE_VISITS, profileVisitsPercentage],
    [ChartDataFilter.PROJECTS_BOOKED, projectsBookedPercentage],
    [ChartDataFilter.SESSIONS_BOOKED, sessionsBookedPercentage],
  ]);
  // Set the current date from API call
  const [currentDate, setCurrentDate] = useState<Date>();
  const showStudioFilters =
    isStudio || chartDataFilter.value === ChartDataFilter.SESSIONS_BOOKED;

  // Set the dates appropriately for the selected date range
  // Custom is not handled here because the date picker will set the state values
  useEffect(() => {
    const now = new Date();
    switch (selectedDateRange.value) {
      case DateRanges.ALL_TIME:
        setStartDate(minStartDate);
        setEndDate(now);
        break;
      case DateRanges.YEAR_TO_DATE:
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        setStartDate(startOfYear);
        setEndDate(now);
        break;
      case DateRanges.QUARTER_TO_DATE:
        const startOfLastThreeMonthsForQuarter = new Date();
        startOfLastThreeMonthsForQuarter.setMonth(now.getMonth() - 3);
        setStartDate(startOfDay(startOfLastThreeMonthsForQuarter));
        setEndDate(now);
        break;
      case DateRanges.MONTH_TO_DATE:
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        setStartDate(startOfMonth);
        setEndDate(now);
        break;
      case DateRanges.LAST_12_MONTHS:
        const startOfLastYear = new Date();
        startOfLastYear.setFullYear(now.getFullYear() - 1);
        setStartDate(startOfLastYear);
        setEndDate(now);
        break;
      case DateRanges.LAST_3_MONTHS:
        const startOfLastThreeMonths = new Date();
        startOfLastThreeMonths.setMonth(now.getMonth() - 3);
        setStartDate(startOfDay(startOfLastThreeMonths));
        setEndDate(now);
        break;
      case DateRanges.LAST_30_DAYS:
        const startOfLast30Days = new Date();
        startOfLast30Days.setDate(now.getDate() - 30);
        setStartDate(startOfDay(startOfLast30Days));
        setEndDate(now);
        break;
      case DateRanges.LAST_7_DAYS:
        const startOfLast7Days = new Date();
        startOfLast7Days.setDate(now.getDate() - 7);
        setStartDate(startOfDay(startOfLast7Days));
        setEndDate(now);
        break;
      case DateRanges.TODAY:
        setStartDate(startOfDay(now));
        setEndDate(now);
        break;
      default:
        break;
    }
  }, [selectedDateRange.value]);

  // Fetch Chart Data
  const { chartDataLoading, chartData, setChartData } = useFetchChartData({
    data_filter: chartDataFilter.value,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    date_range_filter: selectedDateRange.value,
    isUserEngineer: !isStudio,
    studio_ids: studioIds,
    filter_by: engineerFilter,
    userStudios,
  });

  // Set updateTrendPercentage to false after chart data is fetched
  // Set previous date range data and percentage
  useEffect(() => {
    setCurrentDate(new Date());
    setDateFormat(chartData.dateFormat);
    setPreviousStartDate(new Date(chartData.previousStartDate));
    setPreviousEndDate(new Date(chartData.previousEndDate));
    setGrossVolumePercentage(chartData.grossVolumePercentage);
    setProjectsBookedPercentage(chartData.projectsBookedPercentage);
    setSessionsBookedPercentage(chartData.sessionsBookedPercentage);
    setProfileVisitsPercentage(chartData.profileVisitsPercentage);

    if (chartDataFilter.value === ChartDataFilter.GROSS_VOLUME) {
      setPreviousGrossVolume(chartData.previousDateRangeData);
      setCurrentGrossVolume(chartData.grandTotal);
    } else if (chartDataFilter.value === ChartDataFilter.PROJECTS_BOOKED) {
      setPreviousProjectsBooked(chartData.previousDateRangeData);
      setCurrentProjectsBooked(chartData.grandTotal);
    } else if (chartDataFilter.value === ChartDataFilter.SESSIONS_BOOKED) {
      setPreviousSessionsBooked(chartData.previousDateRangeData);
      setCurrentSessionsBooked(chartData.grandTotal);
    } else if (chartDataFilter.value === ChartDataFilter.PROFILE_VISITS) {
      setPreviousProfileVisits(chartData.previousDateRangeData);
      setCurrentProfileVisits(chartData.grandTotal);
    }
  }, [chartData]);

  // Set the studioIDs to the selected studio
  useEffect(() => {
    if (isStudio && !userStudiosLoading) {
      if (selectedStudio) {
        setStudioIds([selectedStudio.id]);
      } else if (!userStudiosLoading) {
        setStudioIds(userStudios.map((studio) => studio.id));
      }
    }
  }, [selectedStudio, userStudios, userStudiosLoading]);

  useEffect(() => {
    setChartDataFilter(ChartDataFilterOptions[0]);
    setSelectedDateRange(dateRangeFiltersOptions[0]);
    setStartDate(new Date(minStartDate));
    setEndDate(new Date());
    setEngineerFilter([ProjectType.NO_TYPE]);
    setCurrentGrossVolume(0);
    setCurrentProjectsBooked(0);
    setCurrentSessionsBooked(0);
    setCurrentProfileVisits(0);
    setGrossVolumePercentage(0);
    setSessionsBookedPercentage(0);
    setProjectsBookedPercentage(0);
    setProfileVisitsPercentage(0);
    setPreviousGrossVolume(0);
    setPreviousProjectsBooked(0);
    setPreviousSessionsBooked(0);
    setPreviousProfileVisits(0);
    setPreviousStartDate(new Date());
    setPreviousEndDate(new Date());
    setChartData(INITIAL_CHART_DATA);
  }, [selectedProfile]);

  useEffect(() => {
    if (selectedProfile?.studio && !selectedStudio) {
      setSelectedStudio(selectedProfile?.studio);
    }
  }, [selectedProfile]);

  useEffect(() => {
    setEngineerFilter([ProjectType.NO_TYPE]);
    if (selectedProfile?.studio && !selectedStudio) {
      setSelectedStudio(selectedProfile?.studio);
    }
  }, [chartDataFilter]);

  const engineerFilterButtons = [
    { type: [ProjectType.NO_TYPE], label: "ALL" },
    {
      type: [ProjectType.TWO_TRACK_MIXING, ProjectType.MIXING],
      label: "Mixing",
      iconColor: "#08306b",
    },
    {
      type: [ProjectType.ATMOS_MIXING],
      label: "Dolby Atmos",
      iconColor: "#08519c",
    },
    {
      type: [ProjectType.MASTERING],
      label: "Mastering",
      iconColor: "#2171b5",
    },
    {
      type: [ProjectType.RECORDING],
      label: "Recording",
      iconColor: "#4292c6",
    },
  ];

  const chartDataFiltersThatAllowServiceFilters = [
    ChartDataFilter.PROJECTS_BOOKED,
    ChartDataFilter.GROSS_VOLUME,
  ];

  function updateEngineerFilter(
    type: ProjectType[],
    engineerFilter: ProjectType[],
  ): ProjectType[] {
    const isDefaultFilter = type.includes(ProjectType.NO_TYPE);
    const isOnlyDefaultSelected =
      engineerFilter.length === 1 && engineerFilter[0] === ProjectType.NO_TYPE;
    const isFilterSelected = type.every((singleType) =>
      engineerFilter.includes(singleType),
    );

    if (isDefaultFilter) {
      return [ProjectType.NO_TYPE];
    }

    if (isOnlyDefaultSelected) {
      return [...type];
    }

    if (isFilterSelected) {
      const wouldBeEmptyAfterRemoval = engineerFilter.length === type.length;
      return wouldBeEmptyAfterRemoval
        ? [ProjectType.NO_TYPE]
        : engineerFilter.filter((item) => !type.includes(item));
    }

    return [...engineerFilter, ...type];
  }

  return (
    <DashboardCard
      cardHeight={580}
      contentOnly={onSidePanel || !isDesktop}
      headerText="Your Performance"
      handleHeaderClick={() => {
        emitAnalyticsTrackingEvent(
          "performance_chart_open_side_panel_with_arrow",
          {},
          loggedInUser?.id,
        );
        onClick();
      }}
      noHeaderIcon={onSidePanel}
    >
      <div className="performance-chart-container">
        <div className="performance-chart-chart-header">
          {isMobile && (
            <div className="chart-header-buttons">
              <DropdownSelector
                value={chartDataFilter}
                options={availableFilterOptions}
                onChange={(option) => {
                  emitAnalyticsTrackingEvent(
                    "performance_chart_filter_change_mobile",
                    { label: option.label },
                    loggedInUser?.id,
                  );
                  setChartDataFilter(option);
                }}
              />
            </div>
          )}
          {!isMobile && (
            <div className="chart-header-buttons">
              {availableFilterOptions.map((option, index) => {
                const percentage =
                  dataFilterToPercentageMap.get(option.value) ?? 0;
                const className = classNames({
                  "chart-header-buttons-unselected":
                    chartDataFilter.value !== option.value,
                  "chart-header-buttons-selected":
                    chartDataFilter.value === option.value,
                  "no-data": percentage === 0,
                  "chart-header-buttons-negative": percentage < 0,
                });

                return (
                  <button
                    key={index}
                    className={className}
                    onClick={() => {
                      emitAnalyticsTrackingEvent(
                        `performance_chart_filter_change_${option.label}`,
                        {
                          label: option.label,
                        },
                        loggedInUser?.id,
                      );
                      setChartDataFilter(option);
                    }}
                  >
                    <TrendingPercentage
                      percentage={percentage}
                      label={option.label}
                    />
                  </button>
                );
              })}
            </div>
          )}
          <div className="chart-header-money">
            {!chartDataLoading && chartData.chartData && (
              <BlurredContent
                blurred={
                  !showEarnings &&
                  chartDataFilter.value === ChartDataFilter.GROSS_VOLUME
                }
              >
                <p
                  className="current-total"
                  onMouseEnter={() => setShowEarnings(true)}
                  onMouseLeave={() => setShowEarnings(false)}
                >
                  {chartDataFilter.value === ChartDataFilter.GROSS_VOLUME
                    ? PennyDollarFormatter().format(currentGrossVolume)
                    : chartDataFilter.value === ChartDataFilter.PROJECTS_BOOKED
                      ? currentProjectsBooked + " Projects"
                      : chartDataFilter.value ===
                          ChartDataFilter.SESSIONS_BOOKED
                        ? currentSessionsBooked + " Sessions"
                        : chartDataFilter.value ===
                            ChartDataFilter.PROFILE_VISITS
                          ? currentProfileVisits + " Profile Visits"
                          : ""}
                </p>
              </BlurredContent>
            )}
            {!chartDataLoading &&
              selectedDateRange.value !== DateRanges.ALL_TIME && (
                <p className="previous-total">
                  {chartDataFilter.value === ChartDataFilter.GROSS_VOLUME &&
                  previousGrossVolume !== undefined
                    ? PennyDollarFormatter().format(previousGrossVolume) +
                      " previous period (" +
                      previousStartDate?.toLocaleDateString() +
                      " - " +
                      previousEndDate?.toLocaleDateString() +
                      ")"
                    : chartDataFilter.value ===
                          ChartDataFilter.PROJECTS_BOOKED &&
                        previousProjectsBooked !== undefined
                      ? previousProjectsBooked +
                        " Projects previous period (" +
                        previousStartDate?.toLocaleDateString() +
                        " - " +
                        previousEndDate?.toLocaleDateString() +
                        ")"
                      : chartDataFilter.value ===
                            ChartDataFilter.SESSIONS_BOOKED &&
                          previousSessionsBooked !== undefined
                        ? previousSessionsBooked +
                          " Sessions previous period (" +
                          previousStartDate?.toLocaleDateString() +
                          " - " +
                          previousEndDate?.toLocaleDateString() +
                          ")"
                        : chartDataFilter.value ===
                              ChartDataFilter.PROFILE_VISITS &&
                            previousProfileVisits !== undefined
                          ? previousProfileVisits +
                            " Profile Visits previous period (" +
                            previousStartDate?.toLocaleDateString() +
                            " - " +
                            previousEndDate?.toLocaleDateString() +
                            ")"
                          : ""}
                </p>
              )}
          </div>
        </div>
        <div className="performance-chart-chart">
          <div className="chart-navigation-bar">
            {!isMobile && (
              <>
                {dateRangeFiltersOptions.map((dateRangeFiltersOption, key) => {
                  if (dateRangeFiltersOption.value === DateRanges.CUSTOM)
                    return;
                  if (
                    ![DateRanges.ALL_TIME].includes(
                      dateRangeFiltersOption.value,
                    )
                  )
                    return;
                  return (
                    <button
                      key={key}
                      onClick={() => {
                        emitAnalyticsTrackingEvent(
                          "performance_chart_filter_change_date_range_mobile",
                          {
                            label: dateRangeFiltersOption.label,
                          },
                          loggedInUser?.id,
                        );
                        setSelectedDateRange(dateRangeFiltersOption);
                      }}
                      className={
                        selectedDateRange.value === dateRangeFiltersOption.value
                          ? "chart-navigation-bar-button-selected"
                          : "chart-navigation-bar-button-unselected"
                      }
                    >
                      {dateRangeFiltersOption.label}
                    </button>
                  );
                })}
                <DropdownSelector
                  value={
                    isAllTimeOrCustom
                      ? { value: -1, label: "Select option" }
                      : selectedDateRange
                  }
                  placeholder="Select time range"
                  options={dateRangeFiltersOptionsForDesktop}
                  onChange={(option) => setSelectedDateRange(option)}
                  customClassNames={{
                    container: isAllTimeOrCustom
                      ? "date-range-dropdown-unselected"
                      : "date-range-dropdown-selected",
                    selectContainer: isAllTimeOrCustom
                      ? ""
                      : "date-range-dropdown-select-content",
                  }}
                />
              </>
            )}
            {isMobile && (
              <DropdownSelector
                value={
                  selectedDateRange.value === DateRanges.CUSTOM
                    ? { value: -1, label: "Select option" }
                    : selectedDateRange
                }
                placeholder="Select range"
                options={dateRangeFiltersOptions}
                onChange={(option) => {
                  emitAnalyticsTrackingEvent(
                    "performance_chart_filter_change_date_range_mobile",
                    {
                      label: option.label,
                    },
                    loggedInUser?.id,
                  );
                  setSelectedDateRange(option);
                }}
              />
            )}
            <div
              className={`chart-navigation-bar-date-picker ${
                selectedDateRange.value === DateRanges.CUSTOM ? "selected" : ""
              }`}
              onClick={() => {
                setSelectedDateRange(
                  dateRangeFiltersOptions[DateRanges.CUSTOM],
                );
              }}
            >
              <DatePicker
                className={`custom-date-picker ${
                  selectedDateRange.value === DateRanges.CUSTOM
                    ? "selected"
                    : ""
                }`}
                selected={startDate}
                onChange={(date) => {
                  emitAnalyticsTrackingEvent(
                    "performance_chart_filter_start_date",
                    { date: date?.toISOString() },
                    loggedInUser?.id,
                  );
                  setStartDate(startOfDay(date!));
                  setSelectedDateRange(
                    dateRangeFiltersOptions[DateRanges.CUSTOM],
                  );
                }}
                dateFormat={"MMM dd"}
                popperClassName="custom-date-picker-popper"
                withPortal={false}
                disabledKeyboardNavigation={true}
                dropdownMode="select"
                showYearDropdown
                showMonthDropdown
                minDate={minStartDate}
                maxDate={currentDate}
              />
              <span
                style={{
                  color:
                    selectedDateRange.value === DateRanges.CUSTOM
                      ? "var(--white)"
                      : "var(--medium-grey)",
                }}
              >
                -
              </span>
              <DatePicker
                className={`custom-date-picker ${
                  selectedDateRange.value === DateRanges.CUSTOM
                    ? "selected"
                    : ""
                }`}
                selected={endDate}
                onChange={(date) => {
                  emitAnalyticsTrackingEvent(
                    "performance_chart_filter_end_date",
                    { date: date?.toISOString() },
                    loggedInUser?.id,
                  );
                  setEndDate(endOfDay(date!));
                  setSelectedDateRange(
                    dateRangeFiltersOptions[DateRanges.CUSTOM],
                  );
                }}
                dateFormat={"MMM dd"}
                popperClassName="custom-date-picker-popper"
                withPortal={false}
                disabledKeyboardNavigation={true}
                dropdownMode="select"
                showYearDropdown
                showMonthDropdown
                minDate={minStartDate}
                maxDate={currentDate}
              />
            </div>
          </div>
          <div className="chart-visual-data">
            {isStudio && (
              <div className="chart-visual-data-title">
                {selectedStudio === null ? (
                  <h3>All Studios</h3>
                ) : (
                  <h3>{selectedStudio.studio_profile?.display_name}</h3>
                )}
              </div>
            )}
            <div className="chart-visual-data-line-graph-container">
              {!chartDataLoading && chartData.chartData ? (
                <LineChart
                  data={chartData}
                  dataFilter={chartDataFilter.value}
                  dateRangeFilter={selectedDateRange.value}
                  dateFormat={dateFormat}
                  isEngineerDashboard={!isStudio}
                />
              ) : (
                <SoundWaveLoader width={100} height={100} />
              )}
            </div>
          </div>
          <div className="chart-visual-data-footer">
            <div className="chart-visual-data-footer-chart-information">
              {!chartDataLoading && chartData.currentDate && (
                <span>Updated: {currentDate?.toLocaleString()}</span>
              )}
              <span>
                Selected: {startDate.toLocaleDateString()} -{" "}
                {endDate.toLocaleDateString()}
              </span>
            </div>
            {showStudioFilters && (
              <div className="chart-visual-data-footer-selections">
                <button
                  onClick={() => {
                    emitAnalyticsTrackingEvent(
                      "performance_chart_select_all_studios",
                      {},
                      loggedInUser?.id,
                    );
                    setSelectedStudio(null);
                    setStudioIds(userStudios.map((studio) => studio.id));
                  }}
                  className={
                    selectedStudio === null
                      ? "footer-button-selected"
                      : "footer-button-unselected"
                  }
                >
                  <span
                    className={
                      selectedStudio === null
                        ? "footer-button-text-selected"
                        : "footer-button-text-unselected"
                    }
                  >
                    ALL
                  </span>
                </button>
                {Array.isArray(userStudios) &&
                  userStudios.map((studio, index) => {
                    const studioChartData = chartData.chartData.find((data) => {
                      const chartDataId = data.id;
                      if (chartDataId.includes("_")) {
                        const [studio_id] = chartDataId.split("_");
                        if (studio_id === studio.id.toString()) {
                          return true;
                        }
                      }
                      return false;
                    });
                    const color = getColorFromChartData(studioChartData);
                    return (
                      <button
                        key={index}
                        onClick={() => {
                          emitAnalyticsTrackingEvent(
                            "performance_chart_select_a_studios",
                            { studio: studio.username },
                            loggedInUser?.id,
                          );

                          setSelectedStudio(studio);
                          setStudioIds([studio.id]);
                        }}
                        className={
                          selectedStudio === studio
                            ? "footer-button-selected"
                            : "footer-button-unselected"
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          color={color}
                          className="chart-footer-selection-circle"
                        />
                        <span
                          className={
                            selectedStudio === studio
                              ? "footer-button-text-selected"
                              : "footer-button-text-unselected"
                          }
                        >
                          {studio.studio_profile?.display_name}
                        </span>
                      </button>
                    );
                  })}
              </div>
            )}
            {!isStudio &&
              chartDataFiltersThatAllowServiceFilters.includes(
                chartDataFilter.value,
              ) && (
                <div className="chart-visual-data-footer-selections">
                  {engineerFilterButtons.map(
                    ({ type, label, iconColor }, index) => {
                      // Only render the "Recording" button when using Gross Volume
                      if (
                        label === "Recording" &&
                        chartDataFilter.value !== ChartDataFilter.GROSS_VOLUME
                      ) {
                        return null;
                      }

                      return (
                        <button
                          key={index}
                          onClick={() => {
                            type.forEach(() => {
                              emitAnalyticsTrackingEvent(
                                "performance_chart_set_engineer_filter",
                                { label: label },
                                loggedInUser?.id,
                              );
                            });
                            setEngineerFilter(
                              updateEngineerFilter(type, engineerFilter),
                            );
                          }}
                          className={
                            type.some((singleType) =>
                              engineerFilter.includes(singleType),
                            )
                              ? "footer-button-selected"
                              : "footer-button-unselected"
                          }
                        >
                          {iconColor && (
                            <FontAwesomeIcon
                              icon={faCircle}
                              color={iconColor}
                              className="chart-footer-selection-circle"
                            />
                          )}
                          <span
                            className={
                              type.some((singleType) =>
                                engineerFilter.includes(singleType),
                              )
                                ? "footer-button-text-selected"
                                : "footer-button-text-unselected"
                            }
                          >
                            {label}
                          </span>
                        </button>
                      );
                    },
                  )}
                </div>
              )}
          </div>
        </div>
        {isStudio &&
        userStudios.length === 0 &&
        !userStudiosLoading &&
        !onSidePanel ? (
          <div className="performance-chart-add-studio-container">
            <button
              className="performance-chart-add-studio-button"
              onClick={() => {
                emitAnalyticsTrackingEvent(
                  "performance_chart_set_add_a_studio",
                  {},
                  loggedInUser?.id,
                );
                setShowAddStudioModal(true);
              }}
            >
              Add Studio
            </button>
          </div>
        ) : !onSidePanel ? (
          <div className="view-transactions-container">
            <Button
              onClick={() => {
                emitAnalyticsTrackingEvent(
                  "performance_chart_open_side_panel_with_view_transactions_button",
                  {},
                  loggedInUser?.id,
                );
                onClickViewTransactions();
              }}
              variant={ButtonVariant.OUTLINED}
            >
              View Transactions
            </Button>
          </div>
        ) : null}
        {showAddStudioModal && (
          <AddAStudioModal
            onClose={() => {
              setShowAddStudioModal(false);
            }}
            overwriteShowModal={showAddStudioModal}
          />
        )}
      </div>
    </DashboardCard>
  );
};

export default PerformanceChart;
