import { useReleaseDetails } from "../../../hooks/releaseAPIhooks";
import { useParams } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/profileScreenHooks/useGetUserProfile";
import { Box, Skeleton } from "@mui/material";
import { ReleaseListItemCoverPhoto } from "../ReleaseList/ReleaseListItemCoverPhoto";
import { ReleasePlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { ExpandableTextArea } from "../../elements/ExpandableTextArea/ExpandableTextArea";
import { FeaturedAristReleaseHeader } from "./FeaturedAristReleaseHeader";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import { MadeOnEngineers } from "./MadeOnEngineers";
import { FeatureReleasePill } from "./FeaturedReleasePill";
import { ReleaseTabViewSectionProps } from "../ArtistReleaseTabView/ArtistReleaseTabView";
import { Release } from "../../../store/models/release";

interface FeaturedArtistReleaseProps extends ReleaseTabViewSectionProps {
  release?: Release | null;
  isReleaseLoading: boolean;
  onReleaseClick: (releaseId?: number) => void;
  ctaText?: string;
}

export const FeaturedArtistRelease = ({
  size,
  shouldShowDesktop,
  release,
  isReleaseLoading,
  onReleaseClick,
  ctaText = "Play Project",
}: FeaturedArtistReleaseProps) => {
  const { username } = useParams<{ username: string }>();
  const { data: userData } = useGetUserProfile(username);
  const {
    recordingEngineerCollaborators,
    mixingEngineerCollaborators,
    dolbyAtmosMixingEngineerCollaborators,
    masteringEngineerCollaborators,
    playCount,
    isReleaseDetailsInitialLoading,
  } = useReleaseDetails(release?.id);

  const featuredArtistRelease = release ? (
    <>
      <ReleaseListItemCoverPhoto
        width={size}
        height={size}
        releaseCover={release.release_cover}
      >
        <Box
          sx={{
            position: "absolute",
            right: 16,
            bottom: 16,
          }}
        >
          <ReleasePlayPauseButton size={42} releaseId={release.id} />
        </Box>
      </ReleaseListItemCoverPhoto>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "24px",
          width: size,
          height: shouldShowDesktop ? size : "fit-content",
        }}
      >
        <FeaturedAristReleaseHeader
          title={release.title}
          shouldShowDesktop={shouldShowDesktop}
          artistDisplayName={getDisplayableNameForUser(userData)}
          artistUsername={userData?.username ?? ""}
          releaseType={release.release_type}
          createdAt={release.created_at}
        />
        <ExpandableTextArea
          isGreyVariant
          minHeight={5}
          body={release.description ?? ""}
        />
        <Box>
          {recordingEngineerCollaborators}
          {mixingEngineerCollaborators}
          {masteringEngineerCollaborators}
          {dolbyAtmosMixingEngineerCollaborators}
        </Box>
        <Box
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {isReleaseDetailsInitialLoading ? (
            <Skeleton width={"10%"} />
          ) : (
            <Text bold variant={TextStyleVariant.P2}>
              {`${playCount} plays`}
            </Text>
          )}
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              onReleaseClick(release.id);
            }}
          >
            {ctaText}
          </Button>
        </Box>
      </Box>
    </>
  ) : null;

  const featuredArtistReleaseLoader = (
    <>
      <Skeleton variant={"rounded"} width={size} height={size} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "24px",
          width: size,
          height: size,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            flexDirection: shouldShowDesktop ? "row" : "column-reverse",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Skeleton height={10} width={200} />
            <Skeleton height={10} width={200} />
            <Skeleton height={10} width={200} />
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <MadeOnEngineers />
            <FeatureReleasePill />
          </Box>
        </Box>
        <Skeleton height={10} />
        <Skeleton height={10} />
        <Box
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Skeleton width={"10%"} />
          <Button disabled variant={ButtonVariant.PRIMARY}>
            Play Project
          </Button>
        </Box>
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: shouldShowDesktop ? "row" : "column",
        gap: "16px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isReleaseLoading ? featuredArtistReleaseLoader : featuredArtistRelease}
    </Box>
  );
};
