import { ReleaseTrack } from "../../../store/models/release";
import { ColumnDef } from "@tanstack/react-table";
import { useSortable } from "@dnd-kit/sortable";
import { useMemo } from "react";
import { Text } from "../../core-ui/components/Text/Text";
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReleaseTrackPlayPauseButton } from "../ReleasePlayPauseButton/ReleasePlayPauseButton";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../core-ui/components/Button/Button";
import { ButtonVariant } from "../../core-ui/components/Button/utils";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";
import millify from "millify";
import { RowDraggerButton } from "../ProjectOverviewTable/NewProjectsTable.styles";

const RowDragHandleCell = ({ rowId }: { rowId: number }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <RowDraggerButton {...attributes} {...listeners}>
      <FontAwesomeIcon icon={faArrowUpArrowDown} size="sm" />
    </RowDraggerButton>
  );
};

export const useColumnDefinitionForReleaseTracks =
  (): ColumnDef<ReleaseTrack>[] => {
    return useMemo(() => {
      return [
        {
          id: "reorder-tab",
          accessorFn: (row) => row.id,
          header: () => null,
          cell: ({ row: { original } }) => {
            return <RowDragHandleCell rowId={original.id} />;
          },
        },
        {
          id: "title",
          header: () => (
            <Text
              style={{
                minWidth: "150px",
                textAlign: "left",
              }}
              bold
              variant={TextStyleVariant.P2}
            >
              Track Title
            </Text>
          ),
          accessorFn: (row: ReleaseTrack) => row.title,
          cell: (args) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  minWidth: "150px",
                  alignItems: "center",
                }}
              >
                <Text>
                  {(args.row.original.track_number + 1)
                    .toString()
                    .padStart(2, "0")}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                  }}
                >
                  {args.row.original.title}
                </Text>
              </Box>
            );
          },
        },
        {
          id: "play_section",
          header: () => null,
          cell: ({ row: { original } }) => (
            <ReleaseTrackPlayPauseButton
              size={32}
              releaseId={original.release.id}
              trackId={original.id}
            />
          ),
        },
        {
          id: "play_count",
          header: () => (
            <Text bold variant={TextStyleVariant.P2}>
              Plays
            </Text>
          ),
          accessorFn: (row: ReleaseTrack) => row.play_count,
          cell: (args) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Text>
                  {args.row.original.play_count < 10
                    ? args.row.original.play_count.toString().padStart(2, "0")
                    : millify(args.row.original.play_count)}
                </Text>
                <Button variant={ButtonVariant.UNSTYLED}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Button>
              </Box>
            );
          },
        },
      ];
    }, []);
  };